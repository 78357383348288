import {
    IS_MICRO_ARTICLES_LOADING,
    SET_ALL_MICRO_ARTICLES, SET_ALL_MICRO_ARTICLES_BOARD,
    SET_CURRENT_FEATURE_ID,
    SET_FORM_POPUP_VISIBILITY,
    SET_MICRO_ARTICLE_DATA,
    SET_VIEW_POPUP_VISIBILITY
} from "../constants";

export const setIsMicroArticlesLoading = (bool) => ({
   type: IS_MICRO_ARTICLES_LOADING,
   payload: bool
});

export const setMicroArticleData = (data) => ({
    type: SET_MICRO_ARTICLE_DATA,
    payload: data,
});

export const setAllMicroArticlesBoard = (data) => ({
    type: SET_ALL_MICRO_ARTICLES_BOARD,
    payload: data,
});

export const setCurrentFeatureId = (featureId) => ({
    type: SET_CURRENT_FEATURE_ID,
    payload: featureId,
});

export const setViewPopupVisibility = (isVisible) => ({
    type: SET_VIEW_POPUP_VISIBILITY,
    payload: isVisible,
});

export const setFormPopupVisibility = (isVisible) => ({
    type: SET_FORM_POPUP_VISIBILITY,
    payload: isVisible,
});

export const setAllMicroArticles = (data) => ({
    type: SET_ALL_MICRO_ARTICLES,
    payload: data,
})
