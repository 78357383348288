import React, { Component, useEffect, useLayoutEffect, useState } from "react";
import Account from "../account";
import { Link } from "react-router-dom";
import "./style.css";
import LeftNavigation from "../../components/leftNavigation";
import AccountFooter from "./../../components/accountFooter/index";
import { useNavigate } from "react-router-dom";
import { HandleToken, LogOut } from "../../helpers";
import { connect } from "react-redux";
import {
  loadingSubjects,
  setSubjects,
  getFilteredSubjectsFromAPI,
} from "../../store/actions/subjects";
import Logout from "../../components/UI/LogOut";
import AccountBoard from "../../components/UI/AccountBoards";
import AccountPage from "../../components/UI/AccountPage";
import DynamicScreenshot from "../../components/UI/DynamicScreenshot";
import Bookmark from "../../components/UI/Bookmark";
import DynamicScreenshotCard from "../../components/UI/DynamicScreenshotCard";
import {
  getUserData,
  getFilteredSubjectsData,
  getFilteredGradesData,
  getTopicsData,
  getLessonsData,
  getIsFilteredSubjectLoading,
  getIsFilteredGradeLoading,
  getIsTopicLoading,
  getIsLessonLoading,
  getMapStateData,
  getScreenShotLoading,
  getRemoveCardId,
} from "../../store/selectors";
import { getUserFromAPI } from "../../store/actions/user";
import { getScreenShotDataFromAPI } from "../../store/actions/mapStateAction";
import { getFilteredGradesFromAPI } from "../../store/actions/grades";
import { getLessonsFromAPI } from "../../store/actions/lessons";
import { getTopicsFromAPI } from "../../store/actions/topics";
import { Pagination, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import PageLoader from "../../components/UI/PageLoader";
import PresentationIcon from "../../components/UI/Presentation";
import {LanguageChanger} from "../../components/languageChanger";
import {useTranslation} from "react-i18next";
import TopBar from "../../components/topBar";

export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};
const queryParams = new URLSearchParams(window.location.search);
const arr = Array.from({ length: 15 }, (v, i) => i);

const DynamicScreenshots = (props) => {
  const matches = useMediaQuery("(min-width:600px)");
  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState("medium");
  const {t} = useTranslation()
  const handleChangePage = (e, value) => {
    const pageLoc = +value;
    if (!isNaN(pageLoc) && typeof pageLoc === "number" && pageLoc !== page) {
      setPage(() => pageLoc);
      props.getScreenShotDataFromAPI(pageLoc, props.user.id);
      window.history.replaceState(null, null, "?page=" + pageLoc);
    }
  };

  useLayoutEffect(() => {
    if (props?.user?.id) {
      const queryParams = new URLSearchParams(window.location.search);
      let pageLoc = +queryParams.get("page");

      if (pageLoc > Math.ceil(props.getMapStateData.total / 10))
        pageLoc = Math.ceil(props.getMapStateData.total / 10);
      if (pageLoc < 1) pageLoc = 1;

      if (!isNaN(pageLoc) && typeof pageLoc === "number") {
        if (pageLoc !== page) setPage(() => pageLoc);

        props.getScreenShotDataFromAPI(pageLoc, props.user.id);
        window.history.replaceState(null, null, "?page=" + pageLoc);
      }
    }
  }, [props.user]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    let pageLoc = +queryParams.get("page");
    if (pageLoc > Math.ceil(props.getMapStateData.total / 10)) {
      pageLoc = Math.ceil(props.getMapStateData.total / 10);
      if (!isNaN(pageLoc) && typeof pageLoc === "number") {
        if (pageLoc !== page) setPage(() => pageLoc);
        window.history.replaceState(null, null, "?page=" + pageLoc);
        props.getScreenShotDataFromAPI(pageLoc, props.user.id);
      }
    }
  }, [props.getMapStateData]);

  useEffect(() => {
    HandleToken();
    // if (!props.user) {
    //   props.getUserFromAPI();
    // }
  }, []);

  return (
    <Account onlyBG={true}>
      <LeftNavigation />
      <TopBar />
      <div className="bookmark-dashboards">
        <div className="account-dashboards--content" id="gap">
          {props.getScreenShotLoading ? (
            props?.getMapStateData?.items?.length > 0 ? (
              props?.getMapStateData?.items?.map((el) => (
                <DynamicScreenshotCard
                  key={el.id}
                  item={el}
                  removeCardId={props.getRemoveCardId}
                />
              ))
            ) : (
              <div className={"dynamic-screen-shot-empty-data"}>
                <h2>{t('noSavedMaps')}</h2>
              </div>
            )
          ) : (
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                zIndex: 1222,
              }}
            >
              <PageLoader />
            </div>
          )}
        </div>
        <div className="account-dashboards--pagination">
          {props.getScreenShotLoading &&
            Math.ceil(props.getMapStateData.total / 10) > 0 && (
              <>
                <Pagination
                  className={"small"}
                  count={Math.ceil(props.getMapStateData.total / 10)}
                  size="small"
                  page={page}
                  onChange={handleChangePage}
                />
                <Pagination
                  className={"medium"}
                  count={Math.ceil(props.getMapStateData.total / 10)}
                  page={page}
                  onChange={handleChangePage}
                />
              </>
            )}
        </div>
      </div>
      <AccountFooter />
    </Account>
  );
};
const mapStateTopProps = (state) => ({
  subjects: getFilteredSubjectsData(state),
  grades: getFilteredGradesData(state),
  user: getUserData(state),
  topics: getTopicsData(state),
  lessons: getLessonsData(state),
  isSubjectsLoading: getIsFilteredSubjectLoading(state),
  isGradesLoading: getIsFilteredGradeLoading(state),
  isTopicsLoading: getIsTopicLoading(state),
  isLessonLoading: getIsLessonLoading(state),
  getMapStateData: getMapStateData(state),
  getScreenShotLoading: getScreenShotLoading(state),
  getRemoveCardId: getRemoveCardId(state),
});

const mapDispatchToProps = {
  getFilteredSubjectsFromAPI,
  getFilteredGradesFromAPI,
  getLessonsFromAPI,
  getTopicsFromAPI,
  getUserFromAPI,
  loadingSubjects,
  setSubjects,
  getScreenShotDataFromAPI,
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withNavigation(DynamicScreenshots));
