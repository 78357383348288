import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const Dots = ({
                  total,
                  active,
                  maxDots = 5,
                  passiveDotWidth = 10,
                  activeDotWidth = 10,
                  passiveDotHeight = 10,
                  activeDotHeight = 10,
                  passiveColor = '#CCCCCC',
                  activeColor = '#016bd8',
                  marginHorizontal = 2,
                  smallerDotScale = 0.5, // Scale for side dots
                  onScrollTo = () => {},
              }) => {
    const getVisibleDots = () => {
        const halfMax = Math.floor(maxDots / 2);
        let start = Math.max(0, active - halfMax);
        let end = Math.min(total, start + maxDots);

        if (end - start < maxDots) {
            start = Math.max(0, end - maxDots);
        }

        return { start, end };
    };

    const getDotStyle = (index) => {
        const isActive = index === active;
        const { start, end } = getVisibleDots();
        const isEdgeDot = index === start || index === end - 1;
        const scale = isEdgeDot ? smallerDotScale : 1;

        return {
            width: `${isActive ? activeDotWidth : passiveDotWidth * scale}px`,
            height: `${isActive ? activeDotHeight : passiveDotHeight * scale}px`,
            margin: `0 ${marginHorizontal}px`,
            backgroundColor: isActive ? activeColor : passiveColor,
            borderRadius: '50%',
            cursor: 'pointer',
            opacity: isEdgeDot ? 0.7 : 1,
            transition: 'all 0.3s ease',
        };
    };

    const { start, end } = getVisibleDots();

    return (
        <div className="dots-container">
            <div className="dots-container--body">
                {Array.from({ length: total }, (_, index) => (
                    index >= start && index < end ? (
                        <div
                            key={index}
                            style={getDotStyle(index)}
                            onClick={() => onScrollTo(index)}
                            className="dot"
                        />
                    ) : null
                ))}
            </div>
        </div>
    );
};

Dots.propTypes = {
    total: PropTypes.number.isRequired,
    active: PropTypes.number.isRequired,
    maxDots: PropTypes.number,
    passiveDotWidth: PropTypes.number,
    activeDotWidth: PropTypes.number,
    passiveDotHeight: PropTypes.number,
    activeDotHeight: PropTypes.number,
    passiveColor: PropTypes.string,
    activeColor: PropTypes.string,
    marginHorizontal: PropTypes.number,
    smallerDotScale: PropTypes.number,
    onScrollTo: PropTypes.func,
};

export default Dots;
