// EditModal.jsx
import React from 'react';
import {Modal, Form, Input, Divider} from 'antd';
import Text from "../Text";
import Title from "antd/lib/typography/Title";
import styles from "./MicroArticleBoardCard.module.css";
import {CloseOutlined} from "@ant-design/icons";

const EditModal = ({ visible, onOk, onCancel, initialValues }) => {
    const [form] = Form.useForm();

    const handleOk = () => {
        form
            .validateFields()
            .then(values => {
                onOk(values);
                form.resetFields();
            })
            .catch(info => {
                console.log('Validation Failed:', info);
            });
    };

    return (
        <Modal
            open={visible}
            styles={{
                content: {
                   // padding: 20,
                   borderRadius: 20,
                },
                header: {
                    padding: '15px 15px 0 15px',
                },
                body: {
                  padding: 15
                },
                footer: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 20,
                    margin: 0
                }
            }}
            title={
                <div style={{ width: '100%', textAlign: 'left',  }}>
                   <Title level={4} style={{color: '#2c476c', fontWeight: 700, letterSpacing: 1}}>Խմբագրել</Title>
                   <Divider className={styles.divider} />
                </div>
            }
            okText={<span style={{fontSize: 12}}>Պահպանել</span>}
            closeIcon={<CloseOutlined style={{
            }}/>}
            centered={true}
            cancelText={<span style={{fontSize: 12}}>Չեղարկել</span>}
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            onOk={handleOk}
        >
            <Form form={form} initialValues={initialValues} layout="vertical">
                <Form.Item
                    name="title"
                    label={<span style={{fontWeight: 500, color: "#2c476c"}}>Անվանում</span>}
                    rules={[{message: 'Please input the title!' }]}
                >
                    <Input style={{color: "#2C476C"}} placeholder="Enter article title" />
                </Form.Item>
                <Form.Item name="content"
                           label={<span style={{fontWeight: 500, color: "#2c476c"}}>Նկարագիր</span>}
                >
                    <Input.TextArea
                        maxLength={1000}
                        rows={4}
                        style={{color: "#2C476C"}}
                        autoSize={{ minRows: 4, maxRows: 6 }}
                        placeholder="Մուտքագրեք նկարագրությունը"/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditModal;
