import {MicroArticleService} from "./service";
import axios from "axios";

export const MICRO_ARTICLE_LAYER = 'micro-article-layer'
export const MICRO_ARTICLE_BACKGROUND_LAYER = 'micro-article-background-layer'

export const uploadImageOrVideo = (
    blob,
    alt = 'Image.jpeg',
    title = 'Image.jpeg',
    access = 'PublicRead',
) => {
  if (!blob) return;
  const url =`${process.env.REACT_APP_MEDIA_CONTENT_URL}/api/Upload`;
  const formData = new FormData();
  formData.append('content', blob, title);
  formData.append('title', title );
  formData.append('alt', alt || title);
  formData.append('access', access);


  return imageOrVideoUploadPostMethod(url, formData).then(extractResult);
}

const imageOrVideoUploadPostMethod = async (url, body) => {
  return await axios.post(url, body)
      .then((response) => response)
      .catch((error) => console.log("error", error));
}

const extractResult = (res) => {
  // debugger
  const { data } = res;
  if (data.accepted) {
    if (data.data && data.data[0]) {
      return data.data[0];
    }
  }
  return [];
};

export const validateCoordinates = (_, value) => {
  if (!value) {
    return Promise.reject(new Error('Координаты обязательны'))
  }
  const coords = value.split(',').map((c) => parseFloat(c.trim()))
  if (coords.length !== 2 || coords.some(isNaN)) {
    return Promise.reject(new Error('Некорректный формат координат'))
  }
  const [lat, lng] = coords
  if (lat < -90 || lat > 90 || lng < -180 || lng > 180) {
    return Promise.reject(new Error('Координаты вне допустимого диапазона'))
  }
  return Promise.resolve()
}

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const validateYouTubeLink = (url) => {
  const regex = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/
  return regex.test(url)
}

export const extractIframeSrc = (input) => {
  const iframeRegex = /<iframe[^>]+src="([^">]+)"/i
  const match = input.match(iframeRegex)
  return match ? match[1] : input // Return extracted src or the original input
}

export const formatDate = (year, month, day) => {
  if (!year || !month || !day) return '';
  return `${year}/${String(month).padStart(2, '0')}/${String(day).padStart(2, '0')}`;
};

const captionExample = 'Франций\n' +
    'Около 200 000 атомов франция в магнитно-оптической ловушке\n' +
    'Франций (химический символ — Fr, от лат. Francium) — радиоактивный химический элемент 1-й группы (по устаревшей классификации — главной подгруппы первой группы, IA) седьмого периода периодической системы химических элементов Д. И. Менделеева с атомным номером 87.\n' +
    '\n' +
    'Франций — самый тяжёлый щелочной металл; по свойствам он больше всего напоминает цезий. До его открытия предполагалось, что он является самым электроположительным металлом и наиболее химически активным из всех металлов, однако установлено, что энергия ионизации атома франция выше, чем у цезия, поэтому наиболее электроположительным и химически активным металлом следует считать, по-видимому, цезий. Из-за редкости и высокой радиоактивности франций почти нигде не применяется.\n' +
    '\n' +
    'Франций — самый редкий природный элемент после астата; в природе встречается в ничтожно малых количествах: так, по некоторым данным на 1 атом Fr приходится 3·1018 атомов природного урана, а общее содержание франция в земной коре по разным данным от 20—30 до 340—500 г. Все изотопы чрезвычайно радиоактивны. Обнаружены их распады в астат (путём альфа-распада), радон (путём позитронного распада и конкурирующего с ним электронного захвата) и радий (путём бета-минус-распада); кроме того, известен чрезвычайно редкий кластерный распад франция-221 в таллий-207 с испусканием ядра 14C. Наиболее стабильный (223Fr) имеет период полураспада 22 мин. Металлический образец франция никогда не наблюдался, существуют лишь фотографии образцов, содержащих до 200 000 атомов. Это последний элемент, впервые обнаруженный в природе, а не путем ядерного синтеза. Франций\n' +
    'Около 200 000 атомов франция в магнитно-оптической ловушке\n' +
    'Франций (химический символ — Fr, от лат. Francium) — радиоактивный химический элемент 1-й группы (по устаревшей классификации — главной подгруппы первой группы, IA) седьмого периода периодической системы химических элементов Д. И. Менделеева с атомным номером 87.\n' +
    '\n' +
    'Франций — самый тяжёлый щелочной металл; по свойствам он больше всего напоминает цезий. До его открытия предполагалось, что он является самым электроположительным металлом и наиболее химически активным из всех металлов, однако установлено, что энергия ионизации атома франция выше, чем у цезия, поэтому наиболее электроположительным и химически активным металлом следует считать, по-видимому, цезий. Из-за редкости и высокой радиоактивности франций почти нигде не применяется.\n' +
    '\n' +
    'Франций — самый редкий природный элемент после астата; в природе встречается в ничтожно малых количествах: так, по некоторым данным на 1 атом Fr приходится 3·1018 атомов природного урана, а общее содержание франция в земной коре по разным данным от 20—30 до 340—500 г. Все изотопы чрезвычайно радиоактивны. Обнаружены их распады в астат (путём альфа-распада), радон (путём позитронного распада и конкурирующего с ним электронного захвата) и радий (путём бета-минус-распада); кроме того, известен чрезвычайно редкий кластерный распад франция-221 в таллий-207 с испусканием ядра 14C. Наиболее стабильный (223Fr) имеет период полураспада 22 мин. Металлический образец франция никогда не наблюдался, существуют лишь фотографии образцов, содержащих до 200 000 атомов. Это последний элемент, впервые обнаруженный в природе, а не путем ядерного синтеза.'

export const fetchMicroArticleDetails = (featureId) => {
  const getRandomNumber = (min, max) => Math.random() * (max - min) + min;

  const generateRandomMedia = (count) => {
    const mediaTypes = ["Image", "Video"];
    return Array.from({ length: count }, (_, index) => ({
      id: index + 1,
      isMain: index === 0,
      caption: `Media Caption ${index + 1}`,
      type: mediaTypes[Math.floor(Math.random() * mediaTypes.length)],
      order: index + 1,
      mediaId: crypto.randomUUID(),
      path: `https://via.placeholder.com/150/${Math.floor(getRandomNumber(100, 999)).toString(16)}`,
    }));
  };

  const generateRandomCoordinates = () => ({
    latitude: getRandomNumber(-90, 90),
    longitude: getRandomNumber(-180, 180),
  });

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        code: "OK",
        accepted: true,
        data: [
          {
            id: featureId,
            title: `Feature Title ${featureId}`,
            description: `Random description for feature ${featureId}.`,
            iconKey: `icon-${featureId}`,
            webIconKey: `web-icon-${featureId}`,
            iconBackgroundColor: `#${Math.floor(getRandomNumber(0, 16777215)).toString(16)}`,
            zoomLevel: Math.floor(getRandomNumber(5, 15)),
            ...generateRandomCoordinates(),
            startYear: Math.floor(getRandomNumber(1900, 2000)),
            startMonth: Math.floor(getRandomNumber(1, 12)),
            startDay: Math.floor(getRandomNumber(1, 28)),
            startHour: Math.floor(getRandomNumber(0, 23)),
            startMinute: Math.floor(getRandomNumber(0, 59)),
            startSecond: Math.floor(getRandomNumber(0, 59)),
            startTimeIsBC: Math.random() > 0.5,
            withoutStartDate: Math.random() > 0.5,
            endYear: Math.floor(getRandomNumber(2001, 2025)),
            endMonth: Math.floor(getRandomNumber(1, 12)),
            endDay: Math.floor(getRandomNumber(1, 28)),
            endHour: Math.floor(getRandomNumber(0, 23)),
            endMinute: Math.floor(getRandomNumber(0, 59)),
            endSecond: Math.floor(getRandomNumber(0, 59)),
            endTimeIsBC: Math.random() > 0.5,
            withoutEndDate: Math.random() > 0.5,
            media: [
              {
                id: 12,
                isMain: true,
                caption: "Image Caption, " + captionExample,
                type: "Image",
                order: 34,
                mediaId: "qwe",
                path: 'https://www.shutterstock.com/shutterstock/photos/1140177605/display_1500/stock-photo-door-to-the-hell-darvaza-turkmenistan-1140177605.jpg',
              },
              {
                id: 34,
                isMain: true,
                caption: "Image Caption",
                type: "Image",
                order: 23,
                mediaId: "qwe",
                path: 'http://a.espncdn.com/i/columnists/simmons_bill_80fp.png',
              },
              {
                id: 23,
                isMain: true,
                caption: "Image Caption",
                type: "Image",
                order: 0,
                mediaId: "qwe",
                path: 'http://a.espncdn.com/media/motion/2012/0325/dm_120325_nfl_ontheclock_robertgriffinIII_thumdnail_wbig.jpg',
              },

              {
                id: 1,
                isMain: false,
                caption: "Video Caption",
                type: "Video",
                order: 1,
                mediaId: "asd",
                path: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4',
              },
              {
                id: 2,
                isMain: false,
                caption: "URL Caption",
                type: "Url",
                order: 2,
                mediaId: "zxc",
                path: 'https://www.youtube.com/watch?v=gxDcPsneOpQ',

              },
              {
                id: 3,
                isMain: false,
                caption: "Iframe Caption",
                type: "Iframe",
                order: 3,
                mediaId: "vbn",
                path: 'https://www.youtube.com/embed/LsQKzSdFx9Q?si=UTwtUZbgWnzia-cz',
              },
            ],
            topicIds: [Math.floor(getRandomNumber(1, 10)), Math.floor(getRandomNumber(10, 20))],
            lessonIds: [Math.floor(getRandomNumber(100, 200)), Math.floor(getRandomNumber(200, 300))],
            createdAt: Math.floor(Date.now() / 1000) - Math.floor(getRandomNumber(0, 1000000)),
            modifiedAt: Math.floor(Date.now() / 1000),
          },
        ],
        errorMessages: [],
      });
    }, 1000); // Simulate 1 second delay
  });
};

export const getYouTubeEmbedURL = (url) => {
  const videoIdMatch = url.match(
      /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.*[?&]v=))([^?&"'>]+)/
  );
  const videoId = videoIdMatch ? videoIdMatch[1] : null;
  return videoId ? `https://www.youtube.com/embed/${videoId}` : url;
};

export const MAX_IMAGE_SIZE = 5 * 1024 * 1024 // 20 MB
export const MAX_VIDEO_SIZE = 50 * 1024 * 1024 // 50 MB

export const mockMicroArticleListOfItems = [
  {
    "id": 1,
    "title": "Սենտրալ Պարկ",
    "imageUrl": "https://example.com/images/central-park.jpg",
    "coordinates": [-73.9654, 40.7829],
    "topicIds": [101, 102],
    "lessonIds": [201],
    "createdAt": 1672531200,
    "modifiedAt": 1672617600,
    "color": "#FF5733",
    "description": "accusamus beatae ad facilis cum similique qui sunt",
    "thumbnailUrl": "https://via.placeholder.com/150/92c952"
  },
  {
    "id": 2,
    "title": "Էյֆելյան ճակատ Էյֆելյան ճակատԷյֆելյան ճակատ Էյֆելյան ճակատԷյֆելյան ճակատ Էյֆելյան Էյֆելյան Էյֆելյանն",
    "imageUrl": "https://example.com/images/eiffel-tower.jpg",
    "coordinates": [2.2945, 48.8584],
    "topicIds": [103],
    "lessonIds": [202, 203],
    "createdAt": 1672617600,
    "modifiedAt": 1672704000,
    "color": "#33FF57",
    "description": "reprehenderit est deserunt velit ipsam",
    "thumbnailUrl": "https://via.placeholder.com/150/771796"
  },
  {
    "id": 3,
    "title": "Չինաստանի մեծ պատը",
    "imageUrl": "https://example.com/images/great-wall-of-china.jpg",
    "coordinates": [116.5704, 40.4319],
    "topicIds": [104],
    "lessonIds": [204],
    "createdAt": 1672704000,
    "modifiedAt": 1672790400,
    "color": "#3357FF",
    "description": "officia porro iure quia iusto qui ipsa ut modi",
    "thumbnailUrl": "https://via.placeholder.com/150/24f355"
  },
  {
    "id": 4,
    "title": "Սիդնեյի օպերային տուն",
    "imageUrl": "https://example.com/images/sydney-opera-house.jpg",
    "coordinates": [151.2153, -33.8568],
    "topicIds": [105, 106],
    "lessonIds": [205],
    "createdAt": 1672790400,
    "modifiedAt": 1672876800,
    "color": "#FF33A8",
    "description": "culpa odio esse rerum omnis laboriosam voluptate repudiandae",
    "thumbnailUrl": "https://via.placeholder.com/150/d32776"
  },
  {
    "id": 5,
    "title": "Ֆուջի լեռը",
    "imageUrl": "https://example.com/images/mount-fuji.jpg",
    "coordinates": [138.7274, 35.3606],
    "topicIds": [107],
    "lessonIds": [206, 207],
    "createdAt": 1672876800,
    "modifiedAt": 1672963200,
    "color": "#A833FF",
    "description": "natus nisi omnis corporis facere molestiae rerum in",
    "thumbnailUrl": "https://via.placeholder.com/150/f66b97"
  },
  {
    "id": 6,
    "title": "Կոլոզեումը",
    "imageUrl": "https://example.com/images/colosseum.jpg",
    "coordinates": [12.4922, 41.8902],
    "topicIds": [108],
    "lessonIds": [208, 209],
    "createdAt": 1672963200,
    "modifiedAt": 1673049600,
    "color": "#33FFF5",
    "description": "accusamus ea aliquid et amet sequi nemo",
    "thumbnailUrl": "https://via.placeholder.com/150/56a8c2"
  },
  {
    "id": 7,
    "title": "Ազատության արձան",
    "imageUrl": "https://example.com/images/statue-of-liberty.jpg",
    "coordinates": [-74.0445, 40.6892],
    "topicIds": [109],
    "lessonIds": [210],
    "createdAt": 1673049600,
    "modifiedAt": 1673136000,
    "color": "#FF8C33",
    "description": "officia delectus consequatur vero aut veniam explicabo molestias",
    "thumbnailUrl": "https://via.placeholder.com/150/b0f7cc"
  },
  {
    "id": 8,
    "title": "Տաջ महाल",
    "imageUrl": "https://example.com/images/taj-mahal.jpg",
    "coordinates": [78.0421, 27.1751],
    "topicIds": [110, 111],
    "lessonIds": [211, 212],
    "createdAt": 1673136000,
    "modifiedAt": 1673222400,
    "color": "#8C33FF",
    "description": "aut porro officiis laborum odit ea laudantium corporis",
    "thumbnailUrl": "https://via.placeholder.com/150/54176f"
  },
  {
    "id": 9,
    "title": "Մաչու Պիքուչու",
    "imageUrl": "https://example.com/images/machu-picchu.jpg",
    "coordinates": [-72.544963, -13.163141],
    "topicIds": [112],
    "lessonIds": [213],
    "createdAt": 1673222400,
    "modifiedAt": 1673308800,
    "color": "#33FF8C",
    "description": "qui eius qui autem sed",
    "thumbnailUrl": "https://via.placeholder.com/150/51aa97"
  },
  {
    "id": 10,
    "title": "Սուրբ Խրիստ Հրկի Հրդեհող",
    "imageUrl": "https://example.com/images/christ-the-redeemer.jpg",
    "coordinates": [-43.2105, -22.9519],
    "topicIds": [113],
    "lessonIds": [214, 215],
    "createdAt": 1673308800,
    "modifiedAt": 1673395200,
    "color": "#FF3333",
    "description": "beatae et provident et ut vel",
    "thumbnailUrl": "https://via.placeholder.com/150/810b14"
  },
  {
    "id": 11,
    "title": "Ստոնհենջ",
    "imageUrl": "https://example.com/images/stonehenge.jpg",
    "coordinates": [-1.8262, 51.1789],
    "topicIds": [114],
    "lessonIds": [216],
    "createdAt": 1673395200,
    "modifiedAt": 1673481600,
    "color": "#33A8FF",
    "description": "nihil at amet non hic quia qui",
    "thumbnailUrl": "https://via.placeholder.com/150/1ee8a4"
  },
  {
    "id": 12,
    "title": "Գիզայի פירամիդները",
    "imageUrl": "https://example.com/images/pyramids-of-giza.jpg",
    "coordinates": [31.1342, 29.9792],
    "topicIds": [115, 116],
    "lessonIds": [217],
    "createdAt": 1673481600,
    "modifiedAt": 1673568000,
    "color": "#A8FF33",
    "description": "mollitia soluta ut rerum eos aliquam consequatur perspiciatis maiores",
    "thumbnailUrl": "https://via.placeholder.com/150/66b7d2"
  },
  {
    "id": 13,
    "title": "Գրանդ Քեյննոն",
    "imageUrl": "https://example.com/images/grand-canyon.jpg",
    "coordinates": [-112.1401, 36.0544],
    "topicIds": [117],
    "lessonIds": [218, 219],
    "createdAt": 1673568000,
    "modifiedAt": 1673654400,
    "color": "#FF33FF",
    "description": "repudiandae iusto deleniti rerum",
    "thumbnailUrl": "https://via.placeholder.com/150/197d29"
  },
  {
    "id": 14,
    "title": "Նյաագարա ջրհեղեղները",
    "imageUrl": "https://example.com/images/niagara-falls.jpg",
    "coordinates": [-79.0742, 43.0962],
    "topicIds": [118],
    "lessonIds": [220],
    "createdAt": 1673654400,
    "modifiedAt": 1673740800,
    "color": "#33FFF5",
    "description": "est necessitatibus architecto ut laborum",
    "thumbnailUrl": "https://via.placeholder.com/150/61a65"
  },
  {
    "id": 15,
    "title": "Բուրչ Խալիֆա",
    "imageUrl": "https://example.com/images/burj-khalifa.jpg",
    "coordinates": [55.2763, 25.1972],
    "topicIds": [119, 120],
    "lessonIds": [221],
    "createdAt": 1673740800,
    "modifiedAt": 1673827200,
    "color": "#FF8C33",
    "description": "harum dicta similique quis dolore earum ex qui",
    "thumbnailUrl": "https://via.placeholder.com/150/f9cee5"
  },
  {
    "id": 16,
    "title": "Սագրադա Ֆամիլիա",
    "imageUrl": "https://example.com/images/sagrada-familia.jpg",
    "coordinates": [2.1744, 41.4036],
    "topicIds": [121],
    "lessonIds": [222, 223],
    "createdAt": 1673827200,
    "modifiedAt": 1673913600,
    "color": "#8C33FF",
    "description": "iusto sunt nobis quasi veritatis quas expedita voluptatum deserunt",
    "thumbnailUrl": "https://via.placeholder.com/150/fdf73e"
  },
  {
    "id": 17,
    "title": "Պետրա",
    "imageUrl": "https://example.com/images/petra.jpg",
    "coordinates": [35.4444, 30.3285],
    "topicIds": [122],
    "lessonIds": [224],
    "createdAt": 1673913600,
    "modifiedAt": 1674000000,
    "color": "#33FF8C",
    "description": "natus doloribus necessitatibus ipsa",
    "thumbnailUrl": "https://via.placeholder.com/150/9c184f"
  },
  {
    "id": 18,
    "title": "Սերենգետի Ազգային պարկ",
    "imageUrl": "https://example.com/images/serengeti-national-park.jpg",
    "coordinates": [34.8888, -2.3333],
    "topicIds": [123, 124],
    "lessonIds": [225],
    "createdAt": 1674000000,
    "modifiedAt": 1674086400,
    "color": "#FF3333",
    "description": "laboriosam odit nam necessitatibus et illum dolores reiciendis",
    "thumbnailUrl": "https://via.placeholder.com/150/1fe46f"
  },
  {
    "id": 19,
    "title": "Անգկորյ Վատ",
    "imageUrl": "https://example.com/images/angkor-wat.jpg",
    "coordinates": [103.8667, 13.4125],
    "topicIds": [125],
    "lessonIds": [226, 227],
    "createdAt": 1674086400,
    "modifiedAt": 1674172800,
    "color": "#33A8FF",
    "description": "perferendis nesciunt eveniet et optio a",
    "thumbnailUrl": "https://via.placeholder.com/150/56acb2"
  },
  {
    "id": 20,
    "title": "Բանֆ Ազգային պարկ",
    "imageUrl": "https://example.com/images/banff-national-park.jpg",
    "coordinates": [-115.5708, 51.4968],
    "topicIds": [126, 127],
    "lessonIds": [228],
    "createdAt": 1674172800,
    "modifiedAt": 1674259200,
    "color": "#A8FF33",
    "description": "assumenda voluptatem laboriosam enim consequatur veniam placeat reiciendis error",
    "thumbnailUrl": "https://via.placeholder.com/150/8985dc"
  }
];

