import {
  SET_COMMENT_TOOLBOX_CLICKED_STATE,
  SET_ERASER_CLICKED_STATE,
  SET_LINE_DRAW_TOOLBOX_COLOR_PICKER_STATE,
  SET_LINE_DRAW_TOOLBOX_COLORS,
  SET_LINE_DRAW_TOOLBOX_CURRENT_COLOR,
  SET_LINE_DRAW_TOOLBOX_STATE,
  SET_LINE_DRAW_TOOLBOX_WEIGHT, SET_MICRO_ARTICLE_BAR_CLICKED_STATE, SET_MICRO_ARTICLE_TOOL_CLICKED_STATE,
  SET_PAINTER_COLOR,
  SET_PAINTER_SIZE,
  SET_PAINTER_START,
  SET_POLYGON_DRAW_TOOLBOX_COLOR_PICKER_STATE,
  SET_POLYGON_DRAW_TOOLBOX_COLORS,
  SET_POLYGON_DRAW_TOOLBOX_CURRENT_COLOR,
  SET_POLYGON_DRAW_TOOLBOX_CURRENT_FILL_COLOR,
  SET_POLYGON_DRAW_TOOLBOX_CURRENT_FILL_OPACITY,
  SET_POLYGON_DRAW_TOOLBOX_CURRENT_STROKE_COLOR,
  SET_POLYGON_DRAW_TOOLBOX_CURRENT_STROKE_OPACITY,
  SET_POLYGON_DRAW_TOOLBOX_OPACITY,
  SET_POLYGON_DRAW_TOOLBOX_STATE,
  SET_RULER_CLICKED_STATE,
  SET_START_POINTER_CLICK_STATE,
  SET_STICKY_NOTES_CLICKED_STATE,
  SET_TEXT_TOOLBOX_CLICKED_STATE,
  SET_SHAPER_CLICKED_STATE,
} from "../constants";
import { setHintsData } from "./hints";
import { setMapRequirementData } from "./mapRequirement";
import { setMapStyleData } from "./mapStyle";
import { setMenuData } from "./menu";
import { setQuizPopupData } from "./quizPopup";
import { setSearchData } from "./search";

export const setPainterStartData = (state) => ({
  type: SET_PAINTER_START,
  payload: state,
});

export const setMicroArticleBarClickedState = (state) => ({
  type: SET_MICRO_ARTICLE_BAR_CLICKED_STATE,
  payload: state,
})

export const setDrawerToolboxClickedState = (state) => ({
  type: SET_START_POINTER_CLICK_STATE,
  payload: state,
});

export const setDrawerColor = (color) => ({
  type: SET_PAINTER_COLOR,
  payload: color,
});

export const setDrawerWeight = (size) => ({
  type: SET_PAINTER_SIZE,
  payload: size,
});

export const setMicroArticleToolClickedState = (state) => ({
  type: SET_MICRO_ARTICLE_TOOL_CLICKED_STATE,
  payload: state,
})

export const setRulerClickedState = (state) => ({
  type: SET_RULER_CLICKED_STATE,
  payload: state,
});

export const setEraserClickedState = (state) => ({
  type: SET_ERASER_CLICKED_STATE,
  payload: state,
});

export const setCommentToolboxState = (state) => ({
  type: SET_COMMENT_TOOLBOX_CLICKED_STATE,
  payload: state,
});

export const setTextToolboxState = (state) => ({
  type: SET_TEXT_TOOLBOX_CLICKED_STATE,
  payload: state,
});

export const setPolygonDrawToolboxState = (state) => ({
  type: SET_POLYGON_DRAW_TOOLBOX_STATE,
  payload: state,
});

export const setPolygonDrawToolboxCurrentFillOpacity = (state) => ({
  type: SET_POLYGON_DRAW_TOOLBOX_CURRENT_FILL_OPACITY,
  payload: state,
})

export const setPolygonDrawToolboxCurrentStrokeOpacity = (state) => ({
  type: SET_POLYGON_DRAW_TOOLBOX_CURRENT_STROKE_OPACITY,
  payload: state,
})

export const setPolygonDrawToolboxCurrentFillColor = (color) => ({
  type: SET_POLYGON_DRAW_TOOLBOX_CURRENT_FILL_COLOR,
  payload: color,
})

export const setPolygonDrawToolboxCurrentStrokeColor = (color) => ({
    type: SET_POLYGON_DRAW_TOOLBOX_CURRENT_STROKE_COLOR,
    payload: color,
})

export const setPolygonDrawToolboxColorPickerState = (state) => ({
  type: SET_POLYGON_DRAW_TOOLBOX_COLOR_PICKER_STATE,
  payload: state,
})

export const setPolygonDrawToolboxColors = (state) => ({
  type: SET_POLYGON_DRAW_TOOLBOX_COLORS,
  payload: state,
})

export const setLineDrawToolboxState = (state) => ({
  type: SET_LINE_DRAW_TOOLBOX_STATE,
  payload: state,
});

export const setLineDrawToolboxWeight = (state) => ({
  type: SET_LINE_DRAW_TOOLBOX_WEIGHT,
  payload: state,
})

export const setLineDrawToolboxCurrentColor = (color) => ({
  type: SET_LINE_DRAW_TOOLBOX_CURRENT_COLOR,
  payload: color,
})

export const setLineDrawToolboxColorPickerState = (state) => ({
  type: SET_LINE_DRAW_TOOLBOX_COLOR_PICKER_STATE,
  payload: state,
})

export const setLineDrawToolboxColors = (state) => ({
  type: SET_LINE_DRAW_TOOLBOX_COLORS,
  payload: state,
});

export const setShaperClickState = (state) => {
  return {
    type: SET_SHAPER_CLICKED_STATE,
    payload: state,
  };
};
export const setStickyNotesClickedState = (state) => ({
  type: SET_STICKY_NOTES_CLICKED_STATE,
  payload: state
})

export const setPainterStart = (state) => (dispatch) => {
  dispatch(setMapRequirementData(false));
  dispatch(setMenuData(false));
  dispatch(setMapStyleData(false));
  dispatch(setHintsData(false));
  dispatch(setQuizPopupData(false));
  dispatch(setSearchData(false));
  dispatch(setCommentToolboxState(false));
  dispatch(setTextToolboxState(false));
  dispatch(setStickyNotesClickedState(false))
  dispatch(setLineDrawToolboxState(false));
  dispatch(setPolygonDrawToolboxState(false));
  dispatch(setRulerClickedState(false));
  dispatch(setEraserClickedState(false));
  dispatch(setDrawerToolboxClickedState(false));
  dispatch(setMicroArticleBarClickedState(false));
  dispatch(setPainterStartData(state));
};

export const setMicroArticleBar = (state) => (dispatch) => {
  dispatch(setMapRequirementData(false));
  dispatch(setMenuData(false));
  dispatch(setMapStyleData(false));
  dispatch(setHintsData(false));
  dispatch(setQuizPopupData(false));
  dispatch(setSearchData(false));
  dispatch(setCommentToolboxState(false));
  dispatch(setTextToolboxState(false));
  dispatch(setStickyNotesClickedState(false))
  dispatch(setLineDrawToolboxState(false));
  dispatch(setPolygonDrawToolboxState(false));
  dispatch(setRulerClickedState(false));
  dispatch(setEraserClickedState(false));
  dispatch(setDrawerToolboxClickedState(false));
  dispatch(setPainterStartData(false));
  dispatch(setMicroArticleBarClickedState(state));
  console.log('state',state)
};

