import React from "react";

const DynamicScreenshot = ({isActive = false}) => {
  return (
    <svg
      width="31"
      height="53"
      viewBox="0 0 31 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.4954 13.25C27.2828 13.25 26.3066 14.2334 26.3066 15.4549V37.5348C26.3066 43.6318 21.405 48.5799 15.3421 48.5799C9.27925 48.5799 4.37759 43.6318 4.37759 37.5348V11.0348C4.37759 7.38066 7.31653 4.40977 10.9542 4.40977C14.592 4.40977 17.5309 7.38066 17.5309 11.0348V33.125C17.5309 34.3465 16.5444 35.3299 15.3421 35.3299C14.1398 35.3299 13.1533 34.3465 13.1533 33.125V15.4549C13.1533 14.2334 12.1668 13.25 10.9645 13.25C9.75195 13.25 8.77573 14.2334 8.77573 15.4549V33.125C8.77573 36.7895 11.7147 39.75 15.3524 39.75C18.9901 39.75 21.929 36.7895 21.929 33.125V11.0451C21.9188 4.94805 17.0068 0 10.9542 0C4.90166 0 0 4.94805 0 11.0451V37.5451C0 46.0852 6.87466 53 15.3421 53C23.8096 53 30.6842 46.0748 30.6842 37.5451V15.4549C30.6842 14.2334 29.708 13.25 28.4954 13.25Z"
        fill={isActive ? "#FF6539" : "#2C476C"}
      />
    </svg>
  );
};
export default DynamicScreenshot;
