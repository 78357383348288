import {COLORS_DATA, SIZE_DATA} from "../../utils/color_picker_data";
import {
  SET_COMMENT_TOOLBOX_CLICKED_STATE,
  SET_ERASER_CLICKED_STATE,
  SET_LINE_DRAW_TOOLBOX_COLOR_PICKER_STATE,
  SET_LINE_DRAW_TOOLBOX_COLORS,
  SET_LINE_DRAW_TOOLBOX_CURRENT_COLOR,
  SET_LINE_DRAW_TOOLBOX_STATE,
  SET_LINE_DRAW_TOOLBOX_WEIGHT,
  SET_PAINTER_COLOR,
  SET_PAINTER_SIZE,
  SET_PAINTER_START,
  SET_STICKY_NOTES_CLICKED_STATE,
  SET_POLYGON_DRAW_TOOLBOX_COLOR_PICKER_STATE,
  SET_POLYGON_DRAW_TOOLBOX_STATE,
  SET_RULER_CLICKED_STATE,
  SET_START_POINTER_CLICK_STATE,
  SET_POLYGON_DRAW_TOOLBOX_COLORS,
  SET_POLYGON_DRAW_TOOLBOX_CURRENT_STROKE_OPACITY,
  SET_POLYGON_DRAW_TOOLBOX_CURRENT_FILL_OPACITY,
  SET_POLYGON_DRAW_TOOLBOX_CURRENT_STROKE_COLOR,
  SET_POLYGON_DRAW_TOOLBOX_CURRENT_FILL_COLOR,
  SET_MICRO_ARTICLE_BAR_CLICKED_STATE,
  SET_MICRO_ARTICLE_TOOL_CLICKED_STATE,
  SET_TEXT_TOOLBOX_CLICKED_STATE,
  SET_SHAPER_CLICKED_STATE,
} from "../constants";

const initialState = {
  data: false,
  microArticleBarClickedState: false,
  startDrawerClickState: false,
  rulerClickedState: false,
  stickyNotesClickedState: false,
  eraserClickedState: false,
  shapeClickedState: false,
  commentToolboxClickedState: false,
  textToolboxClickedState: false,
  polygonDrawToolboxClickedState: false,
  polygonDrawToolboxColors: [
    { id: 1, color: "#000000", status: 0 },
    { id: 2, color: "#157EFA", status: 0 },
    { id: 3, color: "#53D669", status: 1 },
    { id: 4, color: "#FED031", status: 0 },
    { id: 5, color: "#FC3142", status: 0 },
    { id: 6, color: "#95D4F3", status: 0 },
    { id: 7, color: "#D8C9FE", status: 0 },
    { id: 8, status: 0 },
  ],
  polygonDrawToolboxCurrentStrokeColor: COLORS_DATA[2].color,
  polygonDrawToolboxCurrentFillColor: COLORS_DATA[2].color,
  polygonDrawToolboxCurrentStrokeOpacity: 1,
  polygonDrawToolboxCurrentFillOpacity: 0.5,
  polygonDrawToolboxColorPickerState: false,
  lineDrawToolboxClickedState: false,
  microArticleToolClickedState: false,
  lineDrawToolboxWeight: 3,
  lineDrawToolboxColors: [
    { id: 1, color: "#000000", status: 0 },
    { id: 2, color: "#157EFA", status: 0 },
    { id: 3, color: "#53D669", status: 1 },
    { id: 4, color: "#FED031", status: 0 },
    { id: 5, color: "#FC3142", status: 0 },
    { id: 6, status: 0 },
  ],
  lineDrawToolboxCurrentColor: COLORS_DATA[2].color,
  lineDrawToolboxColorPickerState: false,
};

export const painterStartReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STICKY_NOTES_CLICKED_STATE:
      return {
        ...state,
        stickyNotesClickedState: action.payload,
      }
    case SET_POLYGON_DRAW_TOOLBOX_STATE:
      return {
        ...state,
        polygonDrawToolboxClickedState: action.payload,
      }
    case SET_POLYGON_DRAW_TOOLBOX_CURRENT_STROKE_OPACITY:
      return {
        ...state,
        polygonDrawToolboxCurrentStrokeOpacity: action.payload,
      }
    case SET_POLYGON_DRAW_TOOLBOX_CURRENT_FILL_OPACITY:
      return {
        ...state,
        polygonDrawToolboxCurrentFillOpacity: action.payload,
      }
    case SET_POLYGON_DRAW_TOOLBOX_CURRENT_STROKE_COLOR:
      return {
        ...state,
        polygonDrawToolboxCurrentStrokeColor: action.payload,
      }
    case SET_POLYGON_DRAW_TOOLBOX_CURRENT_FILL_COLOR:
      return {
        ...state,
        polygonDrawToolboxCurrentFillColor: action.payload,
      }
    case SET_POLYGON_DRAW_TOOLBOX_COLORS:
      return {
        ...state,
        polygonDrawToolboxColors: action.payload,
      }
    case SET_POLYGON_DRAW_TOOLBOX_COLOR_PICKER_STATE:
      return {
        ...state,
        polygonDrawToolboxColorPickerState: action.payload,
      }
    case SET_LINE_DRAW_TOOLBOX_COLOR_PICKER_STATE:
      return {
        ...state,
        lineDrawToolboxColorPickerState: action.payload,
      }
    case SET_LINE_DRAW_TOOLBOX_WEIGHT:
      return {
        ...state,
        lineDrawToolboxWeight: action.payload,
      }
    case SET_LINE_DRAW_TOOLBOX_CURRENT_COLOR:
      return {
        ...state,
        lineDrawToolboxCurrentColor: action.payload,
      }
    case SET_LINE_DRAW_TOOLBOX_COLORS:
      return {
        ...state,
        lineDrawToolboxColors: action.payload,
      }
    case SET_LINE_DRAW_TOOLBOX_STATE:
      return {
        ...state,
        lineDrawToolboxClickedState: action.payload,
      }
    case SET_MICRO_ARTICLE_BAR_CLICKED_STATE:
      return {
        ...state,
        microArticleBarClickedState: action.payload,
      };
    case SET_MICRO_ARTICLE_TOOL_CLICKED_STATE:
      return {
        ...state,
        microArticleToolClickedState: action.payload,
      };
    case SET_PAINTER_START:
      return {
        ...state,
        data: action.payload,
      };
    case SET_START_POINTER_CLICK_STATE:
      return {
        ...state,
        startDrawerClickState: action.payload,
      };
    case SET_PAINTER_COLOR:
      return {
        ...state,
        painterColor: action.payload,
      };
    case SET_RULER_CLICKED_STATE:
      return {
        ...state,
        rulerClickedState: action.payload,
      };
    case SET_ERASER_CLICKED_STATE:
      return {
        ...state,
        eraserClickedState: action.payload,
      };
    case SET_COMMENT_TOOLBOX_CLICKED_STATE:
      return {
        ...state,
        commentToolboxClickedState: action.payload,
      }
    case SET_TEXT_TOOLBOX_CLICKED_STATE:
      return {
        ...state,
        textToolboxClickedState: action.payload,
      }
     case SET_SHAPER_CLICKED_STATE:
        return {
          ...state,
          shapeClickedState: action.payload,
        };
    default:
      return state;
  }
};
