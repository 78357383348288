import { connect } from "react-redux";
import "./style.css";
import React from "react";
import {useTranslation} from "react-i18next";
const SearchSection = (props) => {
    const { styles = {}, handleSetSearchParams } = props;
    const {t} = useTranslation()
    return (
        <div style={styles.wrapper} className="card-body__search date-search">
            <input style={styles.input} type="text" placeholder={t('search')} onChange={handleSetSearchParams}/>
        </div>
    );
};
export default connect(null, null)(SearchSection);
