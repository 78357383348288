// src/components/CustomColorPicker/ColorGrid.js
import React from 'react';
import { List } from 'antd';
import { color_picker_data } from "../../../../utils/color_picker_data"; // Ensure this path is correct
import styles from './CustomColorPicker.module.css';

const ColorGrid = ({ onColorSelect }) => {
    return (
        <List
            grid={{
                gutter: 0, // Remove space between items
                column: 12, // Adjust based on the number of colors per row
            }}
            dataSource={color_picker_data}
            renderItem={(color, index) => (
                <List.Item key={index} className={styles.listItem}>
                    <div
                        className={styles.colorSquare}
                        style={{ backgroundColor: color }}
                        title={color} // Optional: Show color code on hover
                        onClick={() => onColorSelect(color)}
                        role="button"
                        tabIndex={0}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                                onColorSelect(color);
                            }
                        }}
                    />
                </List.Item>
            )}
        />
    );
};

export default ColorGrid;
