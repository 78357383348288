import React from "react";

const PresentationIcon = ({isActive, activeColor='#FFFFFFFF', disableColor = '#2C476CFF'}) => {
    return (
        <svg width="22" height="22" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_436_8372)">
                <path d="M50.5582 2.40586H28.9057C28.9057 1.07718 27.8286 0 26.4999 0C25.1712 0 24.0941 1.07718 24.0941 2.40586H2.44151C1.11282 2.40586 0.0356445 3.48304 0.0356445 4.81173V7.21759C0.0356445 8.54628 1.11282 9.62345 2.44151 9.62345V38.4935C2.44151 39.8222 3.51868 40.8994 4.84737 40.8994H25.2969V44.0149L18.4283 50.8836C17.9237 51.3157 17.8649 52.0751 18.2971 52.5797C18.7292 53.0843 19.4886 53.1431 19.9932 52.7109C20.0402 52.6705 20.0841 52.6267 20.1243 52.5797L26.4998 46.2042L32.8632 52.5676C33.2953 53.0722 34.0547 53.131 34.5593 52.6988C35.0639 52.2667 35.1227 51.5073 34.6905 51.0027C34.6502 50.9556 34.6063 50.9117 34.5593 50.8715L27.7027 44.0149V40.8994H48.1523C49.481 40.8994 50.5581 39.8222 50.5581 38.4935V9.62335C51.8868 9.62335 52.964 8.54617 52.964 7.21749V4.81162C52.964 3.48293 51.8869 2.40586 50.5582 2.40586ZM48.1524 38.4934H4.84737V9.62335H48.1524V38.4934ZM50.5582 7.21749H2.44151V4.81162H50.5582V7.21749Z"
                      fill={isActive ? activeColor : disableColor}/>
            </g>
            <defs>
                <clipPath id="clip0_436_8372">
                    <rect width="53" height="53" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};
export default PresentationIcon;
