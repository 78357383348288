import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

const Portal = ({ children, containerSelector = '.main', className }) => {
    // Create a div that will hold the portal content
    const portalNode = React.useRef(document.createElement('div'));

    useEffect(() => {
        const container = document.querySelector(containerSelector);

        if (container) {
            // Optionally, add a class to the portal node for styling
            portalNode.current.className = 'portal-content';
            if(className){
                portalNode.current.classList.add(className)
            }
            portalNode.current.style.height = 'fit-content';
            portalNode.current.style.zIndex = 999;
            container.appendChild(portalNode.current);
        } else {
            // If the container doesn't exist, create it
            const newContainer = document.createElement('div');
            // Remove the '.' from the selector to use it as a class name
            const className = containerSelector.startsWith('.')
                ? containerSelector.slice(1)
                : containerSelector;
            newContainer.className = className;
            document.body.appendChild(newContainer);
            newContainer.appendChild(portalNode.current);
        }

        // Cleanup function to remove only the portal node, not the entire container
        return () => {
            if (portalNode.current.parentNode) {
                portalNode.current.parentNode.removeChild(portalNode.current);
            }
        };
    }, [containerSelector]);

    return ReactDOM.createPortal(children, portalNode.current);
};

export default Portal;
