import i18n from '../../../locales/i18n'
import axios from 'axios'

export class MicroArticleService {
  static baseUrl =
    'https://article-340982108.review.api.bg.cluster.vecto.digital'

  static baseUrlAPI = `${MicroArticleService.baseUrl}/api`
  static getAllMicroArticleURL = () =>
    `${MicroArticleService.baseUrlAPI}/MicroArticle/DataProvider/${i18n.language === 'hy' ? 1 : 2}`
  static createMicroArticleURL = () =>
    `${MicroArticleService.baseUrlAPI}/MicroArticle`
  static updateMicroArticleMediaURL = (id) =>
    `${MicroArticleService.baseUrlAPI}/MicroArticle/${id}/Media`
  static getMicroArticleByIDURL = (id) =>
    `${MicroArticleService.baseUrlAPI}/MicroArticle/${id}`
  static updateMicroArticleByIDURL = (id) =>
    `${MicroArticleService.baseUrlAPI}/MicroArticle/${id}`
  static deleteMicroArticleByIDURL = (id) =>
    `${MicroArticleService.baseUrlAPI}/MicroArticle/${id}`

  static getAllMicroArticles = async (
    body = {
      pageSize: 9999,
      pageNumber: 0,
      ids: [],
      sort: [
        {
          sortColumn: 'title',
          sortDir: 'Asc',
        },
      ],
      userId: 109,
      relatedTopics: [],
      relatedLessons: [],
    },
  ) => {
    return await axios
      .post(this.getAllMicroArticleURL(), body)
      .then((res) => res)
  }

  static createMicroArticle = async (body) => {
    if (!body) {
      console.error('body is required for creating micro article')
      return
    }

    const responseData = await axios
      .post(this.createMicroArticleURL(), body)
      .then((res) => {
        const data = res.data
        if (data) {
          console.log('microArticleData', data)
          return res
        } else {
          console.log('something went wrong while fetching micro articles')
          return null
        }
      })

    return responseData
  }

  static getMicroArticleByID = async (id) => {
    if (!id) {
      console.error('id is required for getting micro article')
      return
    }

    return await axios.get(this.getMicroArticleByIDURL(id)).then((res) => res)
  }

  static updateMicroArticleByID = async (id, body) => {
    if (!id) {
      console.error('id is required for updating micro article')
      return
    }
    if (!body) {
      console.error('body is required for updating micro article')
      return
    }

    return await axios
      .put(this.updateMicroArticleByIDURL(id), body)
      .then((res) => res)
  }

  static deleteMicroArticleByID = async (id) => {
    if (!id) {
      console.error('id is required for deleting micro article')
      return
    }

    const responseData = await axios
      .delete(this.deleteMicroArticleByIDURL(id))
      .then((res) => {
        const data = res.data
        if (data) {
          console.log('microArticleData', data)
          return data
        } else {
          console.log('something went wrong while fetching micro articles')
          return []
        }
      })

    return responseData
  }

  static updateMicroArticleMedia = async (id, body) => {
    if (!id) {
      console.error('id is required for updating micro article media')
      return
    }
    if (!body) {
      console.error('body is required for updating micro article media')
      return
    }

    return await axios
      .put(this.updateMicroArticleMediaURL(id), body)
      .then((res) => res)
  }

  static getMicroArticlesForMap = async () => {
    const params = new URLSearchParams(window.location.search)
    const topicID = params.get('topicID')
    const lessonID = params.get('lessonID')
    const userData = JSON.parse(localStorage.getItem('userInfo'))

    const body = {
      pageSize: 9999,
      pageNumber: 0,
      userId: userData?.id,
      relatedTopics: topicID ? [topicID] : [],
      relatedLessons: lessonID ? [lessonID] : [],
    }

    try {
      const microArticlesCall = await this.getAllMicroArticles(body)
      const microArticles = microArticlesCall.data.data[0].items
      console.log('Micro articles:', microArticles)
      return microArticles
    } catch (error) {
      console.error('Error fetching micro articles for map:', error)
      return []
    }
  }

  static getMicroArticlesForBoard = async (page, userId) => {
    // const topicId = body.topicId;
    // const lessonId = body.lessonId;

    const payload = {
      pageSize: 10,
      pageNumber: page,
      userId: userId,
      relatedTopics: [],
      relatedLessons: [],
    }

    try {
      return await this.getAllMicroArticles(payload);
    } catch (error) {
      return null;
    }
  }
}
