import { useDispatch } from 'react-redux';
import {
    setCompassNewAngle,
    setCompassRotate,
    setDisableCompass,
    setResetCompass
} from "../../../store/actions/compassStateAction";
import {setIsPresentationShowF} from "../../../store/actions/presentationMode";
import {
    getCategoriesFromAPI,
    getFilteredCategoriesFromAPI,
    updateCategoriesToggle
} from "../../../store/actions/categories";
import {
    dispatchFilteredLegends,
    getLegendsFromAPI,
    setFilteredLegends,
    updateLegendToggle
} from "../../../store/actions/legends";
import {
    changeMapStyles,
    getBaseMapConfigurationsFromAPI,
    getBaseMapsFromAPI,
    setMapStyleExtraLayers,
    setMapStylesList,
    getBaseMapsSettingsFromAPI
} from "../../../store/actions/mapStyles";
import {getSpriteFromAPI, getSpriteURLFromAPI} from "../../../store/actions/sprite";
import {getLampInfo, setLampModalData, setLampModalState, setLampModalStateIndex} from "../../../store/actions/lamp";
import {setMapBounce, setMapZoom} from "../../../store/actions/zoom";
import {setTimeLineExpend} from "../../../store/actions/timeLineExpend";
import {getArticleFromAPI} from "../../../store/actions/articles";
import {setNextLessonID, setPrevLessonID, setSelectedLesson} from "../../../store/actions/handleLesson";
import {dispatchTopicNavigation, getTopicsFromAPI, setTopicId} from "../../../store/actions/topics";
import {
    setMiddleMaxTimeLineLessonsData,
    setMiddleTimeLineLessonsData,
    setMiddleTimeLineTopicsData, setTimeLineArticlesData, setTimeLineItems, setTimeLineLessonData,
    setTimeLineTopicsData, timeLineLoadingReaction, updateTimeLineGroupToggle
} from "../../../store/actions/timeLine";
import {getLessonsFromAPI, setLessonData} from "../../../store/actions/lessons";
import {loadingMap} from "../../../store/actions/map";
import {getQuizInfo} from "../../../store/actions/quiz";
import {
    dispatchMapMarkerInfo,
    getScreenShotSingleDataAPI,
    setMapCenterPositionST,
    setMapStylesIdST,
    setScreenShotBaseMapGroupId, setScreenShotBaseMapIsLightST,
    setTimeLIneClickState,
    setTimeLineCursorStatusST,
    setTimeLineEventDataST
} from "../../../store/actions/mapStateAction";
import {setSubjectsSettings} from "../../../store/actions/subjects";
import {getMicroArticleToolClickedState} from "../../../store/selectors";
import {setAllMicroArticles, setIsMicroArticlesLoading} from "../../../store/actions/microArticle";

const useMapDispatch = () => {
    const dispatch = useDispatch();

    return {
        setAllMicroArticles: (...args) => dispatch(setAllMicroArticles(...args)),
        setIsMicroArticlesLoading: (...args) => dispatch(setIsMicroArticlesLoading(...args)),
        getMicroArticleToolClickedState: (...args) => dispatch(getMicroArticleToolClickedState(...args)),
        getBaseMapsSettingsFromAPI: (...args) => dispatch(getBaseMapsSettingsFromAPI(...args)),
        setResetCompass: (...args) => dispatch(setResetCompass(...args)),
        setCompassNewAngle: (...args) => dispatch(setCompassNewAngle(...args)),
        setIsPresentationShowF: (...args) => dispatch(setIsPresentationShowF(...args)),
        setCompassRotate: (...args) => dispatch(setCompassRotate(...args)),
        getCategoriesFromAPI: (...args) => dispatch(getCategoriesFromAPI(...args)),
        getLegendsFromAPI: (...args) => dispatch(getLegendsFromAPI(...args)),
        getBaseMapsFromAPI: (...args) => dispatch(getBaseMapsFromAPI(...args)),
        getBaseMapConfigurationsFromAPI: (...args) => dispatch(getBaseMapConfigurationsFromAPI(...args)),
        getSpriteURLFromAPI: (...args) => dispatch(getSpriteURLFromAPI(...args)),
        getSpriteFromAPI: (...args) => dispatch(getSpriteFromAPI(...args)),
        dispatchFilteredLegends: (...args) => dispatch(dispatchFilteredLegends(...args)),
        getLampInfo: (...args) => dispatch(getLampInfo(...args)),
        getFilteredCategoriesFromAPI: (...args) => dispatch(getFilteredCategoriesFromAPI(...args)),
        setMapZoom: (...args) => dispatch(setMapZoom(...args)),
        setMapBounce: (...args) => dispatch(setMapBounce(...args)),
        setTimeLineExpend: (...args) => dispatch(setTimeLineExpend(...args)),
        updateCategoriesToggle: (...args) => dispatch(updateCategoriesToggle(...args)),
        updateLegendToggle: (...args) => dispatch(updateLegendToggle(...args)),
        getArticleFromAPI: (...args) => dispatch(getArticleFromAPI(...args)),
        setNextLessonID: (...args) => dispatch(setNextLessonID(...args)),
        dispatchTopicNavigation: (...args) => dispatch(dispatchTopicNavigation(...args)),
        setMapStyleExtraLayers: (...args) => dispatch(setMapStyleExtraLayers(...args)),
        setTimeLineTopicsData: (...args) => dispatch(setTimeLineTopicsData(...args)),
        setMiddleTimeLineTopicsData: (...args) => dispatch(setMiddleTimeLineTopicsData(...args)),
        setMiddleTimeLineLessonsData: (...args) => dispatch(setMiddleTimeLineLessonsData(...args)),
        setMiddleMaxTimeLineLessonsData: (...args) => dispatch(setMiddleMaxTimeLineLessonsData(...args)),
        setTimeLineLessonData: (...args) => dispatch(setTimeLineLessonData(...args)),
        setPrevLessonID: (...args) => dispatch(setPrevLessonID(...args)),
        setSelectedLesson: (...args) => dispatch(setSelectedLesson(...args)),
        updateTimeLineGroupToggle: (...args) => dispatch(updateTimeLineGroupToggle(...args)),
        setLampModalState: (...args) => dispatch(setLampModalState(...args)),
        setLampModalData: (...args) => dispatch(setLampModalData(...args)),
        setFilteredLegends: (...args) => dispatch(setFilteredLegends(...args)),
        setTopicId: (...args) => dispatch(setTopicId(...args)),
        setLessonData: (...args) => dispatch(setLessonData(...args)),
        getLessonsFromAPI: (...args) => dispatch(getLessonsFromAPI(...args)),
        timeLineLoadingReaction: (...args) => dispatch(timeLineLoadingReaction(...args)),
        setTimeLineArticlesData: (...args) => dispatch(setTimeLineArticlesData(...args)),
        loadingMap: (...args) => dispatch(loadingMap(...args)),
        setTimeLineItems: (...args) => dispatch(setTimeLineItems(...args)),
        getQuizInfo: (...args) => dispatch(getQuizInfo(...args)),
        getScreenShotSingleDataAPI: (...args) => dispatch(getScreenShotSingleDataAPI(...args)),
        setTimeLineCursorStatusST: (...args) => dispatch(setTimeLineCursorStatusST(...args)),
        setTimeLineEventDataST: (...args) => dispatch(setTimeLineEventDataST(...args)),
        setMapCenterPositionST: (...args) => dispatch(setMapCenterPositionST(...args)),
        changeMapStyles: (...args) => dispatch(changeMapStyles(...args)),
        setTimeLIneClickState: (...args) => dispatch(setTimeLIneClickState(...args)),
        getTopicsFromAPI: (...args) => dispatch(getTopicsFromAPI(...args)),
        setSubjectsSettings: (...args) => dispatch(setSubjectsSettings(...args)),
        setLampModalStateIndex: (...args) => dispatch(setLampModalStateIndex(...args)),
        setMapStylesList: (...args) => dispatch(setMapStylesList(...args)),
        setMapStylesIdST: (...args) => dispatch(setMapStylesIdST(...args)),
        setScreenShotBaseMapGroupId: (...args) => dispatch(setScreenShotBaseMapGroupId(...args)),
        setScreenShotBaseMapIsLightST: (...args) => dispatch(setScreenShotBaseMapIsLightST(...args)),
        setDisableCompass: (...args) => dispatch(setDisableCompass(...args)),
        dispatchMapMarkerInfo: (...args) => dispatch(dispatchMapMarkerInfo(...args)),
    };
}

export default useMapDispatch;
