// src/components/microArticle/components/DualDateInput/MicroArticleBoardCard.js
import React, { useEffect } from 'react';
import { Input, Tooltip, Checkbox, Typography } from 'antd';
import IconButton from './../../UI/IconButton';
import PropTypes from 'prop-types'; // Import PropTypes for type checking

const DualDateInput = ({
                           date1,
                           setDate1,
                           era1,
                           setEra1,
                           error1,
                           setError1,
                           withoutStart,
                           setWithoutStart,
                           date2,
                           setDate2,
                           era2,
                           setEra2,
                           error2,
                           setError2,
                           withoutEnd,
                           setWithoutEnd,
                           alwaysShowOnMap, // New prop
                       }) => {
    // Handler for date input changes
    const handleDateChange = (setterDate, setterError, value, era, isStart) => {
        // Allow digits and '/' separators
        const cleaned = value.replace(/[^\d/]/g, '');

        setterDate(cleaned);

        // Validation for individual date format
        if (isValidDate(cleaned, era)) {
            setterError('');
        } else if (cleaned.length > 0) {
            setterError('Date must be in YYYY/MM/DD format with valid values.');
        } else {
            setterError('');
        }
    };

    // Function to validate date string in Y+/MM/DD format (accepts partial dates)
    const isValidDate = (dateString, era) => {
        // Check for the pattern Y+(/MM(/DD)?)?
        const regex = /^(\d+)(\/(\d{1,2})(\/(\d{1,2}))?)?$/;
        const match = dateString.match(regex);
        if (!match) return false;

        let year = parseInt(match[1], 10);
        let month = match[3] ? parseInt(match[3], 10) : null;
        let day = match[5] ? parseInt(match[5], 10) : null;

        // Year validation
        if (era === 'BC') {
            if (year < 1 || year > 4500) return false;
        } else { // AD
            const currentYear = new Date().getFullYear();
            if (year < 1 || year > currentYear) return false;
        }

        // Month validation
        if (month !== null) {
            if (month < 1 || month > 12) return false;
        }

        // Day validation
        if (day !== null) {
            if (day < 1 || day > 31) return false;

            // If month is provided, validate day against month length
            if (month !== null) {
                const monthLengths = [
                    31,
                    isLeapYear(year) ? 29 : 28,
                    31,
                    30,
                    31,
                    30,
                    31,
                    31,
                    30,
                    31,
                    30,
                    31,
                ];
                if (day > monthLengths[month - 1]) return false;
            }
        }

        return true;
    };

    // Function to determine if a year is a leap year
    const isLeapYear = (year) => {
        if (year % 4 !== 0) return false;
        if (year % 100 !== 0) return true;
        return year % 400 === 0;
    };

    // Function to compare two dates
    // Returns 1 if dateA > dateB, -1 if dateA < dateB, 0 if equal
    const compareDates = (dateA, eraA, dateB, eraB) => {
        if (!dateA || !dateB) return null; // Cannot compare if one of the dates is missing

        const parseDate = (date, era) => {
            const parts = date.split('/').map(Number);
            return {
                era,
                year: parts[0],
                month: parts[1] || 1,
                day: parts[2] || 1,
            };
        };

        const a = parseDate(dateA, eraA);
        const b = parseDate(dateB, eraB);

        // Handle eras: BC is earlier than AD
        if (a.era === 'BC' && b.era === 'AD') return -1;
        if (a.era === 'AD' && b.era === 'BC') return 1;

        // If both are BC, higher year means earlier date
        if (a.era === 'BC' && b.era === 'BC') {
            if (a.year > b.year) return -1;
            if (a.year < b.year) return 1;
        }

        // If both are AD, higher year means later date
        if (a.era === 'AD' && b.era === 'AD') {
            if (a.year > b.year) return 1;
            if (a.year < b.year) return -1;
        }

        // Years are equal, compare months
        if (a.month > b.month) return a.era === 'AD' ? 1 : -1;
        if (a.month < b.month) return a.era === 'AD' ? -1 : 1;

        // Months are equal, compare days
        if (a.day > b.day) return a.era === 'AD' ? 1 : -1;
        if (a.day < b.day) return a.era === 'AD' ? -1 : 1;

        return 0; // Dates are equal
    };

    // Function to validate the relationship between start and end dates
    const validateDateRange = () => {
        if (!date1 || !date2) {
            // One of the dates is missing; do not set any range errors
            return;
        }

        const comparison = compareDates(date1, era1, date2, era2);
        if (comparison === null) {
            return;
        }

        if (comparison > 0) {
            // Start date is after end date
            setError1('Start date cannot be later than end date.');
            setError2('End date cannot be earlier than start date.');
        } else {
            // Clear range errors if any
            if (error1 === 'Start date cannot be later than end date.') {
                setError1('');
            }
            if (error2 === 'End date cannot be earlier than start date.') {
                setError2('');
            }
        }
    };

    // useEffect to trigger range validation whenever dates or eras change
    useEffect(() => {
        validateDateRange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date1, era1, date2, era2]);

    // Function to render Input with Tooltip and conditional styling
    const renderInputWithTooltip = (value, onChange, error, disabled) => (
        <Tooltip
            title={error}
            placement="top"
            open={!!error}
            overlayStyle={{ maxWidth: '250px' }}
        >
            <Input
                type="text" // Ensure the input type is text to allow '/'
                value={value}
                onChange={onChange}
                placeholder="YYYY/MM/DD"
                style={{ width: '150px' }}
                status={error ? 'error' : undefined}
                disabled={disabled}
            />
        </Tooltip>
    );

    // Function to toggle era
    const toggleEra = (currentEra, setEra) => {
        setEra(currentEra === 'AD' ? 'BC' : 'AD');
    };

    // Effect to enforce "Always show on the map" behavior
    useEffect(() => {
        if (alwaysShowOnMap) {
            setWithoutStart(true);
            setWithoutEnd(true);
            setDate1('');
            setDate2('');
            setError1('');
            setError2('');
        }
    }, [alwaysShowOnMap, setWithoutStart, setWithoutEnd, setDate1, setDate2, setError1, setError2]);

    return (
        <div style={{ display: 'flex', gap: '20px' }}>
            {/* First Date Input Group - Start Date */}
            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <IconButton
                        style={{ textAlign: 'center' }}
                        icon={
                            <Typography.Text
                                style={{
                                    fontWeight: 400,
                                    fontSize: '12px',
                                    color: era1 === 'BC' ? 'white' : '#2C476C',
                                }}>
                                Ք.Ա
                            </Typography.Text>
                        }
                        onClick={() => toggleEra(era1, setEra1)}
                        toggled={era1 === 'BC'}
                        disabled={alwaysShowOnMap || withoutStart} // Disable when "Always show on the map" or "Without start" is checked
                        aria-label="Toggle Era"
                    />
                    {renderInputWithTooltip(
                        date1,
                        (e) => handleDateChange(setDate1, setError1, e.target.value, era1, true),
                        error1,
                        alwaysShowOnMap || withoutStart, // Disable input when "Always show on the map" or "Without start" is checked
                    )}
                </div>
                <Checkbox
                    checked={withoutStart}
                    onChange={(e) => {
                        const checked = e.target.checked;
                        setWithoutStart(checked);
                        if (checked) {
                            setDate1('');
                            setError1('');
                        }
                    }}
                    disabled={alwaysShowOnMap} // Disable when "Always show on the map" is checked
                >
                    Առանց սկիզբ
                </Checkbox>
            </div>

            {/* Second Date Input Group - End Date */}
            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <IconButton
                        style={{ textAlign: 'center' }}
                        icon={
                            <Typography.Text
                                style={{
                                    fontWeight: 400,
                                    fontSize: '12px',
                                    color: era2 === 'BC' ? 'white' : '#2C476C',
                                }}>
                                Ք.Ա
                            </Typography.Text>
                        }
                        onClick={() => toggleEra(era2, setEra2)}
                        toggled={era2 === 'BC'}
                        disabled={alwaysShowOnMap || withoutEnd} // Disable when "Always show on the map" or "Without end" is checked
                        aria-label="Toggle Era"
                    />
                    {renderInputWithTooltip(
                        date2,
                        (e) => handleDateChange(setDate2, setError2, e.target.value, era2, false),
                        error2,
                        alwaysShowOnMap || withoutEnd, // Disable input when "Always show on the map" or "Without end" is checked
                    )}
                </div>
                <Checkbox
                    checked={withoutEnd}
                    onChange={(e) => {
                        const checked = e.target.checked;
                        setWithoutEnd(checked);
                        if (checked) {
                            setDate2('');
                            setError2('');
                        }
                    }}
                    disabled={alwaysShowOnMap} // Disable when "Always show on the map" is checked
                >
                    Առ այսօր
                </Checkbox>
            </div>
        </div>
    );
};

// PropTypes for type checking
DualDateInput.propTypes = {
    date1: PropTypes.string.isRequired,
    setDate1: PropTypes.func.isRequired,
    era1: PropTypes.string.isRequired,
    setEra1: PropTypes.func.isRequired,
    error1: PropTypes.string.isRequired,
    setError1: PropTypes.func.isRequired,
    withoutStart: PropTypes.bool.isRequired,
    setWithoutStart: PropTypes.func.isRequired,
    date2: PropTypes.string.isRequired,
    setDate2: PropTypes.func.isRequired,
    era2: PropTypes.string.isRequired,
    setEra2: PropTypes.func.isRequired,
    error2: PropTypes.string.isRequired,
    setError2: PropTypes.func.isRequired,
    withoutEnd: PropTypes.bool.isRequired,
    setWithoutEnd: PropTypes.func.isRequired,
    alwaysShowOnMap: PropTypes.bool.isRequired, // New prop
};

export default DualDateInput;
