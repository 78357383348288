import React from 'react';

const MicroArticlesIcon = ({isActive}) => {
    return (
        <svg id='micro-article-icon' width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M27.0005 30.2172C23.1234 30.2172 19.9805 27.0743 19.9805 23.1972C19.9805 19.3202 23.1234 16.1772 27.0005 16.1772C30.8775 16.1772 34.0205 19.3202 34.0205 23.1972C34.0205 27.0743 30.8775 30.2172 27.0005 30.2172Z"
                  stroke={isActive ? "#FF6539" : "#2C476C"} stroke-width="5"/>
            <path
                d="M45.8558 19.125C48.4433 30.555 41.3333 40.23 35.1008 46.215C30.5783 50.58 23.4233 50.58 18.8783 46.215C12.6683 40.23 5.55831 30.5325 8.14581 19.1025C12.5783 -0.382495 41.4458 -0.359994 45.8558 19.125Z"
                stroke={isActive ? "#FF6539" : "#2C476C"} stroke-width="5"/>
        </svg>
    );
};

export default MicroArticlesIcon;
