import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import MicroArticleToolIcon from '../../../assets/imgs/PaintBar/micro_article_tool_icon.svg'
import { setMicroArticleToolClickedState } from '../../../store/actions/painterStart'
import {
  getAllMicroArticles, getMapStyledId,
  getMicroArticleBarClickedState,
  getMicroArticleToolClickedState,
} from '../../../store/selectors'
import { connect } from 'react-redux'
import { changeCursorIcon, CURSOR_TYPE } from '../../../shared/mockData'
import { isMobile } from 'react-device-detect'
import {MICRO_ARTICLE_BACKGROUND_LAYER, MICRO_ARTICLE_LAYER, mockMicroArticleListOfItems} from '../utils'
import { v4 as uuidv4 } from 'uuid'
import usePulseAnimation from '../hooks/usePulseAnimation'
import PopupForm from '../components/PopupForm'
import ViewPopup from '../components/PopupDetail'
import Portal from '../components/Portal'
import { MicroArticleService } from '../service'
import { useSearchParams } from 'react-router-dom'
import { setAllMicroArticles } from '../../../store/actions/microArticle'

const MicroArticleTool = ({
  setMicroArticleToolClickedState,
  getMicroArticleToolClickedState,
  getMicroArticleBarClickedState,
  getAllMicroArticles,
  setAllMicroArticles,
  getMapStyledId,
  globalLoading,
  map,
}) => {

  const draggingFeatureIdRef = useRef(null)
  const iconRef = useRef('geographical_mountain-pass')
  const isTransitioningRef = useRef(false)
  const currentFeatureIdRef = useRef(null)
  const oldFeaturePropertiesRef = useRef({}) // Added ref to store old properties
  const hasRun = useRef(false);

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalCoordinates, setModalCoordinates] = useState({ lng: 0, lat: 0 })
  const [isViewPopupVisible, setIsViewPopupVisible] = useState(false)
  const [currentFeatureId, setCurrentFeatureId] = useState(null)
  const [currentFeatureMicroArticleID, setCurrentFeatureMicroArticleID] =
    useState(null)

  const [isEdit, setIsEdit] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const isEditRef = useRef(isEdit)
  const isCreatingRef = useRef(isCreating)

  useEffect(() => {
    isEditRef.current = isEdit
  }, [isEdit])

  useEffect(() => {
    isCreatingRef.current = isCreating
  }, [isCreating])

  // Use effect to store old properties when entering edit mode
  useEffect(() => {
    if (isModalVisible && isEdit) {
      const featureId = currentFeatureIdRef.current
      if (featureId) {
        const feature = microArticleFeaturesRef.current.features.find(
          (f) => f.properties.id === featureId,
        )
        if (feature) {
          oldFeaturePropertiesRef.current = {
            coordinates: [...feature.geometry.coordinates],
            backgroundColor: feature.properties.backgroundColor,
          }
        }
      }
    }
  }, [isModalVisible, isEdit])

  // with cleaning the url
  useEffect(() => {
    if (!globalLoading && !hasRun.current) {
      hasRun.current = true; // Ensure the effect runs only once

      const timer = setTimeout(() => {
        // Parse the URL parameters
        const urlParams = new URLSearchParams(window.location.search);
        const microarticleID = urlParams.get('microarticleID');

        if (microarticleID) {
          const feature = microArticleFeaturesRef.current.features.find(
              (f) => String(f.properties.microArticleID) === String(microarticleID)
          );

          if (feature) {
            // Start pulsing the feature
            startPulse(feature);

            // Set current feature IDs
            setCurrentFeatureId(feature.properties.id);
            setCurrentFeatureMicroArticleID(feature.properties.microArticleID);
            currentFeatureIdRef.current = feature.properties.id;

            // Set modal coordinates
            setModalCoordinates({
              lng: feature.geometry.coordinates[0],
              lat: feature.geometry.coordinates[1],
            });

            // Open view popup
            setIsViewPopupVisible(true);

            // Fly to feature location on the map
            map.flyTo({ center: feature.geometry.coordinates, zoom: map.getMaxZoom() });
          }

          // **Remove the `microarticleID` from the URL**

          // Create a new URL object based on the current window location
          const url = new URL(window.location);

          // Remove the `microarticleID` parameter
          url.searchParams.delete('microarticleID');

          // Update the browser's address bar without reloading the page
          window.history.replaceState({}, document.title, url.toString());
        }
      }, 1000);

      // Cleanup the timer if the component unmounts before the timeout
      return () => clearTimeout(timer);
    }
  }, [globalLoading]); // Dependency on globalLoading

  // useEffect(() => {
  //   if (!globalLoading && !hasRun.current) {
  //     hasRun.current = true; // Ensure the effect runs only once
  //
  //     const timer = setTimeout(() => {
  //       // Your functionality here
  //       const urlParams = new URLSearchParams(window.location.search);
  //       const microarticleID = urlParams.get('microarticleID');
  //
  //       if (microarticleID) {
  //         const feature = microArticleFeaturesRef.current.features.find(
  //             (f) => String(f.properties.microArticleID) === String(microarticleID)
  //         );
  //
  //         if (feature) {
  //           // Start pulsing
  //           startPulse(feature);
  //           // Set current feature IDs
  //           setCurrentFeatureId(feature.properties.id);
  //           setCurrentFeatureMicroArticleID(feature.properties.microArticleID);
  //           currentFeatureIdRef.current = feature.properties.id;
  //           // Set modal coordinates
  //           setModalCoordinates({
  //             lng: feature.geometry.coordinates[0],
  //             lat: feature.geometry.coordinates[1],
  //           });
  //           // Open view popup
  //           setIsViewPopupVisible(true);
  //           // Fly to feature location
  //           map.flyTo({ center: feature.geometry.coordinates, zoom: map.getMaxZoom() });
  //         }
  //       }
  //     }, 1000);
  //
  //     // Cleanup the timer if the component unmounts before the timeout
  //     return () => clearTimeout(timer);
  //   }
  // }, [globalLoading]); // Dependency on globalLoading

  useEffect(() => {
    setTimeout(() => {
      createSourceAndLayers();
      if (getAllMicroArticles) {
        microArticleFeaturesRef.current.features = getAllMicroArticles.map((item) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [item.longitude, item.latitude],
          },
          properties: {
            id: uuidv4(),
            title: item.title,
            microArticleID: item.id,
            backgroundColor: item.iconBackgroundColor,
            imageUrl: item.imageUrl,
            radius: 10,
            opacity: 0.5,
          },
        }));
      }
      let mainSource = getMicroArticlesSource()

      if (mainSource) {
        mainSource.setData(microArticleFeaturesRef.current);
      } else {
        map.addSource(MICRO_ARTICLE_LAYER, {
          type: 'geojson',
          data: microArticleFeaturesRef.current,
        });
      }
    }, 2000)
  }, [getMapStyledId])

  const microArticleFeaturesRef = useRef({
    type: 'FeatureCollection',
    features: [],
  })

  const { startPulse, stopPulse, pulsingFeatureRef } = usePulseAnimation(
    map,
    MICRO_ARTICLE_BACKGROUND_LAYER,
    'pulse-source',
    'pulse-layer',
  )

  const changeIsViewPopupVisible = useCallback((bool) => {
    setIsViewPopupVisible(bool)
  }, [])

  const changeEditMode = useCallback((bool) => {
    setIsEdit(bool)
  }, [])

  const changeIsModalVisible = useCallback((bool) => {
    setIsModalVisible(bool)
  }, [])

  const handleCreateSuccess = useCallback((microArticleId) => {
    const featureId = currentFeatureIdRef.current
    if (!featureId) {
      console.warn('No current feature ID found.')
      return
    }

    const feature = microArticleFeaturesRef.current.features.find(
      (f) => f.properties.id === featureId,
    )

    if (feature) {
      feature.properties.microArticleID = microArticleId

      // Update the map source to reflect the new property
      const mainSource = map.getSource(MICRO_ARTICLE_LAYER)
      if (mainSource) {
        mainSource.setData(microArticleFeaturesRef.current)
      } else {
        // If the source doesn't exist, add it
        map.addSource(MICRO_ARTICLE_LAYER, {
          type: 'geojson',
          data: microArticleFeaturesRef.current,
        })
      }
    } else {
      console.warn(`Feature with ID ${featureId} not found.`)
    }
  }, [])

  const mapClickHandler = useCallback((e) => {
    const { lng, lat } = e.lngLat

    const newFeature = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [lng, lat],
      },
      properties: {
        id: uuidv4(),
        title: '',
        backgroundColor: '#036147',
        radius: 10,
        opacity: 0.5,
      },
    }

    microArticleFeaturesRef.current.features.push(newFeature)
    currentFeatureIdRef.current = newFeature.properties.id

    map.flyTo({ center: [lng, lat] })

    const mainSource = map.getSource(MICRO_ARTICLE_LAYER)
    if (mainSource) {
      mainSource.setData(microArticleFeaturesRef.current)
    }

    startPulse(newFeature)

    setTimeout(() => {
      setMicroArticleToolClickedState(false)
    }, 50)

    setModalCoordinates({ lng, lat })
    setIsModalVisible(true)
    setIsCreating(true)
  }, [])

  const handleModalSave = useCallback(
    (formData) => {
      const featureId = currentFeatureIdRef.current
      if (!featureId) return

      const feature = microArticleFeaturesRef.current.features.find(
        (f) => f.properties.id === featureId,
      )
      if (feature) {
        feature.properties.title = formData.title || feature.properties.title
        feature.properties.backgroundColor =
          formData.color || feature.properties.backgroundColor

        const mainSource = map.getSource(MICRO_ARTICLE_LAYER)
        if (mainSource) {
          mainSource.setData(microArticleFeaturesRef.current)
        }

        if (
          pulsingFeatureRef.current &&
          pulsingFeatureRef.current.featureId === featureId
        ) {
          pulsingFeatureRef.current.backgroundColor =
            feature.properties.backgroundColor
        }
      }
    },
    [pulsingFeatureRef],
  )

  const onFeatureClick = useCallback((e) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: [MICRO_ARTICLE_BACKGROUND_LAYER],
    })

    if (!features.length) return

    const feature = features[0]
    console.log('feature', feature)
    const featureId = feature.properties.id
    const [lng, lat] = feature.geometry.coordinates

    const isAlreadySelected =
      pulsingFeatureRef.current &&
      pulsingFeatureRef.current.featureId === featureId

    if (!isAlreadySelected) {
      if (isTransitioningRef.current) return

      const cubicEaseOut = (t) => 1 - Math.pow(1 - t, 3)

      map.easeTo({
        center: [lng, lat],
        zoom: map.getMaxZoom(),
        duration: 3000,
        easing: cubicEaseOut,
        essential: true,
      })

      isTransitioningRef.current = true

      map.once('moveend', () => {
        isTransitioningRef.current = false
      })
    }

    if (!isAlreadySelected) {
      startPulse(feature)
      setCurrentFeatureMicroArticleID(feature.properties.microArticleID)
      console.log(
        'feature.properties.microArticleID',
        feature.properties.microArticleID,
      )
      setCurrentFeatureId(featureId)
      setModalCoordinates({ lng, lat })
      setIsViewPopupVisible(true)
      currentFeatureIdRef.current = featureId
    }
  }, [])

  const deleteCurrentFeature = useCallback(() => {
    const featureId = currentFeatureIdRef.current
    if (!featureId) return

    microArticleFeaturesRef.current.features =
      microArticleFeaturesRef.current.features.filter(
        (f) => f.properties.id !== featureId,
      )

    const mainSource = map.getSource(MICRO_ARTICLE_LAYER)
    if (mainSource) {
      mainSource.setData(microArticleFeaturesRef.current)
    }

    const pulseSource = map.getSource('pulse-source')
    if (pulseSource && pulsingFeatureRef.current?.featureId === featureId) {
      pulseSource.setData({
        type: 'FeatureCollection',
        features: [],
      })
      pulsingFeatureRef.current = null
    }

    currentFeatureIdRef.current = null
    setIsModalVisible(false)
    setModalCoordinates({ lng: 0, lat: 0 })
    setIsViewPopupVisible(false)
  }, [])

  const handleCoordinatesChange = useCallback(({ lat, lng }) => {
    const featureId = currentFeatureIdRef.current
    if (!featureId) return

    const feature = microArticleFeaturesRef.current.features.find(
      (f) => f.properties.id === featureId,
    )
    if (feature) {
      feature.geometry.coordinates = [lng, lat]
      const mainSource = map.getSource(MICRO_ARTICLE_LAYER)
      if (mainSource) {
        mainSource.setData(microArticleFeaturesRef.current)
      }

      if (
        pulsingFeatureRef.current &&
        pulsingFeatureRef.current.featureId === featureId
      ) {
        pulsingFeatureRef.current.coordinates = [lng, lat]
      }

      map.flyTo({ center: [lng, lat] })
    }

    setModalCoordinates({ lng, lat })
  }, [])

  const handleTitleChange = useCallback((title) => {
    const featureId = currentFeatureIdRef.current
    if (!featureId) return

    const feature = microArticleFeaturesRef.current.features.find(
      (f) => f.properties.id === featureId,
    )
    if (feature) {
      feature.properties.title = title
      const mainSource = map.getSource(MICRO_ARTICLE_LAYER)
      if (mainSource) {
        mainSource.setData(microArticleFeaturesRef.current)
      }
    }
  }, [])

  const handleColorChange = useCallback((color) => {
    const featureId = currentFeatureIdRef.current
    if (!featureId) return

    const feature = microArticleFeaturesRef.current.features.find(
      (f) => f.properties.id === featureId,
    )
    if (feature) {
      feature.properties.backgroundColor = color
      const mainSource = map.getSource(MICRO_ARTICLE_LAYER)
      if (mainSource) {
        mainSource.setData(microArticleFeaturesRef.current)
      }

      if (
        pulsingFeatureRef.current &&
        pulsingFeatureRef.current.featureId === featureId
      ) {
        pulsingFeatureRef.current.backgroundColor = color
      }
    }
  }, [])

  const createSourceAndLayers = useCallback(() => {
    if (!map.getSource(MICRO_ARTICLE_LAYER)) {
      map.addSource(MICRO_ARTICLE_LAYER, {
        type: 'geojson',
        data: microArticleFeaturesRef.current,
      })
    }

    if (!map.getSource('pulse-source')) {
      map.addSource('pulse-source', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      })
    }

    if (!map.getLayer(MICRO_ARTICLE_BACKGROUND_LAYER)) {
      map.addLayer({
        id: MICRO_ARTICLE_BACKGROUND_LAYER,
        type: 'circle',
        source: MICRO_ARTICLE_LAYER,
        paint: {
          'circle-radius': 15,
          'circle-color': ['get', 'backgroundColor'],
          'circle-stroke-width': 4,
          'circle-stroke-color': '#CBC0D3',
        },
      })
    }

    if (!map.getLayer(`${MICRO_ARTICLE_LAYER}-symbol`)) {
      map.addLayer({
        id: `${MICRO_ARTICLE_LAYER}-symbol`,
        type: 'symbol',
        source: MICRO_ARTICLE_LAYER,
        layout: {
          'icon-image': iconRef.current,
          'icon-size': 1.3,
          'icon-allow-overlap': true,
        },
        paint: {
          'icon-color': '#FFFFFF',
        },
      })
    }

    if (!map.getLayer(`${MICRO_ARTICLE_LAYER}-labels`)) {
      map.addLayer(
        {
          id: `${MICRO_ARTICLE_LAYER}-labels`,
          type: 'symbol',
          source: MICRO_ARTICLE_LAYER,
          layout: {
            'text-field': ['get', 'title'],
            'text-font': ['GHEA Grapalat Regular'],
            'text-size': 16,
            'text-offset': [0, 1.2],
            'text-anchor': 'top',
            'text-allow-overlap': false,
            'text-letter-spacing': 0.05,
          },
          paint: {
            'text-color': '#0C0C0C',
            'icon-halo-blur': 1,
            'text-halo-blur': 1,
            'icon-halo-width': 1.3,
            'text-halo-width': 1.3,
          },
        },
        MICRO_ARTICLE_BACKGROUND_LAYER,
      )
    }

    if (!map.getLayer('pulse-layer')) {
      map.addLayer(
        {
          id: 'pulse-layer',
          type: 'circle',
          source: 'pulse-source',
          paint: {
            'circle-radius': ['get', 'radius'],
            'circle-color': ['get', 'backgroundColor'],
            'circle-opacity': ['get', 'opacity'],
          },
        },
        MICRO_ARTICLE_BACKGROUND_LAYER,
      )
    }
  }, [])

  const getMicroArticlesSource = () => map.getSource(MICRO_ARTICLE_LAYER)


  const onDragMove = useCallback((e) => {
    if (!draggingFeatureIdRef.current) return

    const feature = microArticleFeaturesRef.current.features.find(
      (f) => f.properties.id === draggingFeatureIdRef.current,
    )
    if (feature) {
      feature.geometry.coordinates = [e.lngLat.lng, e.lngLat.lat]
      const mainSource = map.getSource(MICRO_ARTICLE_LAYER)
      if (mainSource) {
        mainSource.setData(microArticleFeaturesRef.current)
      }

      if (
        pulsingFeatureRef.current &&
        pulsingFeatureRef.current.featureId === feature.properties.id
      ) {
        pulsingFeatureRef.current.coordinates = [e.lngLat.lng, e.lngLat.lat]
      }
    }
  }, [])

  const onDragEnd = useCallback((e) => {
    const feature = microArticleFeaturesRef.current.features.find(
      (f) => f.properties.id === draggingFeatureIdRef.current,
    )

    if (feature) {
      startPulse(feature)
    }

    if (draggingFeatureIdRef.current) {
      draggingFeatureIdRef.current = null
      map.getCanvas().style.cursor = ''
    }

    map.setLayoutProperty(
      `${MICRO_ARTICLE_LAYER}-labels`,
      'visibility',
      'visible',
    )

    setModalCoordinates({ lng: e.lngLat.lng, lat: e.lngLat.lat })
    map.dragPan.enable()
    map.off('mousemove', onDragMove)
    map.flyTo({
      center: [e.lngLat.lng, e.lngLat.lat],
    })
  }, [])

  const onDragStart = useCallback((e) => {
    if (!isEditRef.current && !isCreatingRef.current) return

    const features = map.queryRenderedFeatures(e.point, {
      layers: [MICRO_ARTICLE_BACKGROUND_LAYER],
    })

    if (!features.length) return

    const selectedFeatureId = pulsingFeatureRef.current
      ? pulsingFeatureRef.current.featureId
      : null

    const feature = features[0]
    const featureId = feature.properties.id

    stopPulse()

    if (featureId !== selectedFeatureId) {
      return
    }

    map.dragPan.disable()

    draggingFeatureIdRef.current = featureId
    map.getCanvas().style.cursor = 'grabbing'

    map.setLayoutProperty(`${MICRO_ARTICLE_LAYER}-labels`, 'visibility', 'none')

    map.on('mousemove', onDragMove)
    map.once('mouseup', onDragEnd)
  }, [])

  const onPopupClose = useCallback(() => {
    if (isEdit) {
      // Revert the feature's coordinates and color to the old values
      const featureId = currentFeatureIdRef.current
      if (featureId && oldFeaturePropertiesRef.current) {
        const feature = microArticleFeaturesRef.current.features.find(
          (f) => f.properties.id === featureId,
        )
        if (feature) {
          feature.geometry.coordinates = [
            ...oldFeaturePropertiesRef.current.coordinates,
          ]
          feature.properties.backgroundColor =
            oldFeaturePropertiesRef.current.backgroundColor

          const mainSource = map.getSource(MICRO_ARTICLE_LAYER)
          if (mainSource) {
            mainSource.setData(microArticleFeaturesRef.current)
          }

          if (
            pulsingFeatureRef.current &&
            pulsingFeatureRef.current.featureId === featureId
          ) {
            pulsingFeatureRef.current.coordinates = [
              ...oldFeaturePropertiesRef.current.coordinates,
            ]
            pulsingFeatureRef.current.backgroundColor =
              oldFeaturePropertiesRef.current.backgroundColor
          }

          // Update modal coordinates to the reverted ones
          setModalCoordinates({
            lng: feature.geometry.coordinates[0],
            lat: feature.geometry.coordinates[1],
          })
        }
      }
    }
    setIsModalVisible(false)
    setIsEdit(false)
    setIsCreating(false)
    setCurrentFeatureId(null)
    setCurrentFeatureMicroArticleID('asd')
    stopPulse()
  }, [])

  const onViewPopupClose = useCallback(() => {
    setIsViewPopupVisible(false)
    stopPulse()
    setModalCoordinates({ lng: 0, lat: 0 })
    setIsEdit(false)
    setIsCreating(false)
    setCurrentFeatureId(null)
    setCurrentFeatureMicroArticleID('123')
  }, [])

  // useEffect(() => {
  //   const loadMicroArticles = async () => {
  //     try {
  //       // Fetch micro articles from API
  //       const microArticles = await MicroArticleService.getMicroArticlesForMap()
  //       setAllMicroArticles(microArticles)
  //     } catch (error) {
  //       console.error('Error loading micro articles:', error)
  //     }
  //   }
  //   debugger
  //   loadMicroArticles();
  // }, [])

  useEffect(() => {
    // createSourceAndLayers()

    map.on('mousedown', MICRO_ARTICLE_BACKGROUND_LAYER, onDragStart)
    map.on('click', MICRO_ARTICLE_BACKGROUND_LAYER, onFeatureClick)

    return () => {
      map.off('mousedown', MICRO_ARTICLE_BACKGROUND_LAYER, onDragStart)
      map.off('mousemove', onDragMove)
      map.off('mouseup', onDragEnd)
      map.off('click', MICRO_ARTICLE_BACKGROUND_LAYER, onFeatureClick)
      stopPulse()
    }
  }, [])

  useEffect(() => {
    if(!getAllMicroArticles) return
    createSourceAndLayers();
    microArticleFeaturesRef.current.features = getAllMicroArticles.map((item) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [item.longitude, item.latitude],
      },
      properties: {
        id: uuidv4(),
        title: item.title,
        microArticleID: item.id,
        backgroundColor: item.iconBackgroundColor,
        imageUrl: item.imageUrl,
        radius: 10,
        opacity: 0.5,
      },
    }));
    // microArticleFeaturesRef.current.features = mockMicroArticleListOfItems.map(
    //     (item) => ({
    //       type: 'Feature',
    //       geometry: {
    //         type: 'Point',
    //         coordinates: item.coordinates,
    //       },
    //       properties: {
    //         id: uuidv4(),
    //         title: item.title,
    //         microArticleID: item.id,
    //         backgroundColor: item.color,
    //         imageUrl: item.imageUrl,
    //         radius: 10,
    //         opacity: 0.5,
    //       },
    //     }),
    // )

    // console.log('newFeaturesExample[0]', newFeaturesExample[0]);
    console.log('getAllMicroArticles[0]', getAllMicroArticles[0]);
    console.log('microArticleFeaturesRef.current[0]', microArticleFeaturesRef.current.features[0]);

    let mainSource = getMicroArticlesSource()

    if (mainSource) {
      mainSource.setData(microArticleFeaturesRef.current);
    } else {
      map.addSource(MICRO_ARTICLE_LAYER, {
        type: 'geojson',
        data: microArticleFeaturesRef.current,
      });
    }

  }, [getAllMicroArticles])

  useEffect(() => {
    if (getMicroArticleBarClickedState && getMicroArticleToolClickedState) {
      setTimeout(() => {
        changeCursorIcon(CURSOR_TYPE.DRAW)
      }, 50)
      map.on(isMobile ? 'touchstart' : 'click', mapClickHandler)
    } else {
      changeCursorIcon()
      map.off(isMobile ? 'touchstart' : 'click', mapClickHandler)
      setMicroArticleToolClickedState(false)
    }
  }, [getMicroArticleBarClickedState, getMicroArticleToolClickedState])

  return (
    <>
      <div
        className={`pain_items ${
          getMicroArticleToolClickedState ? 'button_active' : ''
        }`}
        onClick={() => {
          setMicroArticleToolClickedState(!getMicroArticleToolClickedState)
        }}
        style={{
          marginLeft: '7px',
        }}>
        <img
          src={MicroArticleToolIcon}
          alt="Micro Article Tool"
          className="icon_img"
        />
      </div>

      {isModalVisible && (
        <Portal containerSelector=".main">
          <PopupForm
            isEdit={isEdit}
            map={map}
            isCreating={isCreating}
            isVisible={isModalVisible}
            onClose={onPopupClose}
            onCreateSuccess={handleCreateSuccess}
            handleTitleChange={handleTitleChange}
            onSave={handleModalSave}
            coordinates={modalCoordinates}
            onPopupClose={onPopupClose}
            handleDeleteMicroArticle={deleteCurrentFeature}
            onCoordinatesChange={handleCoordinatesChange}
            featureMicroArticleID={currentFeatureMicroArticleID}
            initialColor={
              microArticleFeaturesRef.current.features.find(
                (f) => f.properties.id === currentFeatureIdRef.current,
              )?.properties.backgroundColor || '#1976d2'
            }
            onColorChange={handleColorChange}
          />
        </Portal>
      )}

      {isViewPopupVisible && currentFeatureId && (
        <ViewPopup
          map={map}
          changeIsModalVisible={changeIsModalVisible}
          changeEditMode={changeEditMode}
          changeIsViewPopupVisible={changeIsViewPopupVisible}
          featureId={currentFeatureId}
          featureMicroArticleID={currentFeatureMicroArticleID}
          isVisible={isViewPopupVisible}
          onClose={onViewPopupClose}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  getMicroArticleToolClickedState: getMicroArticleToolClickedState(state),
  getMicroArticleBarClickedState: getMicroArticleBarClickedState(state),
  getAllMicroArticles: getAllMicroArticles(state),
  getMapStyledId: getMapStyledId(state),
 })

const mapDispatchToProps = {
  setMicroArticleToolClickedState,
  setAllMicroArticles
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(MicroArticleTool))
