import {ConfigProvider} from "antd";

const theme = {
    token: {
        // colorPrimary: '#036147',
        // colorPrimaryHover: '#036147',
        fontFamily: "'Noto Sans Armenian', sans-serif",
        fontSize: 12,
        colorText: "#2C476C",
        borderRadius: 10,
        colorPrimary: '#036147',
        colorPrimaryHover: '#036147',
        colorPrimaryActive: '#036147',
        colorBorderHover: "#036147",
        colorBorderPrimaryActive: "#FFFFFF",
        colorBgContainer: "#FFFFFF",
        colorShadowActive: "#FFFFFF",
        colorShadowHover: "#036147",
        // colorBorder: "#036147",
    },
    components: {
        Input: {
            // colorText: "#333",
            // borderRadius: 15,
            // colorBgContainer: "#f5f5f5",
            // colorBorderFocus: "#52c41a",
            // colorPrimaryHover: '#036147',
            // colorBorderHover: '#036147',
            // colorBorderFocus: "#036147",
            controlHeight: 30,
        },
        TextArea: {
        },
        Checkbox: {
            // colorBgContainer: "#f5f5f5",
            // colorPrimary: "#036147",
            // colorChecked: "#036147",
            // colorBgChecked: "#e8f5e9",
            // colorBorder: "#00b96b",
        },
    },
}
const ConfigProviderAntd = ({ children, ...props }) => {
    return <ConfigProvider theme={theme} {...props}>{children}</ConfigProvider>
}

export default ConfigProviderAntd
