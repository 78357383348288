// MicroArticleBoardCard.jsx
import React, { useEffect, useState } from 'react'
import {Card, Typography, Row, Col, Divider, Tooltip, message} from 'antd'
import PlaceholderImage from '../../../assets/imgs/image_placeholder_2.png'
import styles from './MicroArticleBoardCard.module.css'
import EditModal from './EditModal'
import DeleteModal from './DeleteModal'
import DotsIcon from '../../../assets/imgs/DotsIcons.svg'
import {MicroArticleService} from "../../microArticle/service";

const { Title, Text } = Typography

const MicroArticleBoardCard = ({ microArticleItemData, handleGetData }) => {
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)

  useEffect(() => {
    console.log('microArticleItemData', microArticleItemData)
  }, [microArticleItemData])

  // Utility function to format Unix timestamp to readable date
  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A'
    const date = new Date(timestamp * 1000)
    return date.toLocaleDateString()
  }

  // Determine the icon URL
  const getIconUrl = () => {
    // If iconKey or webIconKey is provided, construct the URL accordingly
    if (microArticleItemData.iconKey) {
      return `https://your-icon-storage.com/icons/${microArticleItemData.iconKey}.png`
    }
    if (microArticleItemData.webIconKey) {
      return `https://your-web-icon-storage.com/icons/${microArticleItemData.webIconKey}.png`
    }
    // Fallback to a default icon if none provided
    return 'https://dummyimage.com/20x20/ffffff/000000&text=A'
  }

  // Handle Edit Modal submission
  const handleEdit = (values) => {
    console.log('Edited values:', values)
    // Here you would typically make an API call to update the article
    setEditModalVisible(false)
  }

  // Handle Delete Modal confirmation
  const handleDelete = () => {
    MicroArticleService.deleteMicroArticleByID(microArticleItemData.id).then(res => {
        handleGetData()
        message.success('Deleted successfully!')
        setDeleteModalVisible(false)
    }).catch(err => {
       message.error('Failed to delete!')
       setDeleteModalVisible(false)
    });
  }

    const handleHeaderClick = () => {
        // Extract necessary data from microArticleItemData
        const type = microArticleItemData.lessonIds ? 'lesson' : 'microarticle';
        let typeID = type === 'lesson' ? microArticleItemData.lessonIds[0] : microArticleItemData.topicIds[0];
        const microarticleId = microArticleItemData.id;

        console.log('microarticleId', microArticleItemData)

        // Construct the URL
        const baseUrl = window.location.origin; // e.g., https://yourdomain.com
        const url = `${baseUrl}/map?type=${type}&${type}ID=${encodeURIComponent(typeID)}&microarticleID=${microarticleId}`;

        // Open the URL in a new tab
        window.open(url, '_blank');
    };

  return (
    <Card
      className={styles.card}
      styles={{
          body: {
          padding: '15px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          // flexGrow: 'calc(100% - 20px)',
              flex: 1,
      }}}>
      {/* Header Section */}
        <Row
            justify="space-between"
            align="middle"
            className={styles.header}
            style={{ flexWrap: 'nowrap', cursor: 'pointer' }} // Add cursor pointer for better UX
            onClick={handleHeaderClick} // Attach the click handler
        >
            <Col style={{width: "100%", flexWrap: 'none', display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", flexWrap: 'none', gap: 8, }} >
                    <div
                        className={styles.iconWrapper}
                        style={{
                            backgroundColor: microArticleItemData.iconBackgroundColor || '#000',
                        }}
                    >
                        <img src={getIconUrl()} alt="Icon" className={styles.iconImage} />
                    </div>
                    <Title level={5} className={styles.title}>
                        {
                            microArticleItemData.title.length > 15 ?
                                microArticleItemData.title.substring(0, 15) + '...'
                                : microArticleItemData.title
                        }
                    </Title>
                </div>
                <Tooltip
                    trigger={['click']}
                    // mouseLeaveDelay={0.1}
                    destroyTooltipOnHide={true}
                    placement="bottomRight"
                    arrow={false}
                    overlayInnerStyle={{
                        backgroundColor: '#1E2B49',
                        color: '#fff',
                    }}
                    title={
                        <div className={styles.tooltip}>
                            <div
                                className={styles.tooltipItem}
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent triggering header click
                                    setEditModalVisible(true);
                                }}
                            >
                                Edit
                            </div>
                            <Divider className={styles.tooltipDivider} />
                            <div
                                className={styles.tooltipItem}
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent triggering header click
                                    setDeleteModalVisible(true);
                                }}
                            >
                                Delete
                            </div>
                        </div>
                    }
                >
                    <img onClick={e => e.stopPropagation()} src={DotsIcon} alt={'Action Button'} className={styles.ellipsisIcon} />
                </Tooltip>
            </Col>
        </Row>

      {/* Divider */}
      <Divider className={styles.divider} />

      {/* Content Section */}
      <div className={styles.content}>
        {/* Image Section */}
        <div className={styles.imageWrapper}>
          <img
            src={microArticleItemData.imageUrl || PlaceholderImage}
            alt="Thumbnail"
            className={styles.image}
          />
        </div>

        {/* Text Section */}
        <div className={styles.textSection}>
          <p className={styles.text}>
            {microArticleItemData.description}
          </p>
        </div>
      </div>

      {/* Footer Section */}
      <Row justify="space-between" className={styles.footer}>
        <Col>
          <Text className={styles.footerText}>
            <span className={styles.dateText}>Ստեղծվել է`</span>
            <span className={styles.dateNums}>
              {formatDate(microArticleItemData.createdAt)}
            </span>
          </Text>
        </Col>
        <Col>
          <Text className={styles.footerText}>
            <span className={styles.dateText}>Փոփոխվել է`</span>
            <span className={styles.dateNums}>
              {formatDate(microArticleItemData.modifiedAt)}
            </span>
          </Text>
        </Col>
      </Row>

      {/* Edit Modal */}
      <EditModal
        visible={editModalVisible}
        onOk={handleEdit}
        onCancel={() => setEditModalVisible(false)}
        initialValues={{
          title: microArticleItemData.title || '',
          content: microArticleItemData.description || '',
        }}
      />

      {/* Delete Modal */}
      <DeleteModal
        visible={deleteModalVisible}
        onOk={handleDelete}
        onCancel={() => setDeleteModalVisible(false)}
      />
    </Card>
  )
}

export default MicroArticleBoardCard
