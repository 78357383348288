import React from 'react';
import { Modal, Button, Typography, Space } from 'antd';
import styles from './DeleteConfirmationModal.module.css';

const DeleteConfirmationModal = ({ visible, onCancel, onAgree, isClose }) => {
    return (
        <Modal
            width={480}
            style={{ borderRadius: '50px' }}
            open={visible}
            title={
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <Typography.Text
                        style={{
                            color: '#2C476C',
                            fontSize: '24px',
                            fontWeight: 600,
                            letterSpacing: 3,
                        }}
                    >
                        {isClose ? 'Close'.toUpperCase() : 'Delete'.toUpperCase()}
                    </Typography.Text>
                </div>
            }
            onCancel={onCancel}
            rootClassName={styles.confirmationModal}
            footer={[
                <Space direction={'horizontal'} className={styles.buttonsWrapper} key="footer-buttons">
                    <Button
                        outline
                        key="cancel"
                        onClick={onCancel}
                        className={styles.cancelButton}
                    >
                        No
                    </Button>
                    <Button
                        key="confirm"
                        onClick={onAgree}
                        type="primary"
                        danger
                        className={styles.deleteButton}
                    >
                        Yes
                    </Button>
                </Space>,
            ]}
            centered
            closable={false}
        >
            <div className={styles.textContent}>
                {isClose ? (
                    <>
                        <Typography.Text
                            style={{ color: '#2C476C', fontSize: '16px', fontWeight: 500 }}
                        >
                            Are you sure you need to close the modal without saving your changes?
                        </Typography.Text>
                    </>
                ) : (
                    <>
                        <Typography.Text
                            style={{ color: '#2C476C', fontSize: '16px', fontWeight: 500 }}
                        >
                            Your changes will be lost permanently.
                        </Typography.Text>
                        <br />
                        <Typography.Text
                            style={{ color: '#2C476C', fontSize: '16px', fontWeight: 500 }}
                        >
                            Are you sure you want to delete this article?
                        </Typography.Text>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default DeleteConfirmationModal;
