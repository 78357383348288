import React from "react";
import MicroArticleBoardCard from "../MicroArticleBoardCard";
import styles from "./MicroArticleBoardGrid.module.css";
const MicroArticleBoardGrid = ({ microArticleData, handleGetData }) => {

    // style={{
    //     overflowY: 'auto',
    //     overflowX: 'hidden',
    //     padding: '20px',
    //     background: '#2c476c',
    //     width: '100%',
    //     height: '100%',
    //     display: 'grid',
    //     gridTemplateColumns: 'repeat(5, 1fr)',
    //     gridGap: '10px',
    //     boxSizing: 'border-box'
    // }}

    return (
        <div className={styles.gridContainer}>
            {microArticleData.map((microArticleItemData, index) => (
                <div key={index} style={{ width: '100%', height: '100%', display: 'flex' }}>
                    <MicroArticleBoardCard
                        handleGetData={handleGetData}
                        microArticleItemData={microArticleItemData}
                    />
                </div>
            ))}
        </div>
    )
};

export default MicroArticleBoardGrid;
