// src/components/YourComponentPath/PopupForm.js
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  List,
  message,
  Modal,
  Popover,
  Row,
  Space,
  Spin,
  Typography,
  Upload,
} from 'antd'
import {
  CameraOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  CodeOutlined,
  DeleteOutlined,
  EnvironmentOutlined,
  LinkOutlined,
  PictureOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import YoutubeIcon from '../../../../assets/imgs/youtube-icon.svg'
import styles from './PopupForm.module.css'
import IconButton from '../../UI/IconButton'
import {
  extractIframeSrc,
  formatDate,
  getBase64,
  MAX_IMAGE_SIZE,
  MAX_VIDEO_SIZE,
  uploadImageOrVideo,
  validateCoordinates,
} from '../../utils' // Import uploadImageOrVideo
import DualDateInput from '../DualDateInput'
import { v4 as uuidv4 } from 'uuid'
import CustomColorPicker from '../CustomColorPicker'
import { getMicroArticleData } from '../../../../store/selectors'
import { setMicroArticleData } from '../../../../store/actions/microArticle'
import { connect } from 'react-redux'
import DeleteConfirmationModal from '../DeleteConfirmationModal'
import { MicroArticleService } from '../../service'
import i18n from '../../../../locales/i18n'
import { useSearchParams } from 'react-router-dom'
import './style.css'

const PopupForm = ({
                       isVisible,
                       onClose,
                       map,
                       coordinates,
                       handleDeleteMicroArticle,
                       onCoordinatesChange,
                       handleTitleChange,
                       initialColor = '#036147',
                       onColorChange,
                       isEdit = false,
                       isCreating,
                       onPopupClose,
                       getMicroArticleData,
                       setMicroArticleData,
                       lessonStartDate,
                       lessonEndDate,
                       onCreateSuccess,
                       featureMicroArticleID,
                   }) => {
    const [form] = Form.useForm();
    const [activeButton, setActiveButton] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [youtubeLink, setYoutubeLink] = useState('');
    const [iframeLink, setIframeLink] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentMediaUrl, setCurrentMediaUrl] = useState('');
    const [currentMediaType, setCurrentMediaType] = useState('');
    const [currentItemUid, setCurrentItemUid] = useState(null);
    const [currentDescription, setCurrentDescription] = useState('');
    const [date1, setDate1] = useState('');
    const [era1, setEra1] = useState('AD');
    const [error1, setError1] = useState('');
    const [withoutStart, setWithoutStart] = useState(false);
    const [date2, setDate2] = useState('');
    const [era2, setEra2] = useState('AD');
    const [error2, setError2] = useState('');
    const [withoutEnd, setWithoutEnd] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedColor, setSelectedColor] = useState(initialColor);
    const [recentColors, setRecentColors] = useState([]);
    const [alwaysShowOnMap, setAlwaysShowOnMap] = useState(true);
    const dragItem = useRef();
    const dragOverItem = useRef();
    const previewContainerRef = useRef(null);
    const cameraInputRef = useRef(null);
    const environmentButtonRef = useRef(null);
    const popupRef = useRef(null);

    const [searchParams] = useSearchParams();

    // Function to extract YouTube Video ID
    const extractYoutubeId = useCallback((url) => {
        const regex =
            /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regex);
        return match ? match[1] : null;
    }, []);

    const generateVideoThumbnail = useCallback((file) => {
        return new Promise((resolve, reject) => {
            const url = URL.createObjectURL(file);
            const video = document.createElement('video');
            video.src = url;
            video.currentTime = 1; // Set to 1 second to avoid black frames
            video.onloadeddata = () => {
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                canvas.toBlob((blob) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                }, 'image/jpeg');
            };
            video.onerror = (e) => {
                console.error('Error generating video thumbnail:', e);
                resolve(''); // Fallback to empty string on error
            };
        });
    }, []);

    // Function to generate video thumbnail from a video URL
    const generateVideoThumbnailFromURL = useCallback((url) => {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.src = url;
            video.crossOrigin = 'Anonymous';
            video.currentTime = 1; // 1 second
            video.onloadeddata = () => {
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                canvas.toBlob((blob) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                }, 'image/jpeg');
            };
            video.onerror = (e) => {
                console.error('Error generating video thumbnail:', e);
                resolve(''); // Fallback to empty string on error
            };
        });
    }, []);

    // Initialize form with existing data or reset for new entry
    useEffect(() => {
        if (isEdit && getMicroArticleData) {
            const data = getMicroArticleData;

            const formValues = {
                title: data.title || '',
                description: data.description || '',
                coordinates: `${coordinates.lat.toFixed(4)}, ${coordinates.lng.toFixed(4)}`,
            };

            form.setFieldsValue(formValues);

            setDate1(formatDate(data.startYear, data.startMonth, data.startDay));
            setEra1(data.startTimeIsBC ? 'BC' : 'AD');
            setWithoutStart(data.withoutStartDate);
            setDate2(formatDate(data.endYear, data.endMonth, data.endDay));
            setEra2(data.endTimeIsBC ? 'BC' : 'AD');
            setWithoutEnd(data.withoutEndDate);
            setAlwaysShowOnMap(data.alwaysShowOnMap || false);
            setSelectedColor(initialColor);

            if (data.media && Array.isArray(data.media)) {
                const processMedia = async () => {
                    const processedMediaPromises = data.media.map(
                        async (media, index) => {
                            let preview = '';
                            let isVideo = false;
                            let videoUrl = undefined;
                            let iframeUrl = undefined;

                            if (media.type === 'Image') {
                                preview = media.path;
                            } else if (media.type === 'Video') {
                                isVideo = true;
                                // Assume 'path' contains the video URL
                                try {
                                    preview = await generateVideoThumbnailFromURL(media.path);
                                    if (!preview) {
                                        preview = 'https://www.biblecenterchurch.com/wp-content/uploads/2018/10/video-placeholder.png'; // Replace with your default thumbnail path
                                    }
                                } catch (error) {
                                    console.error(
                                        'Failed to generate thumbnail for video:',
                                        error,
                                    );
                                    preview = 'https://www.biblecenterchurch.com/wp-content/uploads/2018/10/video-placeholder.png'; // Replace with your default thumbnail path
                                }
                            } else if (media.type === 'Url') {
                                isVideo = true;
                                videoUrl = media.path;
                                const videoId = extractYoutubeId(media.path);
                                preview = videoId
                                    ? `https://img.youtube.com/vi/${videoId}/0.jpg`
                                    : '';
                                if (!preview) {
                                    preview = 'https://www.biblecenterchurch.com/wp-content/uploads/2018/10/video-placeholder.png'; // Replace with your default thumbnail path
                                }
                            } else if (media.type === 'Iframe') {
                                iframeUrl = media.path;
                                preview = null; // Will use icon
                            }

                            return {
                                uid: uuidv4(),
                                mediaId: media.mediaId, // **Include the original mediaId**
                                name: media.caption || `Media ${index + 1}`,
                                status: 'done',
                                url: media.path,
                                path: media.path,
                                originFileObj: null,
                                preview,
                                isVideo,
                                isIframe: media.type === 'Iframe',
                                videoUrl: videoUrl,
                                iframeUrl: iframeUrl,
                                description: media.caption,
                            };
                        },
                    );

                    const processedMedia = await Promise.all(processedMediaPromises);
                    setFileList(processedMedia);
                };

                processMedia();
            }
        } else if (isCreating) {
            // Reset form for creating a new micro-article
            form.resetFields();
            setFileList([]);
            setSelectedColor(initialColor);
            setRecentColors([]);
            setDate1('');
            setEra1('AD');
            setDate2('');
            setEra2('AD');
            setWithoutStart(false);
            setWithoutEnd(false);
            setAlwaysShowOnMap(true);
        }
    }, [
        isEdit,
        isCreating,
        getMicroArticleData,
        initialColor,
        form,
        generateVideoThumbnailFromURL,
        extractYoutubeId,
    ]);

    // Update coordinates when changed externally
    useEffect(() => {
        if (coordinates) {
            form.setFieldsValue({
                coordinates: `${coordinates.lat.toFixed(4)}, ${coordinates.lng.toFixed(4)}`,
            });
        }
    }, [coordinates]);

    useEffect(() => {
        setSelectedColor(initialColor);
    }, [initialColor]);

    // Toggle active button (e.g., environment, link, iframe)
    const handleToggle = useCallback((key) => {
        setActiveButton((prevActiveButton) =>
            prevActiveButton === key ? null : key,
        );
    }, []);

    // Handle title blur event
    const handleTitleBlur = useCallback(() => {
        const value = form.getFieldValue('title');
        handleTitleChange(value);
    }, [form, handleTitleChange]);

    // Handle changes in the upload component
    const handleUploadChange = useCallback(
        async ({ file, fileList: newFileList }) => {
            if (newFileList.length > 8) {
                message.warning('You can only upload up to 8 items.');
                return;
            }

            // Separate new files (with originFileObj) from existing files
            const existingFiles = fileList.filter(fileItem => !fileItem.originFileObj);
            const uploadedFiles = newFileList.filter(fileItem => fileItem.originFileObj);

            // Process new uploaded files
            const processedUploadedFiles = await Promise.all(
                uploadedFiles.map(async (fileItem) => {
                    if (
                        fileItem.originFileObj &&
                        !fileItem.preview &&
                        !fileItem.isIframe
                    ) {
                        try {
                            if (fileItem?.type && fileItem?.type?.startsWith && fileItem.type.startsWith('image/')) {
                                fileItem.preview = await getBase64(fileItem.originFileObj);
                            } else if (fileItem?.type && fileItem?.type?.startsWith && fileItem.type.startsWith('video/')) {
                                fileItem.preview = await generateVideoThumbnail(
                                    fileItem.originFileObj,
                                );
                            }
                        } catch (error) {
                            console.error('Error generating preview:', error);
                        }
                    }
                    return {
                        ...fileItem,
                        status: 'ready',
                        path: '',
                        isVideo: fileItem?.type && fileItem?.type?.startsWith && fileItem?.type?.startsWith('video/'),
                        isIframe: false, // Adjust if necessary
                        description: fileItem.description || '',
                        originFileObj: fileItem.originFileObj || null, // Ensure originFileObj is consistent
                    };
                }),
            );

            // Merge existing files with newly uploaded files
            setFileList([...existingFiles, ...processedUploadedFiles]);
        },
        [fileList, generateVideoThumbnail],
    );

    // Handle removing a file from the list
    const handleRemove = useCallback((file) => {
        setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
    }, []);

    // Upload component properties
    const uploadProps = useMemo(
        () => ({
            multiple: true,
            fileList,
            onChange: handleUploadChange,
            onRemove: handleRemove,
            beforeUpload: (file) => {
                const isImage = file.type.startsWith('image/');
                const isVideo = file.type.startsWith('video/');

                if (!isImage && !isVideo) {
                    message.error('You can only upload image or video files!');
                    return Upload.LIST_IGNORE;
                }

                if (isImage && file.size > MAX_IMAGE_SIZE) {
                    message.error(`Image must be smaller than ${MAX_IMAGE_SIZE / 1024 / 1024} MB!`);
                    return Upload.LIST_IGNORE;
                }

                if (isVideo && file.size > MAX_VIDEO_SIZE) {
                    message.error(`Video must be smaller than ${MAX_VIDEO_SIZE / 1024 / 1024} MB!`);
                    return Upload.LIST_IGNORE;
                }
                return isImage || isVideo || Upload.LIST_IGNORE;
            },
            showUploadList: false,
        }),
        [fileList, handleUploadChange, handleRemove],
    );

    // Drag and drop handlers for reordering media items
    const handleDragStart = useCallback((e, index) => {
        dragItem.current = index;
        e.dataTransfer.effectAllowed = 'move';
    }, []);

    const handleDragEnter = useCallback(
        (e, index) => {
            dragOverItem.current = index;
            if (dragItem.current === dragOverItem.current) return;

            const newFileList = [...fileList];
            const draggedItem = newFileList[dragItem.current];
            newFileList.splice(dragItem.current, 1);
            newFileList.splice(dragOverItem.current, 0, draggedItem);

            dragItem.current = dragOverItem.current;
            dragOverItem.current = null;
            setFileList(newFileList);
        },
        [fileList],
    );

    const handleDragOver = useCallback((e) => {
        e.preventDefault();
    }, []);

    const handleDragEnd = useCallback(() => {
        dragItem.current = null;
        dragOverItem.current = null;
    }, []);

    // Handle scroll wheel for the preview container
    const handleWheel = useCallback((e) => {
        if (previewContainerRef.current) {
            previewContainerRef.current.scrollLeft += e.deltaY;
        }
    }, []);

    // Handle capturing image from the camera
    const handleCameraCapture = useCallback(
        async (e) => {
            const file = e.target.files[0];
            if (file) {
                if (fileList.length >= 8) {
                    message.warning('You can only upload up to 8 images.');
                    return;
                }

                try {
                    const preview = await getBase64(file);

                    const newFile = {
                        uid: uuidv4(),
                        name: file.name,
                        status: 'ready',
                        path: '',
                        originFileObj: file,
                        description: '',
                        isVideo: false,
                        isIframe: false,
                        preview, // Set the generated preview
                    };
                    setFileList((prev) => [...prev, newFile]);
                } catch (error) {
                    console.error('Camera capture failed:', error);
                    message.error('Failed to capture image.');
                }
            }
            e.target.value = null;
        },
        [fileList.length],
    );

    // Validate iframe URLs
    const isValidIframeUrl = useCallback((url) => {
        try {
            const parsedUrl = new URL(url);
            return ['http:', 'https:'].includes(parsedUrl.protocol);
        } catch (e) {
            return false;
        }
    }, []);

    // Handle adding YouTube links
    const handleAddYoutubeLink = useCallback(
        (inputLink) => {
            const link = inputLink.trim();
            if (!link) {
                message.error('Please enter a YouTube link.');
                return;
            }

            const videoId = extractYoutubeId(link);
            if (!videoId) {
                message.error('Invalid YouTube link.');
                return;
            }

            const embedUrl = `https://www.youtube.com/embed/${videoId}`;
            const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;

            if (fileList.some((item) => item.mediaId === videoId)) {
                message.warning('This YouTube link has already been added.');
                return;
            }

            const newItem = {
                uid: uuidv4(), // Use unique identifier
                mediaId: uuidv4(), // Assign a unique mediaId for new media
                name: `YouTube Video: ${videoId}`,
                status: 'ready',
                url: thumbnailUrl,
                path: '',
                originFileObj: null, // No originFileObj for URL-based media
                preview: thumbnailUrl, // Set the thumbnail as preview
                isVideo: true,
                videoUrl: embedUrl,
                videoId,
                description: '', // Set description to the YouTube link
            };

            setFileList((prev) => [...prev, newItem]);
            setYoutubeLink('');
            setActiveButton(null);
            message.success('YouTube link added successfully.');
        },
        [extractYoutubeId, fileList],
    );

    // Handle adding iframe links
    const handleAddIframeLink = useCallback(
        (inputLink) => {
            let link = inputLink.trim();
            if (!link) {
                message.error('Please enter an iFrame link or embed code.');
                return;
            }

            link = extractIframeSrc(link);

            if (!isValidIframeUrl(link)) {
                message.error('Invalid iFrame URL.');
                return;
            }

            if (fileList.some((item) => item.mediaId === link)) {
                message.warning('This iFrame link has already been added.');
                return;
            }

            const newItem = {
                uid: uuidv4(), // Use unique identifier
                mediaId: uuidv4(), // Assign a unique mediaId for new media
                name: `iFrame: ${link}`,
                status: 'ready',
                url: '',
                path: '',
                originFileObj: null, // No originFileObj for URL-based media
                preview: null, // No preview image for iFrame
                isIframe: true,
                iframeUrl: link,
                description: '', // Set description to the iFrame link
            };

            setFileList((prev) => [...prev, newItem]);
            setIframeLink('');
            setActiveButton(null);
            message.success('iFrame link added successfully.');
        },
        [extractIframeSrc, isValidIframeUrl, fileList],
    );

    // Open modal to preview media and edit description
    const openModal = useCallback((file) => {
        if (file.isVideo) {
            if (file.videoUrl) {
                // It's a YouTube video
                setCurrentMediaType('video');
                setCurrentMediaUrl(file.videoUrl);
            } else {
                // It's an uploaded video file
                setCurrentMediaType('uploadedVideo');
                if (file.path) {
                    // If the file has been uploaded, use the path
                    setCurrentMediaUrl(file.path);
                } else if (file.originFileObj) {
                    // Otherwise, create an object URL
                    const videoUrl = URL.createObjectURL(file.originFileObj);
                    setCurrentMediaUrl(videoUrl);
                }
            }
        } else if (file.isIframe) {
            setCurrentMediaType('iframe');
            setCurrentMediaUrl(file.iframeUrl);
        } else {
            setCurrentMediaType('image');
            setCurrentMediaUrl(file.preview);
        }
        setCurrentItemUid(file.uid);
        setCurrentDescription(file.description || '');
        setIsModalVisible(true);
    }, []);

    // Close the media preview modal
    const closeModal = useCallback(() => {
        setIsModalVisible(false);
        setCurrentMediaUrl('');
        setCurrentMediaType('');
        setCurrentItemUid(null);
    }, []);

    // Handle description change in modal
    const handleDescriptionChange = useCallback((e) => {
        setCurrentDescription(e.target.value);
    }, []);

    // Save description from modal
    const handleSaveDescription = useCallback(() => {
        setFileList((prevList) =>
            prevList.map((item) =>
                item.uid === currentItemUid
                    ? { ...item, description: currentDescription }
                    : item,
            ),
        );
        message.success('Description saved successfully.');
        setIsModalVisible(false);
    }, [currentDescription, currentItemUid]);

    // Delete a media item
    const handleDeleteItem = useCallback(() => {
        setFileList((prevList) =>
            prevList.filter((item) => item.uid !== currentItemUid),
        );
        message.success('Item deleted successfully.');
        setIsModalVisible(false);
    }, [currentItemUid]);

    // Categories for environment popover
    const environmentCategories = useMemo(
        () => [
            'Climate Change',
            'Renewable Energy',
            'Pollution',
            'Conservation',
            'Sustainability',
            // Add more categories as needed
        ],
        [],
    );

    // Icons for environment popover
    const icons = useMemo(
        () => [
            <PlusCircleOutlined />,
            <PlusCircleOutlined />,
            <PlusCircleOutlined />,
            <PlusCircleOutlined />,
            <PlusCircleOutlined />,
            // Add more icons as needed
        ],
        [],
    );

    const colors = useMemo(() => ['#036147', '#157EFA', '#53D669', '#FED031'], []);

    // Handle color changes
    const handleColorChangeLocal = useCallback(
        (color) => {
            setSelectedColor(color);
            onColorChange(color);
            setRecentColors((prev) => {
                const filtered = prev.filter((c) => c !== color);
                const newRecent = [color, ...filtered];
                return newRecent.slice(0, 3);
            });
        },
        [onColorChange],
    );

    // Content for environment popover
    const content = useMemo(
        () => (
            <div className={styles.environmentPopup}>
                <Row style={{ maxHeight: 'inherit', width: '100%' }}>
                    {/* Left Column: Categories */}
                    <Col span={8} className={`${styles.popupLeft} custom-scroll-v3`}>
                        <Typography.Title style={{ margin: '10px' }} level={5}>
                            Categories
                        </Typography.Title>
                        <List
                            dataSource={environmentCategories}
                            renderItem={(category) => (
                                <List.Item
                                    className={styles.categoryItem}
                                    onClick={() => {
                                        /* Handle category click */
                                    }}>
                                    {category}
                                </List.Item>
                            )}
                        />
                    </Col>
                    {/* Right Column: Search, Icons, Colors, Color Picker */}
                    <Col span={16} className={styles.popupRight}>
                        {/* Search Section */}
                        <div className={styles.topSection}>
                            <Input
                                suffix={<SearchOutlined />}
                                placeholder="Search"
                                className={styles.searchInput}
                            />
                        </div>
                        {/* Icons Grid Section */}
                        <div className={`${styles.middleSection} custom-scroll-v3`}>
                            <Row gutter={[15, 15]} className={styles.iconsGrid}>
                                {icons.map((icon, index) => (
                                    <Col span={4} key={index} className={styles.iconItem}>
                                        <Button shape="circle" icon={icon} />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                        {/* Colors and Color Picker Section */}
                        <div className={styles.bottomSection}>
                            <Space className={styles.bottomSectionSpace}>
                                {/* Existing color buttons */}
                                {colors.map((color, index) => (
                                    <Button
                                        key={`color-${index}`}
                                        size="small"
                                        shape="circle"
                                        className={styles.colorButton}
                                        style={{ backgroundColor: color }}
                                        onClick={() => {
                                            handleColorChangeLocal(color);
                                        }}
                                    />
                                ))}

                                {/* Three dynamic buttons: Recent colors or "+" */}
                                {[0, 1, 2].map((i) =>
                                    recentColors[i] ? (
                                        <Button
                                            key={`recent-color-${i}`}
                                            size="small"
                                            shape="circle"
                                            className={styles.colorButton}
                                            style={{ backgroundColor: recentColors[i] }}
                                            onClick={() => {
                                                handleColorChangeLocal(recentColors[i]);
                                            }}
                                        />
                                    ) : (
                                        <Button
                                            key={`add-color-${i}`}
                                            size="small"
                                            shape="circle"
                                            className={`${styles.colorButton} ${styles.recentColorButton}`}
                                            icon={<PlusCircleOutlined />}
                                            onClick={() => {
                                                message.info('Select a color from the color picker.');
                                            }}
                                        />
                                    ),
                                )}

                                {/* Always Visible Color Picker */}
                                <CustomColorPicker
                                    initialColor={selectedColor}
                                    onColorChange={handleColorChangeLocal}
                                />
                            </Space>
                        </div>
                    </Col>
                </Row>
            </div>
        ),
        [
            colors,
            environmentCategories,
            icons,
            handleColorChangeLocal,
            recentColors,
            selectedColor,
        ],
    );

    // Handle form submission
    const handleSubmit = useCallback(async () => {
        try {
            if (isSubmitting) return;
            // Validate form fields
            const values = await form.validateFields();

            // Parse and validate coordinates
            const coords = values.coordinates.split(',').map((c) => c.trim());
            if (coords.length !== 2) {
                message.error('Coordinates must include both latitude and longitude.');
                return;
            }
            const latitude = parseFloat(coords[0]);
            const longitude = parseFloat(coords[1]);
            if (
                isNaN(latitude) ||
                isNaN(longitude) ||
                latitude < -90 ||
                latitude > 90 ||
                longitude < -180 ||
                longitude > 180
            ) {
                message.error('Please enter valid latitude and longitude.');
                return;
            }

            // Parse dates
            const parseDateString = (dateString) => {
                if (!dateString) return { year: null, month: null, day: null };
                const parts = dateString.split('/');
                return {
                    year: parts[0] ? parseInt(parts[0], 10) : null,
                    month: parts[1] ? parseInt(parts[1], 10) : null,
                    day: parts[2] ? parseInt(parts[2], 10) : null,
                };
            };

            let startDate = parseDateString(date1);
            let endDate = parseDateString(date2);
            let era1Copy = era1;
            let era2Copy = era2;

            // **Defaulting Start Date**
            if (startDate.year) {
                if (!startDate.month) {
                    startDate.month = 1; // Default to January
                }
                if (!startDate.day) {
                    startDate.day = 1; // Default to the first day of the month
                }
            }

            // Initialize time fields
            let startHour = null;
            let startMinute = null;
            let startSecond = null;
            let endHour = null;
            let endMinute = null;
            let endSecond = null;

// **Check if Start and End Dates are Identical**
            const isSameDate =
                startDate.year &&
                endDate.year &&
                startDate.year === endDate.year &&
                startDate.month === endDate.month &&
                startDate.day === endDate.day;

            if (isSameDate) {
                startHour = 1;
                startMinute = 1;
                startSecond = 1;

                endHour = 23;
                endMinute = 59;
                endSecond = 59;
            }

            // **Defaulting End Date**
            if (endDate.year) {
                if (!endDate.month) {
                    endDate.month = 12; // Default to December
                }
                if (!endDate.day) {
                    // Calculate the last day of the specified month
                    endDate.day = new Date(endDate.year, endDate.month, 0).getDate();
                }
            }

            if (alwaysShowOnMap) {
                startDate = { year: 4713, month: 1, day: 1 };
                era1Copy = 'BC';
                endDate = null; // Dynamic "today"
            } else {
                if (withoutStart) {
                    startDate = { year: 4713, month: 1, day: 1 };
                    era1Copy = 'BC';
                }
                if (withoutEnd) {
                    endDate = null; // Dynamic "today"
                }
            }

            // Prevent saving if only end date is set
            if (!startDate.year && endDate && endDate.year) {
                message.error('Start date is required when end date is set.');
                return;
            }

            // Specific Range Calculation
            const adjustDateRange = (date, era) => {
                if (!date.year) return { start: null, end: null };
                let year = era === 'BC' ? -(date.year - 1) : date.year;
                let start, end;

                if (date.year && date.month && date.day) {
                    // Full date provided
                    start = new Date(year, date.month - 1, date.day, 0, 0, 0);
                    end = new Date(year, date.month - 1, date.day, 23, 59, 59);
                } else {
                    // Incomplete date already defaulted earlier
                    start = new Date(year, date.month - 1, date.day, 0, 0, 0);
                    end = new Date(year, date.month - 1, date.day, 23, 59, 59);
                }

                return { start, end };
            };

            const adjustedStartRange = adjustDateRange(startDate, era1Copy);
            const adjustedEndRange = endDate
                ? adjustDateRange(endDate, era2Copy)
                : { start: new Date(), end: new Date() };

            const adjustedStart = adjustedStartRange.start;
            const adjustedEnd = adjustedEndRange.end;

            if (!adjustedStart) {
                message.error('Invalid start date.');
                return;
            }

            // Time Validation: Check if the set timeline conflicts with lesson/topic dates
            if (lessonStartDate && lessonEndDate && !alwaysShowOnMap) {
                const constructDate = (date, era) => {
                    if (!date.year) return null;
                    let month = date.month ? date.month - 1 : 0;
                    let day = date.day || 1;
                    let year = era === 'BC' ? -(date.year - 1) : date.year;
                    return new Date(year, month, day);
                };

                const lessonStart = constructDate(lessonStartDate, lessonStartDate.era);
                const lessonEnd = constructDate(lessonEndDate, lessonEndDate.era);

                const articleStartTime = new Date(startDate.year, startDate.month - 1, startDate.day, startHour || 0, startMinute || 0, startSecond || 0).getTime();
                const articleEndTime = endDate
                    ? new Date(endDate.year, endDate.month - 1, endDate.day, endHour || 0, endMinute || 0, endSecond || 0).getTime()
                    : Date.now();

                const isOverlap =
                    articleStartTime <= lessonEnd.getTime() &&
                    articleEndTime >= lessonStart.getTime();

                if (!isOverlap) {
                    message.error(
                        "The article's timeline should overlap with the lesson's time parameter.",
                    );
                    return;
                }
            }

            const topicID = searchParams.get('topicID');
            const lessonID = searchParams.get('lessonID');

            setIsSubmitting(true); // Set submitting state

            // Construct payload without media (media will be handled separately)
            const payload = {
                languageId: i18n.language === 'hy' ? 1 : 2, // Set accordingly
                title: values.title,
                description: values.description,
                iconKey: '',
                webIconKey: '',
                iconBackgroundColor: selectedColor,
                zoomLevel: 0, // map.getZoom().toFixed(2)
                longitude: longitude,
                latitude: latitude,
                media: isEdit ? [...getMicroArticleData.media] : [], // Initially empty; media will be added after upload
                topicIds: topicID ? [+topicID] : [], // Set accordingly
                lessonIds: lessonID ? [+lessonID] : [], // Set accordingly
                startYear: startDate.year,
                startMonth: startDate.month,
                startDay: startDate.day,
                startHour: startHour,
                startMinute: startMinute,
                startSecond: startSecond,
                startTimeIsBC: era1Copy === 'BC' || withoutStart || alwaysShowOnMap,
                endYear: endDate ? endDate.year : null,
                endMonth: endDate ? endDate.month : null,
                endDay: endDate ? endDate.day : null,
                endHour: endHour,
                endMinute: endMinute,
                endSecond: endSecond,
                endTimeIsBC: endDate ? era2Copy === 'BC' : false,
                backgroundColor: selectedColor,
            };


            // Submit the form data to create or update the micro-article
            try {
                let response;
                let microArticleId;

                if (isEdit) {
                    // Update existing micro-article
                    console.log('featureMicroArticleID', featureMicroArticleID);

                    response = await MicroArticleService.updateMicroArticleByID(
                        featureMicroArticleID,
                        payload
                    );
                    if (response.status === 200 || response.status === 204) {
                        message.success('Micro-article updated successfully.');
                        microArticleId = featureMicroArticleID;
                    } else {
                        message.error('Failed to update micro-article.');
                        setIsSubmitting(false);
                        return;
                    }
                } else {
                    // Create a new micro-article
                    response = await MicroArticleService.createMicroArticle(payload);
                    if (response.status === 200 || response.status === 201) {
                        message.success('Micro-article created successfully.');

                        // Extract microArticleId from the response
                        microArticleId = response.data.data[0]; // Adjust according to your API response structure
                    } else {
                        message.error('Failed to create micro-article.');
                        setIsSubmitting(false);
                        return;
                    }
                }

                // Now, handle media uploads and associations
                // Handle media uploads and associations
                if (fileList.length > 0) {
                    // **Separate existing media from new media**
                    const existingMedia = fileList.filter(
                        file =>
                            file.mediaId &&
                            !file.originFileObj &&
                            !file.isIframe &&
                            !(file.isVideo && file.videoUrl)
                    );
                    const newMedia = fileList.filter(file => file.originFileObj);

                    // **Upload new media**
                    const uploadMediaPromises = newMedia.map(async (file) => {
                        try {
                            const blob = file.originFileObj;
                            const alt = file.description;
                            const title = file.title || file.name;

                            const uploadResponse = await uploadImageOrVideo(blob, alt, title, 'PublicRead');
                            return {
                                mediaId: uploadResponse.id, // New mediaId from upload response
                                path: uploadResponse.mediaInfo.original, // Assuming this contains the URL
                                type: file.isVideo ? 'Video' : 'Image',
                                caption: alt,
                                order: fileList.indexOf(file),
                            };
                        } catch (error) {
                            console.error(`Failed to upload ${file.name}:`, error);
                            message.error(`Failed to upload ${file.name}.`);
                            return null;
                        }
                    });

                    const uploadedMediaResponses = await Promise.all(uploadMediaPromises);
                    const successfulUploads = uploadedMediaResponses.filter(response => response !== null);

                    // **Handle URL-Based Media (YouTube Links and iFrames)**
                    const urlBasedMedia = fileList
                        .filter(file => file.isIframe || (file.isVideo && file.videoUrl))
                        .map(file => ({
                            mediaId: file.mediaId, // **Retain original mediaId**
                            type: file.isIframe ? 'Iframe' : 'Url',
                            path: file.isIframe ? file.iframeUrl : file.videoUrl,
                            caption: file.description,
                            order: fileList.indexOf(file),
                        }));

                    // **Combine Existing Media, Uploaded Media, and URL-Based Media**
                    const finalMediaArray = [
                        // Existing Media
                        ...existingMedia.map(media => ({
                            mediaId: media.mediaId, // **Original mediaId**
                            type: media.isIframe ? 'Iframe' : (media.isVideo ? 'Video' : 'Image'),
                            path: media.path,
                            caption: media.description, // This now contains the URL
                            order: fileList.indexOf(media),
                        })),
                        // Uploaded Media
                        ...successfulUploads,
                        // URL-Based Media
                        ...urlBasedMedia,
                    ];

                    // **Sort or Assign 'isMain' if Needed**
                    const sortedFinalMediaArray = [...finalMediaArray].sort((a, b) => a.order - b.order);

                    // **Update the micro-article's media list**
                    try {
                        const mediaUpdateResponse = await MicroArticleService.updateMicroArticleMedia(
                            microArticleId,
                            sortedFinalMediaArray
                        );
                        if (mediaUpdateResponse.status === 200 || mediaUpdateResponse.status === 204) {
                            message.success('Media uploaded and associated successfully.');
                        } else {
                            message.error('Media uploaded but failed to associate with the micro-article.');
                        }
                    } catch (error) {
                        console.error('Failed to update micro-article media:', error);
                        message.error('Failed to associate media with the micro-article.');
                    }
                }

                // Reset form and state
                onClose();
                onCreateSuccess(microArticleId);
            } catch (error) {
                if (isEdit) {
                    message.error('An error occurred while updating the micro-article.');
                } else {
                    message.error('An error occurred while creating the micro-article.');
                }
                console.error(error);
            } finally {
                setIsSubmitting(false);
            }
        } catch (e) {
            console.log('e', e);
        }
    }, [
            form,
            fileList,
            selectedColor,
            date1,
            date2,
            era1,
            era2,
            withoutStart,
            withoutEnd,
            alwaysShowOnMap,
            lessonStartDate,
            lessonEndDate,
            onClose,
            isSubmitting,
            onCreateSuccess,
            searchParams,
            isEdit,
            featureMicroArticleID,
            getMicroArticleData,
        ]);

        const deleteMicroArticle = useCallback(async () => {
            try {
                await MicroArticleService.deleteMicroArticleByID(featureMicroArticleID);
            } catch (error) {
                message.error('Failed to delete micro-article.');
            }
        }, [featureMicroArticleID]);

        // Handle deletion confirmation
        const handleAttemptDelete = useCallback(() => {
            setIsDeleteModalVisible(true);
        }, []);

        const handleAttemptClose = useCallback(() => {
            setIsCancelModalVisible(true);
        }, []);

        const confirmDelete = useCallback(() => {
            setIsDeleteModalVisible(false);
            handleDeleteMicroArticle();
            // Reset form and state
            onClose();
            deleteMicroArticle().then(() => {
                message.success('Article deleted successfully.');
            }).catch(() => {
                message.error('Failed to delete micro-article.');
            });
        }, [handleDeleteMicroArticle]);

        const agreeCancelModal = useCallback(() => {
            setIsCancelModalVisible(false);
            setIsModalVisible(false);
            onPopupClose();
        }, [onPopupClose]);

        const cancelDelete = useCallback(() => {
            setIsDeleteModalVisible(false);
        }, []);

        const cancelCancelModal = useCallback(() => {
            setIsCancelModalVisible(false);
        }, []);

        // Handle coordinates change
        const handleCoordinatesChange = useCallback(() => {
            const value = form.getFieldValue('coordinates');
            if (!value) return;

            const coords = value.split(',').map((c) => parseFloat(c.trim()));
            if (
                coords.length === 2 &&
                !isNaN(coords[0]) &&
                !isNaN(coords[1]) &&
                coords[0] >= -90 &&
                coords[0] <= 90 &&
                coords[1] >= -180 &&
                coords[1] <= 180
            ) {
                const [lat, lng] = coords;
                onCoordinatesChange({ lat, lng });
            } else {
                message.error(
                    'Invalid coordinates format. Please enter as "lat, lng" with valid values.',
                );
                form.setFieldsValue({
                    coordinates: `${coordinates.lat.toFixed(4)}, ${coordinates.lng.toFixed(4)}`,
                });
            }
        }, [coordinates, form, onCoordinatesChange]);

        useEffect(() => {
            return () => {
                form.resetFields();
                setFileList([]);
                setSelectedColor('#036147');
                setDate1('');
                setEra1('AD');
                setDate2('');
                setEra2('AD');
                setWithoutStart(false);
                setWithoutEnd(false);
                setAlwaysShowOnMap(true);
            }
        }, [form])

        return (
            <div className={styles.container + ' fontStyles'}>
                <Card
                    title={
                        <div className={styles.cardHeader}>
                            <span>{isEdit ? 'Կետ' : 'Նոր կետ'}</span>
                            <div>
                                {isEdit && (
                                    <DeleteOutlined
                                        onClick={handleAttemptDelete}
                                        style={{ marginRight: 10 }}
                                        className={styles.deleteIcon}
                                    />
                                )}
                                <CloseOutlined
                                    disabled={isSubmitting}
                                    onClick={!isEdit ? handleAttemptDelete : handleAttemptClose}
                                    className={styles.closeIcon}
                                />
                            </div>
                        </div>
                    }
                    styles={{
                        body: { padding: 10 },
                        header: { padding: '0px 20px', minHeight: '45px', height: '45px' },
                    }}
                    bordered={false}
                    className={`${styles.card} custom-scroll-v3`}>
                    <Form form={form} layout="vertical">
                        {/* Title field with toggle button */}
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <Form.Item
                                label="Անվանում"
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter a title.',
                                    },
                                ]}>
                                <Input.TextArea
                                    maxLength={50}
                                    autoSize={{ minRows: 1, maxRows: 1 }}
                                    showCount
                                    placeholder="Մուտքագրեք անվանումը"
                                    onBlur={handleTitleBlur}
                                    className={styles.titleInput}
                                />
                            </Form.Item>
                            <div className={styles.popoverWrapper} ref={popupRef}>
                                <Popover
                                    content={content}
                                    overlayInnerStyle={{ padding: 0 }}
                                    trigger="click"
                                    open={activeButton === 'environment'}
                                    placement="bottomRight"
                                    onOpenChange={(visible) => {
                                        if (visible) {
                                            setActiveButton('environment');
                                        } else {
                                            setActiveButton(null);
                                        }
                                    }}>
                                    <IconButton
                                        key="environment"
                                        toggled={activeButton === 'environment'}
                                        icon={<EnvironmentOutlined />}
                                        style={{
                                            marginTop: 5,
                                            marginLeft: '10px',
                                            backgroundColor: selectedColor,
                                            border: 'none',
                                        }}
                                        ref={environmentButtonRef}
                                        disabled={false}
                                        title="Toggle Environment"
                                    />
                                </Popover>
                            </div>
                        </div>

                        {/* Description field */}
                        <Form.Item
                            label={
                                <p>
                                    Նկարագրություն
                                </p>
                            }
                            name="description"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please enter a description.',
                                },
                            ]}>
                            <Input.TextArea
                                maxLength={1000}
                                rows={4}
                                autoSize={{ minRows: 4, maxRows: 6 }}
                                showCount
                                placeholder="Մուտքագրեք նկարագրությունը"
                                className={styles.resizableTextarea}
                            />
                        </Form.Item>

                        {/* Coordinates field with search button */}
                        <Form.Item
                            label="Կոորդինատներ"
                            name="coordinates"
                            initialValue={`${coordinates.lat.toFixed(4)}, ${coordinates.lng.toFixed(4)}`}
                            required
                            style={{ marginBottom: 10 }}
                            rules={[
                                {
                                    required: true,
                                    validator: (_, value) => validateCoordinates(_, value),
                                    message:
                                        'Ներառեք կոորդինատները ճիշտ ձևաչափով, օրինակ: "40.0691, 45.0382"',
                                },
                            ]}>
                            <Input
                                placeholder="Մուտքագրեք կոորդինատները"
                                onBlur={handleCoordinatesChange}
                                suffix={
                                    <Button
                                        icon={<SearchOutlined />}
                                        onClick={handleCoordinatesChange}
                                        type="text"
                                        size="middle"
                                    />
                                }
                            />
                        </Form.Item>

                        {/* Action buttons */}
                        <Space size="middle" className={styles.spaceContainer}>
                            {/* Image and Video upload */}
                            <Upload {...uploadProps} className={styles.uploadButton}>
                                <IconButton
                                    key="picture"
                                    title="Նկարներ"
                                    icon={<PictureOutlined />}
                                    className={styles.smallButton}
                                />
                            </Upload>

                            {/* Camera button */}
                            <IconButton
                                key="camera"
                                title="Լուսանկար"
                                icon={<CameraOutlined />}
                                onClick={() => {
                                    if (cameraInputRef.current) {
                                        cameraInputRef.current.click();
                                    }
                                }}
                                className={styles.smallButton}
                            />

                            {/* Toggle buttons (Link and iFrame) */}
                            {[
                                {
                                    key: 'link',
                                    title: 'Հղում',
                                    icon: <LinkOutlined />,
                                },
                                {
                                    key: 'iframe',
                                    title: 'iFrame',
                                    icon: <CodeOutlined />,
                                },
                            ].map(({ key, title, icon }) => (
                                <IconButton
                                    key={key}
                                    toggled={activeButton === key}
                                    title={title}
                                    icon={icon}
                                    onClick={() => handleToggle(key)}
                                    className={styles.smallButton}
                                />
                            ))}

                            {/* Conditional input for YouTube */}
                            {activeButton === 'link' && (
                                <Input
                                    placeholder="YouTube Link or Embed Code"
                                    className={styles.youtubeLinkInput}
                                    value={youtubeLink}
                                    onChange={(e) => setYoutubeLink(e.target.value)}
                                    onPressEnter={() => handleAddYoutubeLink(youtubeLink)}
                                    onPaste={(e) => {
                                        const pastedText = e.clipboardData.getData('Text').trim();
                                        setYoutubeLink(pastedText);
                                        e.preventDefault();
                                        handleAddYoutubeLink(pastedText);
                                    }}
                                />
                            )}

                            {/* Conditional input for iFrame */}
                            {activeButton === 'iframe' && (
                                <Input
                                    placeholder="iFrame Link or Embed Code"
                                    className={styles.youtubeLinkInput}
                                    value={iframeLink}
                                    onChange={(e) => setIframeLink(e.target.value)}
                                    onPressEnter={() => handleAddIframeLink(iframeLink)}
                                    onPaste={(e) => {
                                        const pastedText = e.clipboardData.getData('Text').trim();
                                        setIframeLink(pastedText);
                                        e.preventDefault();
                                        handleAddIframeLink(pastedText);
                                    }}
                                />
                            )}
                        </Space>

                        {/* Hidden camera input */}
                        <input
                            type="file"
                            accept="image/*"
                            capture="environment"
                            style={{ display: 'none' }}
                            ref={cameraInputRef}
                            onChange={handleCameraCapture}
                        />

                        {/* Upload previews */}
                        {fileList.length > 0 && (
                            <Form.Item style={{ margin: '5px 0px', padding: 0 }}>
                                <div
                                    className={`${styles.previewContainer} custom-scroll-v3`}
                                    onWheel={handleWheel}
                                    ref={previewContainerRef}>
                                    {fileList.map((file, index) => (
                                        <div
                                            key={file.uid}
                                            className={styles.previewItem}
                                            draggable
                                            onDragStart={(e) => handleDragStart(e, index)}
                                            onDragEnter={(e) => handleDragEnter(e, index)}
                                            onDragOver={handleDragOver}
                                            onDragEnd={handleDragEnd}
                                            onClick={() => openModal(file)}>
                                            {/* Image Preview */}
                                            {!file.isVideo && !file.isIframe && file.preview && (
                                                <img
                                                    src={file.preview}
                                                    alt={file.name}
                                                    className={styles.previewImage}
                                                />
                                            )}

                                            {/* Uploaded Video File Preview */}
                                            {file.isVideo &&
                                                !file.isIframe &&
                                                !file.videoUrl &&
                                                file.preview && (
                                                    <img
                                                        src={file.preview}
                                                        alt={file.name}
                                                        className={styles.previewImage}
                                                    />
                                                )}

                                            {/* YouTube Video Preview (Thumbnail) */}
                                            {file.isVideo && file.videoUrl && file.preview && (
                                                <>
                                                    <img
                                                        src={file.preview}
                                                        alt={file.name}
                                                        className={styles.previewImage}
                                                    />
                                                    <img
                                                        src={YoutubeIcon}
                                                        className={styles.playIcon}
                                                        alt="YouTube Video"
                                                    />
                                                </>
                                            )}

                                            {/* iFrame Preview (Icon) */}
                                            {file.isIframe && (
                                                <div className={styles.iframeIconContainer}>
                                                    <CodeOutlined className={styles.codeIcon} />
                                                </div>
                                            )}

                                            {/* Uploading Overlay */}
                                            {file.status === 'uploading' && (
                                                <div className={styles.uploadingOverlay}>
                                                    <Spin />
                                                </div>
                                            )}

                                            {/* Error Indicator */}
                                            {file.status === 'error' && (
                                                <CloseOutlined
                                                    className={styles.removeIcon}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleRemove(file);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </Form.Item>
                        )}

                        {/* Action buttons continued */}
                        <Space direction="vertical" style={{ marginTop: '10px' }}>
                            <div className={styles.flexRow}>
                                <IconButton
                                    key="timePeriod"
                                    icon={
                                        activeButton === 'timePeriod' ? (
                                            <div style={{ display: 'flex', gap: 5, padding: 10 }}>
                                                <ClockCircleOutlined style={{ marginRight: 5 }} />
                                                Ժամանակահատված
                                            </div>
                                        ) : (
                                            <ClockCircleOutlined />
                                        )
                                    }
                                    style={{
                                        width: activeButton === 'timePeriod' ? 'fit-content' : 32,
                                    }}
                                    toggled={activeButton === 'timePeriod'}
                                    onClick={() => handleToggle('timePeriod')}
                                    className={styles.smallButton}
                                />
                                {activeButton !== 'timePeriod' && (
                                    <Typography.Text className={styles.marginLeft10}>
                                        Ժամանակահատված
                                    </Typography.Text>
                                )}
                            </div>
                            {activeButton === 'timePeriod' && (
                                <>
                                    {/* Always Show on the Map Checkbox */}
                                    <Form.Item style={{ marginBottom: 0 }}>
                                        <Checkbox
                                            checked={alwaysShowOnMap}
                                            onChange={(e) => {
                                                const checked = e.target.checked;
                                                setAlwaysShowOnMap(checked);
                                                if (checked) {
                                                    setWithoutStart(true);
                                                    setWithoutEnd(true);
                                                } else {
                                                    setWithoutStart(false);
                                                    setWithoutEnd(false);
                                                }
                                            }}>
                                            Always show on the map
                                        </Checkbox>
                                    </Form.Item>

                                    {/* Date Inputs with DualDateInput Component */}
                                    <DualDateInput
                                        date1={date1}
                                        setDate1={setDate1}
                                        era1={era1}
                                        setEra1={setEra1}
                                        error1={error1}
                                        setError1={setError1}
                                        withoutStart={withoutStart}
                                        setWithoutStart={setWithoutStart}
                                        date2={date2}
                                        setDate2={setDate2}
                                        era2={era2}
                                        setEra2={setEra2}
                                        error2={error2}
                                        setError2={setError2}
                                        withoutEnd={withoutEnd}
                                        setWithoutEnd={setWithoutEnd}
                                        alwaysShowOnMap={alwaysShowOnMap}
                                    />
                                </>
                            )}
                            <div className={styles.flexRow}>
                                <IconButton
                                    key="addToDisplay"
                                    title="Ավելացնել ցուցադրության մեջ"
                                    icon={<ClockCircleOutlined />}
                                    toggled={activeButton === 'addToDisplay'}
                                    onClick={() => handleToggle('addToDisplay')}
                                    className={styles.smallButton}
                                />
                                <Typography.Text className={styles.marginLeft10}>
                                    Ավելացնել ցուցադրության մեջ
                                </Typography.Text>
                            </div>
                        </Space>

                        {/* Save button */}
                        <div className={styles.buttonContainer}>
                            <Button
                                type="primary"
                                shape="round"
                                className={styles.saveButton}
                                onClick={handleSubmit}
                                loading={isSubmitting}
                                disabled={isSubmitting}>
                                Պահպանել
                            </Button>
                        </div>
                    </Form>

                    {/* Modal for media playback and description */}
                    <Modal
                        open={isModalVisible}
                        footer={null}
                        destroyOnClose={true}
                        onCancel={closeModal}
                        width={800}
                        title={
                            <div className={styles.modalHeader}>
                                <Typography.Title
                                    className={styles.modalHeaderText}
                                    level={4}
                                    strong>
                                    {currentMediaType === 'image' && 'ՆԿԱՐ'}
                                    {currentMediaType === 'video' && 'YOUTUBE ՀՂՈՒՄ'}
                                    {currentMediaType === 'iframe' && 'IFRAME ՀՂՈՒՄ'}
                                    {currentMediaType === 'uploadedVideo' && 'ՏԵՍԱՆՅՈՒԹ'}
                                </Typography.Title>
                            </div>
                        }>
                        <div className={styles.modalContent}>
                            {currentMediaType === 'video' && currentMediaUrl && (
                                <div className={styles.iframeWrapper}>
                                    <iframe
                                        key={currentMediaUrl}
                                        width="100%"
                                        height="100%"
                                        src={currentMediaUrl}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title="YouTube Video"
                                        className={styles.modalMedia}></iframe>
                                </div>
                            )}
                            {currentMediaType === 'iframe' && currentMediaUrl && (
                                <div className={styles.iframeWrapper}>
                                    <iframe
                                        key={currentMediaUrl}
                                        width="100%"
                                        height="100%"
                                        src={currentMediaUrl}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title="iFrame Content"
                                        className={styles.modalMedia}></iframe>
                                </div>
                            )}
                            {currentMediaType === 'image' && currentMediaUrl && (
                                <img
                                    src={currentMediaUrl}
                                    alt="Selected"
                                    className={styles.modalImage}
                                />
                            )}
                            {currentMediaType === 'uploadedVideo' && currentMediaUrl && (
                                <video
                                    controls
                                    className={styles.modalVideo}
                                    src={currentMediaUrl}
                                />
                            )}

                            {/* Description field */}
                            <Form.Item>
                                <Input.TextArea
                                    rows={4}
                                    maxLength={1000}
                                    showCount
                                    value={currentDescription}
                                    onChange={handleDescriptionChange}
                                    placeholder="Մուտքագրեք նկարագրությունը"
                                />
                            </Form.Item>

                            {/* Save and Delete buttons */}
                            <div className={styles.modalButtonContainer}>
                                <Button
                                    type="primary"
                                    onClick={handleSaveDescription}
                                    className={styles.modalSaveButton}>
                                    Պահպանել
                                </Button>
                                <Button
                                    type="danger"
                                    onClick={handleDeleteItem}
                                    className={styles.modalDeleteButton}>
                                    Ջնջել
                                </Button>
                            </div>
                        </div>
                    </Modal>

                    {/* Confirmation modal for deletion */}
                    <DeleteConfirmationModal
                        visible={isDeleteModalVisible}
                        onCancel={cancelDelete}
                        onAgree={confirmDelete}
                    />

                    {/* Confirmation modal for cancel editing */}
                    <DeleteConfirmationModal
                        visible={isCancelModalVisible}
                        onCancel={cancelCancelModal}
                        onAgree={agreeCancelModal}
                        isClose={true}
                    />
                </Card>
            </div>
        );
    };

    const mapStateToProps = (state) => ({
        getMicroArticleData: getMicroArticleData(state),
    });

    const mapDispatchToProps = {
        setMicroArticleData,
    };

    /**
     * Connect the component to Redux and export.
     */
    export default connect(
        mapStateToProps,
        mapDispatchToProps,
    )(memo(PopupForm));
