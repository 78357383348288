import {
    SET_MICRO_ARTICLE_DATA,
    SET_CURRENT_FEATURE_ID,
    SET_VIEW_POPUP_VISIBILITY,
    SET_FORM_POPUP_VISIBILITY, SET_ALL_MICRO_ARTICLES, IS_MICRO_ARTICLES_LOADING, SET_ALL_MICRO_ARTICLES_BOARD
} from "../constants";

const initialState = {
    isMicroArticlesLoading: false,
    allMicroArticles: null, // after it's gonna be []
    allMicroArticlesBoard: null,
    microArticleData: null,
    currentFeatureId: null,
    viewPopupIsVisible: false,
    isFormPopupVisible: false,
};

export const microArticleReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_MICRO_ARTICLES_BOARD:
            return {
                ...state,
                allMicroArticlesBoard: action.payload
            }
        case SET_MICRO_ARTICLE_DATA:
            return {
                ...state,
                microArticleData: action.payload,
            };
        case SET_CURRENT_FEATURE_ID:
            return {
                ...state,
                currentFeatureId: action.payload,
            };
        case SET_VIEW_POPUP_VISIBILITY:
            return {
                ...state,
                viewPopupIsVisible: action.payload,
            };
        case SET_FORM_POPUP_VISIBILITY:
            return {
                ...state,
                isFormPopupVisible: action.payload,
            };
        case SET_ALL_MICRO_ARTICLES:
            return {
                ...state,
                allMicroArticles: action.payload,
            };
        case IS_MICRO_ARTICLES_LOADING: {
            return {
                ...state,
                isMicroArticlesLoading: action.payload
            }
        }

        default:
            return state;
    }
};
