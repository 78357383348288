// src/components/CustomColorPicker/CustomColorPicker.js
import React, { useState } from 'react';
import {Button, Popover, Row, Col, Typography, Space} from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import styles from './CustomColorPicker.module.css';
import ColorGrid from "./ColorGrid";
import IconButton from "../../UI/IconButton";

const CustomColorPicker = ({ initialColor, onColorChange }) => {
    const [selectedColor, setSelectedColor] = useState(initialColor || '#1976d2');
    const [colorInputVisible, setColorInputVisible] = useState(false);

    const handleColorSelect = (color) => {
        setSelectedColor(color);
        onColorChange(color);
        setColorInputVisible(false);
    };

    const content = (
        <div className={styles.colorPickerPopover}>
            <Row justify="space-between" align={'middle'} gutter={[4, 4]}>
                <Typography.Text>
                    Ընտրել գույնը
                </Typography.Text>
                <Space className={styles.iconButtonsWrapper} align={'center'}>
                    <IconButton
                        icon={<EnvironmentOutlined color={'#fff'} />}
                        shape="square"
                        size="medium"
                        style={{ backgroundColor: selectedColor }}
                        onClick={() => setColorInputVisible(false)}
                        className={styles.iconButtonLight}
                    />
                    <IconButton
                        icon={<EnvironmentOutlined color={'#000'} />}
                        shape="square"
                        size="medium"
                        style={{ backgroundColor: selectedColor }}
                        onClick={() => {
                            onColorChange(selectedColor);
                            setColorInputVisible(false);
                        }}
                        className={styles.iconButtonDark}
                    />
                </Space>
            </Row>

            <ColorGrid onColorSelect={handleColorSelect} />
        </div>
    );

    return (
        <Popover
            placement="bottomLeft"
            content={content}
            trigger="click"
            arrow={true}
            overlayClassName={styles.colorPickerPopoverWrapper}
            open={colorInputVisible}
            onOpenChange={(visible) => setColorInputVisible(visible)}
        >
            <Button
                shape="circle"
                size="small"
                className={styles.coloredCircleButton}
                onClick={() => setColorInputVisible(true)}
            />
        </Popover>
    );
};

export default CustomColorPicker;
