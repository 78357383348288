import React from "react"

const Bookmark = ({ isActive }) => {
  return (
      <svg width="51" height="48" viewBox="0 0 51 48" fill={isActive ? "#FF6539" : "#2C476C"} xmlns="http://www.w3.org/2000/svg">
          <path d="M25.5004 8.79373L23.2149 6.43533C17.8502 0.899352 8.01328 2.80975 4.4623 9.76972C2.79519 13.0433 2.41905 17.7697 5.46321 23.8017C8.3958 29.6097 14.4969 36.5664 25.5004 44.144C36.504 36.5664 42.6019 29.6097 45.5377 23.8017C48.5818 17.7665 48.2089 13.0433 46.5386 9.76972C42.9876 2.80975 33.1507 0.896153 27.7859 6.43213L25.5004 8.79373ZM25.5004 48C-23.375 15.5777 10.4518 -9.72781 24.9394 3.65774C25.1307 3.83374 25.3187 4.01614 25.5004 4.20494C25.6803 4.01631 25.8674 3.83484 26.0615 3.66094C40.5459 -9.73421 74.3759 15.5745 25.5004 48Z" fill={isActive ? "#FF6539" : "#2C476C"}/>
      </svg>
  )
}
export default Bookmark
