import React, {useCallback, useEffect, useState} from "react";
import Account from "../account";
import styles from "./style.module.css";
import "./style.css";
import LeftNavigation from "../../components/leftNavigation";
import AccountFooter from "../../components/accountFooter";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
    getUserData,
    getAllMicroArticlesBoard,
} from "../../store/selectors";
import { setAllMicroArticlesBoard } from "../../store/actions/microArticle";
import { Pagination } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import PageLoader from "../../components/UI/PageLoader";
import { useTranslation } from "react-i18next";
import TopBar from "../../components/topBar";
import MicroArticleBoardGrid from "../../components/UI/MicroArticleBoardGrid";
import { MicroArticleService } from "../../components/microArticle/service";
import SearchSection from "../../components/UI/PresentationsCard/SearchSection";

// Higher-Order Component to inject navigation
export const withNavigation = (Component) => {
    return (props) => <Component {...props} navigate={useNavigate()} />;
};

// Debounce function to limit the rate of function execution
const debounce = (fn, wait) => {
    let timeoutID;
    return (...args) => {
        if (timeoutID) clearTimeout(timeoutID);
        timeoutID = setTimeout(() => fn(...args), wait);
    };
};

const debounced300 = debounce((fn) => fn(), 300);

const MicroArticleBoard = (props) => {
    const { t } = useTranslation();
    const matches = useMediaQuery("(min-width:600px)");
    const [page, setPage] = useState(0); // Start from 0
    const [itemsTotal, setItemsTotal] = useState(0);
    const [isDeleted, setIsDeleted] = useState(false);
    const [searchParams, setSearchParams] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    // Handle page change in pagination
    const handleChangePage = (e, value) => {
        const pageLoc = Number(value) - 1; // Convert to 0-based
        if (!isNaN(pageLoc) && pageLoc !== page) {
            setPage(pageLoc);
            window.history.replaceState(null, null, `?page=${pageLoc}`);
        }
    };

    // Fetch microarticles data based on current page and search parameters
    const handleGetData = useCallback(async (deleted = false) => {
        setIsDeleted(!!deleted);
        const queryParams = new URLSearchParams(window.location.search);
        let pageLoc = Number(queryParams.get("page"));
        if (isNaN(pageLoc)) {
            pageLoc = 0; // Default to 0 if not set
        }

        // Calculate total pages based on itemsTotal
        const totalPages = Math.ceil(itemsTotal / 10) || 1;

        if (pageLoc >= totalPages) pageLoc = totalPages - 1;
        if (pageLoc < 0) pageLoc = 0;

        if (!isNaN(pageLoc)) {
            if (pageLoc !== page) setPage(pageLoc);
            setIsLoading(true);
            try {
                // Fetch microarticles from the service
                const userId = props.user?.id;
                const res = await MicroArticleService.getMicroArticlesForBoard(pageLoc, userId);

                // Update total items and set microarticles in Redux store
                const data = res.data.data[0];
                setItemsTotal(data.total);
                props.setAllMicroArticlesBoard(data.items);

                // Update the URL with the current page
                window.history.replaceState(null, null, `?page=${pageLoc}`);
            } catch (error) {
                console.error("Error fetching microarticles:", error);
                // Optionally, handle errors (e.g., show a notification)
            } finally {
                setIsLoading(false);
            }
        }
    }, [page, itemsTotal, props.user]);

    // Effect to fetch data when page, searchParams, or user changes
    useEffect(() => {
        if (props.user && props.user.id) {
            handleGetData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchParams, props.user]);

    // Handle search input changes with debounce
    const handleSetSearchParams = (e) => {
        debounced300(() => {
            setPage(0); // Reset to first page on new search (0-based)
            const newSearch = { ...searchParams };
            newSearch.searchTerm = e?.target?.value.trim() || "";
            setSearchParams(newSearch);
        });
    };

    return (
        <Account onlyBG={true}>
            <LeftNavigation />
            <TopBar />
            <div className={styles["microArticle-dashboards"] + " main-wrapper-microArticleBoard"}>
                <div className={styles["account-dashboards--search"]}>
                    <SearchSection handleSetSearchParams={handleSetSearchParams} />
                </div>
                <div style={{maxHeight: 'calc(83% + 40px)', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, width: '100%'}}>
                    {!isLoading || isDeleted ? (
                        props.getAllMicroArticlesBoard && props.getAllMicroArticlesBoard.length > 0 ? (
                            <MicroArticleBoardGrid microArticleData={props.getAllMicroArticlesBoard} handleGetData={handleGetData} />
                        ) : (
                            <div className={styles["microArticle-empty-data"]}>
                                <h2>{t("noSavedPresentations")}</h2> {/* Updated translation key */}
                            </div>
                        )
                    ) : (
                        <div className={styles["loader-container"]}>
                            <PageLoader />
                        </div>
                    )}
                </div>
                <div className={"account-dashboards--pagination"}>
                    {(!isLoading || isDeleted) && itemsTotal > 10 && (
                        <Pagination
                            className={"pagination"}
                            count={Math.ceil(itemsTotal / 10)}
                            page={page + 1} // Convert to 1-based
                            onChange={handleChangePage}
                            color="primary"
                            variant="outlined"
                            shape="rounded"
                        />
                    )}
                </div>
            </div>
            <AccountFooter />
        </Account>
    );
};

// Map Redux state to component props
const mapStateToProps = (state) => ({
    user: getUserData(state),
    getAllMicroArticlesBoard: getAllMicroArticlesBoard(state),
});

// Map dispatch actions to component props
const mapDispatchToProps = {
    setAllMicroArticlesBoard,
};

// Export the connected component with navigation
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNavigation(MicroArticleBoard));
