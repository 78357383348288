import React, {useEffect, useMemo, useRef, useState} from "react";
import ReactDOM from "react-dom";
import LeftSide from "../../components/leftSide";
import MapHeader from "../../components/mapHeader";
import RightSide from "../../components/rightSide";
import Timeline from "../../components/timeline";
import "./style.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "mapbox-gl";
import {HandleToken} from "../../helpers";
import {
    determinateURLParams,
    determineCatIds,
    extractId,
    formatServerResponseTime,
    generateTimeLineQueryString,
    getSelectedYear,
    getShowByType,
    subCatFilterLogic,
    _filterByFeatureId,
    MAX_ZOOM_MAP,
    handleTimeLineClickActionST,
    navigateTo,
    onMouseEnterScrollZone,
    setCurrentOnTimeline,
    changeTimeLineOptions,
    stopPinAnimation,
    goToTopics,
    filterFeatuers,
    onMapRenderComplete,
    move,
    changeMapZoom,
    clickSliderItemHandler,
    handleTimelineChange,
    getSubjectSettings,
    showArticleReadMoreSection,
    mapTransformRequest,
    determineMapStyle,
    filterFeaturesBySource,
    extractFeatureLayerKeys,
    organizeFilterLayers,
    finalizeLayers,
    updateAndDispatchLayers,
    closeMapPopupsAndMarkers,
    formatTimeString,
    updateMapTiles,
    fitMapToClusterBounds, handleMobileTouch, setupMouseMoveListener, setupZoomEndListener, calculateChildPositions
} from "./utils/utils";
import {
    mapLessonAPIRequest,
    mapLiveAPIRequest,
} from "./utils/mapPreviewMode";
import {MicroArticleService} from "../../components/microArticle/service";



import * as turf from "@turf/turf";
import Supercluster from "supercluster";
import "./style.css";
import TimeLineClock from "../../assets/imgs/timeline_clock";
import axios from "axios";
import {mapItemAdapter, MapSlider} from "../../components/mapSlider";
import {
    getMapScale,
    MAP_TOOLBOX_KEYS,
    Toolbox
} from "../../components/mapToolbox";


import WorldMapLoader from "../../components/UI/WorldMapLoader/WorldMapLoader";
import {isMobile} from "react-device-detect";
import StickyNotesEditPopup from "../../components/toolbox/StickyNotes/StickyNotesEditPopup";
import {withTranslation} from "react-i18next";
import i18n from "i18next";
import NoLanguageContent from "../../components/UI/NoLanguageContent/NoLanguageContent";
import MapContainer from "./map-container";
import {
    debeounced500,
    debeounced100,
    debeounced10,
    handleTouchMove,
    handleTouchStart,
    adjustMapPitchAndCompass,
    handleSelectedElement,
} from "./utils/helper";
import useMapDispatch from "./hooks/useMapDispatch";
import {mapboxGlDrawStylesIds, SOURCE_OPTIONS_FILTER} from "./constants";
import usePrevMapSelectors from "./hooks/usePrevMapSelectors";
import {createPopup, createPopupWithRadius} from "./utils/mapPopupHelpers";
import {createArticleFeature, createClusterElement} from "./utils/featureHelpers";
import {
    fetchLegends,
    fetchMetadata, finalizeMapSetup,
    handleScreenshotMode,
    processMetadata,
    setupMapFirstViewMode
} from "./utils/mapSetupUtils";
import LeftSideRefactored from "../../components/leftSideRefactored";

const queryParams = new URLSearchParams(window.location.search);


const NewMap = (props) => {
    const { selectors, previousSelectors } = usePrevMapSelectors();
    const actions = useMapDispatch();

    const [globalLoading, setGlobalLoading] = useState(true);
    const [selectedElement, setSelectedElement] = useState(null);
    const [markerAnimation, setMarkerAnimation] = useState(null);
    const [url, setUrl] = useState({
        lessonID: queryParams.get("lessonID"),
        topicID: queryParams.get("topicID"),
        gradeID: queryParams.get("gradeID"),
        subjectID: queryParams.get("subjectID"),
        articleID: queryParams.get("articleID"),
        preview: queryParams.get("preview"),
        type: queryParams.get("type"),
        articleReadMode: queryParams.get("articleReadMode"),
        bookmarkID: queryParams.get("bookmarkID"),
        screenShot: queryParams.get("screenShot"),
        screenSlide: queryParams.get("screenSlide"),
        userId: queryParams.get("userId"),
    });
    const [subject, setSubject] = useState({});
    const [toolboxValues, setToolboxValues] = useState({});
    const [screenShotFirstLoad, setScreenShotFirstLoad] = useState(true);
    const [superCluster, setSuperCluster] = useState(null);
    const [mapData, setMapData] = useState(null);
    const [loadFirst, setLoadFirst] = useState(true);
    const [timeLineChanged, setTimeLineChanged] = useState(false);
    const [nextLessonId, setNextLessonId] = useState(null);
    const [prevLessonId, setPrevLessonId] = useState(null);
    const [subjectID, setSubjectID] = useState(null);
    const [gradeID, setGradeID] = useState(null);
    const [metadata, setMetadata] = useState(null);
    const [loadingOverlay, setLoadingOverlay] = useState(false);
    const [isScreenshot, setIsScreenshot] = useState(false);
    const [hasLanguageContent, setHasLanguageContent] = useState(true);
    const [topic, setTopic] = useState(true);
    const [legends, setLegends] = useState(null);

    // Refs
    const selectorsRef = useRef(selectors);
    const prevStatesRef = useRef(previousSelectors)

    const mapRef = useRef(null);
    const mapContainerRef = useRef(null);
    const timeLineRef = useRef(null);
    const timeLineRelatedRef = useRef(null);
    const customLineRef = useRef(null);
    const geojsonRef = useRef(null);
    const stopCustomTimeChangeRef = useRef(null);
    const initMapToolboxListenersRef = useRef(null);
    const mapLiveViewRef = useRef(null);
    const metadataRef = useRef(metadata);
    const legendsRef = useRef(legends);
    const selectedMapStyleRef = useRef(selectorsRef.current.selectedMapStyle);
    const screenshotLanguageRef = useRef(selectorsRef.current.screenshotLanguage);
    const mapZoomRef = useRef(selectorsRef.current.mapZoom);
    const screenShoteGenFRef = useRef(null);
    const subjectRef = useRef(null);
    const runScreenShotStatesRef = useRef(null);
    const currentBaseMapIdRef = useRef(null);
    const prevTimeLineChangedRef = useRef(timeLineChanged);
    const prevMapSelectedClusterRef = useRef(null);
    const prevSelectedMapRequirementRef = useRef(selectorsRef.current.selectedMapRequirement);

    // TimeLine
    async function setupCustomCollections(resData, legends) {
        const subCat = {};
        const cat = {};

        // Populate `cat` and `subCat` with categories and subcategories
        selectorsRef.current.categories?.forEach?.((el) => {
            cat[el.id] = el;
            el?.subCategories?.forEach?.((item) => {
                subCat[item.id] = item;
            });
        });

        // Generate features for cluster elements
        const clusterFeatures = resData.clusterElements.reduce((acum, el) => {
            acum.push(createClusterElement(el, selectorsRef, legends, cat));
            return acum;
        }, []);

        // Generate features for cluster articles
        const articleFeatures = resData.clusterArticles.reduce((acum, el, idx) => {
            acum.push(createArticleFeature(el, subCat, cat, idx));
            return acum;
        }, []);

        const features = [...clusterFeatures, ...articleFeatures];

        // Set up the Supercluster index
        const index = new Supercluster({
            radius: 30,
            maxZoom: MAX_ZOOM_MAP,
        }).load(features);

        setSuperCluster(index);

        // Define GeoJSON source options
        const sourceOptions = {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features,
            },
            cluster: true,
            clusterMaxZoom: MAX_ZOOM_MAP, // Max zoom to cluster points on
            clusterRadius: 30, // Radius of each cluster when clustering points (defaults to 50)
            filter: SOURCE_OPTIONS_FILTER, // Apply filter if applicable
        };

        // Remove existing layers and sources
        ['unclustered-text', 'unclustered-point', 'clusters', 'cluster-count'].forEach((layer) => {
            if (mapRef.current.getLayer(layer)) {
                mapRef.current.removeLayer(layer);
            }
        });

        if (mapRef.current.getSource('Brainograph PIN GIS API')) {
            mapRef.current.removeSource('Brainograph PIN GIS API');
        }

        // Add the new source
        mapRef.current.addSource('Brainograph PIN GIS API', sourceOptions);
    }
    async function installMapImportantFeatures (resData,type) {
        const lng = isScreenshot ? screenshotLanguageRef.current : (i18n.language === 'en' ? 2 : 1)
        const catIds = determineCatIds(resData, type);
        setSubjectID(resData.subjectId)
        setGradeID(resData.gradeId)

        if (url?.type === "lesson" && (!resData?.subjectId || !mapData?.showTimeline) ) {
            await axios.get(`${process.env.REACT_APP_HEADING_URL}/api/Topic/${resData?.topicIds?.[0]}/Language/1`)
                .then(async (res) => {
                    setTopic(res.data.data[0])
                    await fetchSubjectSettings(resData?.subjectId || res.data.data[0].subjectId)
                    return true
                }).then(async ()=>{
                    await actions.getBaseMapsSettingsFromAPI({
                        [`${url.type}Id`]: +url[`${url.type}ID`],
                        IncludeBaseMapGroup:true
                    },lng);
                }).then(async ()=>{
                    await actions.setMapStylesList(subjectRef.current?.setting?.mapTypes);
                })
        }
        else{
            await fetchSubjectSettings(resData?.subjectId)
                .then(async ()=>{
                    await actions.getBaseMapsSettingsFromAPI({
                        [`${url.type}Id`]: +url[`${url.type}ID`],
                        IncludeBaseMapGroup:true
                    },lng);
                })
                .then(async ()=>{
                    await actions.setMapStylesList(subjectRef.current?.setting?.mapTypes);
                })
        }
        if(url.screenShot){
            setIsScreenshot(true)
            if (screenShotFirstLoad && !!url.userId) {
                window.scrrenShot = true
                await screenShoteGenFRef.current?.setData(screenShoteGenFRef.current.nextArg())
                    .then((runScreenShotStates)=>{
                        // setRunScreenShotStates(runScreenShotStates)
                        runScreenShotStatesRef.current = runScreenShotStates
                    })
            }
        }
        await actions.getCategoriesFromAPI(lng)
            .then(async(res)=> await actions.getFilteredCategoriesFromAPI(resData, catIds, type,res))
        return
    }
    async function handleSetMapStyleExtraLayers (styleId) {
        await actions.setMapStyleExtraLayers(styleId || 1)
            .then(res=>{
                res.forEach(layer=>{
                    mapRef.current.addLayer(layer)
                })
            })
    }
    const getAllClusters = () => {
        let zoom = Math.floor(mapRef.current.getZoom());
        let clusters = superCluster.getClusters([-180, -90, 180, 90], zoom);
        return clusters;
    }
    async function mapLiveView (id, type,legends,stopFly=false,onlymap=false,time,loadFeatures,resData) {
        const lng = isScreenshot ? screenshotLanguageRef.current : (i18n.language === 'en' ? 2 : 1)
        const selectedMapStyle = selectedMapStyleRef.current.active
        const styleId = selectedMapStyleRef.current[selectedMapStyle]
        actions.setScreenShotBaseMapGroupId(selectedMapStyleRef.current.id)
        actions.setScreenShotBaseMapIsLightST(selectedMapStyle === 'light')
        const catIds = determineCatIds(resData, type);
        // actions.loadingMap(true) getById
        setupCustomCollections(resData,legends)
        await handleSetMapStyleExtraLayers(styleId)
        if(!onlymap) {
            // #725a4f
            let _thisMapCurrent = mapRef.current
            const layers = mapRef.current
                .getStyle()
                .layers
                .map((l) => l.id)

            const touchAndClickHandlerForMap = (el, e)=> {
                if(isMobile) {
                    const result = handleMobileTouch(isMobile, touchStartTime, isDragging, startCoordinates);

                    if (result.shouldReturn) return;

                    touchStartTime = result.touchStartTime;
                    isDragging = result.isDragging;
                    startCoordinates = result.startCoordinates;
                    if (e.originalEvent?.target.className.includes("shape")) return;
                }

                const isSelectorActive = [
                    selectorsRef.current.getRulerClickedState,
                    selectorsRef.current.getCommentToolboxState,
                    selectorsRef.current.getTextToolboxState,
                    selectorsRef.current.getDrawerToolboxClickedState,
                    selectorsRef.current.getEraserClickedState,
                    selectorsRef.current.getLineDrawToolboxState,
                    selectorsRef.current.getPolygonDrawToolboxState,
                    selectorsRef.current.getStickyNotesClickedState,
                ].some(state => state);

                const isElMatching = [
                    "gl-draw-line-active.cold",
                    "gl-draw-line-active.hot",
                    "gl-draw-point-active.hot",
                    "gl-draw-point-active.cold",
                    "gl-draw-polygon-and-line-vertex-active.active",
                    "gl-draw-polygon-and-line-vertex-active.cold",
                    "gl-draw-polygon-and-line-vertex-inactive.cold",
                    "gl-draw-polygon-and-line-vertex-inactive.active",
                    "gl-draw-polygon-stroke-inactive.cold",
                    "gl-draw-polygon-stroke-inactive.active",
                ].includes(el);

                if (!e.point || isSelectorActive || isElMatching) {
                    return;
                }

                const activeSources = ['Brainograph PIN GIS API','Brainograph GIS API']

                const allFeatures = _thisMapCurrent.queryRenderedFeatures(e.point)?.filter(el => activeSources.includes(el.source))
                const features = _thisMapCurrent.queryRenderedFeatures(e.point, {layers: [el]});
                if (allFeatures[0] && allFeatures[0].properties.cluster) {
                    mapRef.current.fire('closeMapboxGlDrawPopupsAndSelection');
                    const clusters = mapRef.current.queryRenderedFeatures(e.point, {layers: ["clusters"]});
                    const lng = e.lng || e.lngLat.lng;
                    const lat = e.lat || e.lngLat.lat;
                    const cluster = clusters.find(item => item.geometry?.coordinates?.[0] === lng && item.geometry?.coordinates?.[1] === lat) || clusters[1]
                    const clusterId = cluster?.properties.cluster_id;

                    if (clusterId) {
                        const source = mapRef.current.getSource("Brainograph PIN GIS API");

                        const handleClusterChildren = (clusterId, zoom) => {
                            source.getClusterChildren(clusterId, (error, features) => {
                                if (!error) {
                                    generMarker(cluster.geometry.coordinates, features, zoom, clusterId);
                                }
                            });
                        };

                        source.getClusterExpansionZoom(clusterId, (err, zoom) => {
                            if (err) {
                                handleClusterChildren(clusterId, null);
                                return;
                            }

                            if (zoom <= MAX_ZOOM_MAP) {
                                mapRef.current.easeTo({
                                    center: { lng, lat },
                                    zoom: zoom + 0.1,
                                });
                                return;
                            }

                            handleClusterChildren(clusterId, zoom);
                        });
                    }
                    return
                }
                if (allFeatures.some(feature => ["mapbox-gl-draw-cold", "mapbox-gl-draw-hot"].includes(feature.source))) {
                    return;
                }
                if ((allFeatures[0] && allFeatures[0].layer && allFeatures[0].layer.id === "unclustered-text") || (features[features.length - 1] && features[features.length - 1].layer.id === "unclustered-point")) {
                    mapRef.current.fire('closeMapboxGlDrawPopupsAndSelection');
                    const unclusterPoint = mapRef.current.queryRenderedFeatures(e?.point, {layers: ["unclustered-point"]});
                    if(!unclusterPoint[1]) return;
                    const markerInfo = {
                        id: unclusterPoint[1]?.properties.id,
                        cordinates: unclusterPoint[1]?.geometry.coordinates,
                        pointCount: 0,
                        articleIds: JSON.parse(unclusterPoint[1]?.properties.articleIds),
                        catColor: unclusterPoint[1]?.properties?.catColor
                    }
                    actions.dispatchMapMarkerInfo(markerInfo)
                    generAnimationMarker(markerInfo)
                    setSelectedElement(unclusterPoint[1])
                    return
                }
                const feature = allFeatures.find(el=> 'articleids' in el?.properties)
                if (feature) {
                    mapRef.current.fire('closeMapboxGlDrawPopupsAndSelection');
                    actions.getArticleFromAPI(feature?.properties.articleids)
                }
            }

            let touchStartTime = 0;
            let startCoordinates = null;
            let isDragging = false;

            if (isMobile) {
                mapRef.current.on('touchstart', (e) => {
                    const result = handleTouchStart(e, touchStartTime, startCoordinates);
                    touchStartTime = result.touchStartTime;
                    startCoordinates = result.startCoordinates;
                    isDragging = result.isDragging;
                });

                mapRef.current.on('touchmove', (e) => {
                    isDragging = handleTouchMove(e, startCoordinates);
                });
            }

            layers.forEach(el => {
                if(mapboxGlDrawStylesIds[el]) return;
                mapRef.current.on(isMobile ? "touchend" : "click", el, e => debeounced10(() => touchAndClickHandlerForMap(el, e)))
            })
            mapRef.current.on("zoom", () => {
                adjustMapPitchAndCompass(mapRef, actions);

                if (selectedElement) {
                    debeounced100(() => {
                        handleSelectedElement(mapRef, selectedElement, actions, generAnimationMarker,getAllClusters);
                    });
                }
                const currentZoom = mapRef.current.getZoom();
                debeounced500(() => {
                    actions.setMapZoom(currentZoom);
                });
            });
            actions.getLampInfo(resData, type);
            const {startFrom, endTo} = formatServerResponseTime(resData);
            setMapData(resData)
            setSubjectID(resData.subjectId)
            setGradeID(resData.gradeId)

            processTimeLineRendering(resData, type, false, lng);
            if(url.type === 'article') {
                actions.getArticleFromAPI(url.articleID, false)
            }
            if(url.type !== 'article') {
                actions.getQuizInfo(type, id)
            }
            if (resData?.clusterBounds?.coordinates) {
                fitMapToClusterBounds(resData, mapRef, actions, { padding: 0.2, buffer: 0.7 });
            }
        }
        setTimeout(()=> {
            setGlobalLoading(false)
        },500)
        if(onlymap) {
            const gisApiSources = mapRef.current.getStyle()?.sources['Brainograph PIN GIS API']
            if (!gisApiSources || !loadFeatures) return;
            gisApiSources.data.features = loadFeatures
            mapRef.current.getSource('Brainograph PIN GIS API')._updateWorkerData()
        }
        if (url.screenShot && screenShotFirstLoad && !!url.userId) {
            const statesST = ['mapSTF','toolBarSTF','compassSTF',]
            runScreenShotStatesRef.current?.(statesST)
        }
    };
    function setLampModalDataAndState (time, isScreenShot) {
        const timeYear = Math.abs(new Date(time).getFullYear());

        let data = selectorsRef.current.lampData.filter(
            (item) => {
                if (!item?.timeEnd?.isBc &&
                    !item?.timeStart?.isBc &&
                    timeYear - 5 <= Math.abs(item.timeEnd.year) &&
                    timeYear + 5 >= Math.abs(item.timeStart.year)) {
                    return true
                } else if (
                    item?.timeEnd?.isBc &&
                    item?.timeStart?.isBc &&
                    timeYear + 5 >= Math.abs(item.timeEnd.year) &&
                    timeYear - 5 <= Math.abs(item.timeStart.year)
                ) {
                    return true
                } else {
                    return false
                }
            }
        );
        actions.setLampModalData(data);

        if (isScreenShot) {
            actions.setLampModalState(selectorsRef.current.lampModalState);
            return;
        }
        actions.setLampModalState(data.length > 0);
        actions.setLampModalStateIndex(0)

    };

    /**
     * Handles time changes on the map, updating relevant data and map tiles.
     */
    function handleTimeChange(time, isScreenShot) {
        const lng = isScreenshot ? screenshotLanguageRef.current : (i18n.language === 'en' ? 2 : 1)
        closeMapPopupsAndMarkers(mapRef);

        const formattedTime = formatTimeString(time);
        timeLineRef.current.setCustomTimeTitle(formattedTime, "t1");

        setLampModalDataAndState(time, isScreenShot);

        const year = getSelectedYear(time, timeLineRef);
        const newObj = generateTimeLineQueryString(year - 1, url, selectorsRef.current.selectedLesson, mapData?.id,lng);

        updateMapTiles(mapRef, newObj);
    }

    // Start
    // TimeLine rendering
    async function processTimeLineRendering (data, type, fromTopic,lng=1) {
        if (type === "lesson") {
            if (fromTopic === false && selectorsRef.current.selectedLesson === null) {
                actions.setSelectedLesson(url.lessonID);
            }
            actions.setMiddleMaxTimeLineLessonsData(data);
            actions.setMiddleTimeLineLessonsData(data);
            actions.setTimeLineLessonData(
                data,
                data.id,
                data.id,
                data.articles,
                fromTopic,
                actions.setTopicId,
                actions.dispatchTopicNavigation,
                lng
            );

        }
        if (type === "topic") {
            await actions.setTimeLineTopicsData(data, type,lng);
            await actions.setMiddleTimeLineTopicsData(data);
        }
        if (type === "article") {
            actions.setTimeLineArticlesData(data, type,lng);
        }
    };
    function processSelectedWhateverView (data) {
        onMouseEnterScrollZone(timeLineRef,customLineRef,timeLineRelatedRef,handleTimeChange);
        timeLineRef.current.on("click", handleTimeLineClickAction);
        timeLineRef.current.on("rangechange", (e)=>{
            stopCustomTimeChangeRef.current = true
        });
        timeLineRef.current.on("rangechanged", (e)=>{
            setTimeout(()=>stopCustomTimeChangeRef.current = false, 0)
        });
        timeLineRelatedRef.current?.on("click", handleTimeLineClickAction);
        setCurrentOnTimeline(data,customLineRef,timeLineRef,selectorsRef.current.selectedLesson);
        changeTimeLineOptions(data,timeLineRef,timeLineRelatedRef);
        reactToTimeChange();
    };

    function filterLegend () {
        filterFeatuers(mapRef,selectorsRef.current.filteredLegends)
        debeounced500(()=>filtredLegendsByViewport(selectorsRef.current.legendsData))
    }

    function filterLegendsViewport () {
        return  filtredLegendsByViewport(selectorsRef.current.legendsData)
    }

    function reactToTimeChange () {
        timeLineRef.current.redraw();
        timeLineRelatedRef.current?.redraw();
        timeLineRef.current.on("timechanged", (properties) => {
            let time = properties.time;
            const activeEvent = selectorsRef.current.timeLineItems?.getItemByDateRange?.(timeLineRef.current?.timeAxis?.step?.step || 0, time)
            if (activeEvent.length > 0) {
                time = activeEvent[0].start
            }
            timeLineRef.current.removeCustomTime('t1')
            timeLineRef.current.addCustomTime(time, 't1')

            setLampModalDataAndState(time);
            handleTimeChange(time);
            setTimeout(() => {
                const eventIcon = document.getElementById('event-icon')
                const customTimeLine = document.querySelector(".t1")
                const timeLineClock = document.querySelector(".time-line-clock")
                if (!timeLineClock) {
                    const rootElement = document.createElement("div")
                    rootElement.className = 'time-line-clock'
                    ReactDOM.render(<TimeLineClock/>, rootElement);
                    customTimeLine?.appendChild(rootElement)
                }
                const customTimeLineParams = customTimeLine?.getBoundingClientRect();
                document.querySelector('.iconic-clock-minute-hand').setAttribute('transform', `rotate(${customTimeLineParams?.left * 6},192,192)`);
                document.querySelector('.iconic-clock-hour-hand').setAttribute('transform', `rotate(${customTimeLineParams?.left},192,192)`);

                if (eventIcon) {
                    eventIcon.remove()
                }
                if (activeEvent.length > 0) {
                    const icon = document.createElement('i')
                    icon.className = 'event-icon' + ' ' + activeEvent[0]?.elementType
                    icon.id = 'event-icon'
                    customTimeLine.appendChild(icon)
                }

                debeounced100(()=>onMapRenderComplete(mapRef.current,filterLegend))

            }, 0)

        });
    };

    // End
    function triggerClickMapCluster (selectedCluster) {
        mapRef.current.fire('closeMapboxGlDrawPopupsAndSelection');
        setTimeout(() => {
            const coords = {
                lat: selectedCluster.lat || selectedCluster?.elementProps?.cords?.[1],
                lng: selectedCluster.lng || selectedCluster?.elementProps?.cords?.[0],
            }
            if(coords.lat && coords.lng){
                mapRef.current.fire("click", {
                    ...coords,
                    point:mapRef.current.project(coords)
                })
            }

            if (selectedCluster.elementProps) {
                setTimeout(() => {
                    const {item, cords, count} = selectedCluster.elementProps
                    selectElement(item, cords, count, true);
                }, 500)
            }
        },3000)
    }

    function selectElement (item, cords, count, isScreenShoot) {
        const popupELems = document.getElementsByClassName('elements-popup-radius-content')
        if (popupELems && popupELems.length > 0) {
            [...popupELems].forEach(el => {
                el.dataset.active = false
            })
        }
        const markerInfo = {
            id: item.properties.id,
            cordinates: cords,
            pointCount: count,
            articleIds: item.properties.articleIds,
            catColor:null,
            flyTo:false
        }
        actions.dispatchMapMarkerInfo(markerInfo)
        generAnimationMarker(markerInfo)
        setSelectedElement(item)
        const element =document.getElementById(`element-${item.id || item?.properties?.id}`)
        if(element) element.dataset.active = true

    }

    function handleRequirements () {
        mapRef.current.fire('closeAllPopups');
        mapRef.current.fire('closeAnimationMarker');
        const type = selectorsRef.current.selectedMapRequirement;
        const data = mapRef.current.getStyle()?.layers
        const types = {
            'Mandatory': 1,
            'Additional': 2,
            'All': 3
        }

        function getOptionsFromBitmask(bitmask) {
            let options = [];

            if ((bitmask & 1) !== 0) {
                options.push("Mandatory");
            }

            if ((bitmask & 2) !== 0) {
                options.push("Additional");
            }
            return options;
        }

        let source = [...(mapRef.current.getStyle().sources['Brainograph PIN GIS API']?.data.features || [])]
        source.map(el => {
            if (el.properties.relationType) {
                el.properties.relationFilter = 'visible'
                if (types[type] && !getOptionsFromBitmask(el.properties.relationType).includes(type)) {
                    el.properties.relationFilter = 'none'
                }

            }
            return el
        });
        mapRef.current.getSource('Brainograph PIN GIS API')?._updateWorkerData(source)
    };

    async function handleMapStyleChange () {
        if( !mapRef.current) return
        setGlobalLoading(true)
        geojsonRef.current =  {
            type: "FeatureCollection",
            features: [],
        }
        const time = time
        const gisApiSources = mapRef.current?.getStyle()?.sources['Brainograph PIN GIS API']
        const selectedMapStyle = selectedMapStyleRef.current?.active
        const styleId = selectedMapStyleRef.current?.[selectedMapStyle]
        actions.getSpriteFromAPI()
        actions.setMapStylesIdST(styleId)
        actions.setScreenShotBaseMapGroupId(selectedMapStyleRef.current.id)
        actions.setScreenShotBaseMapIsLightST(selectedMapStyle === 'light')
        mapRef.current.setStyle(`${process.env.REACT_APP_GIS_URL}/BaseMap/${styleId}?${url.type}Id=${url[`${url.type}ID`]}`);
        mapRef.current.baseMapId = styleId
        mapRef.current.once('styledata', () => {
            const lng = isScreenshot ? screenshotLanguageRef.current : (i18n.language === 'en' ? 2 : 1)

            const selectedMapStyle = selectedMapStyleRef.current?.active
            const styleId = selectedMapStyleRef.current?.[selectedMapStyle]

            const year = getSelectedYear(time, timeLineRef);

            const newObj = generateTimeLineQueryString(
                year - 1,
                url,
                selectorsRef.current.selectedLesson,
                mapData?.id,
                lng
            );
            if (Object.keys(newObj).length !== 0) {
                const queryString = Object.keys(newObj)
                    .map((key) => key + "=" + newObj[key])
                    .join("&")
                const tileUrl = new URL(mapRef.current.getStyle()
                    .sources["Brainograph GIS API"].tiles[0]);
                mapRef.current.getSource("Brainograph GIS API")
                    .setTiles([`${tileUrl.origin}${decodeURI(tileUrl.pathname)}?${queryString}`]);
            }
        })
        mapRef.current.once("idle", () => {
            const lng = isScreenshot ? screenshotLanguageRef.current : (i18n.language === 'en' ? 2 : 1)

            const {id, type} = determinateURLParams(url);
            // if (type === "article") {
            //     window.location.href = `/`;
            // }
            ['unclustered-text','unclustered-point','clusters','cluster-count'].forEach(layer=>{
                if(mapRef.current.getLayer(layer)){
                    mapRef.current.removeLayer(layer)
                }
            })
            if (mapRef.current.getSource('Brainograph PIN GIS API')) {
                mapRef.current.removeSource('Brainograph PIN GIS API')
            }

            const legendsBody = {
                [`${url.type}Id`]: +url[`${url.type}ID`],
                baseMapId:styleId,
                includeSubLegends:true
            }
            const legendsData = actions.getLegendsFromAPI(legendsBody,lng)

            Promise.all([legendsData])
                .then(values=>{
                    setLegends(values[0])
                })
                .then(()=>{
                    mapLiveViewRef.current(id, type, selectorsRef.current.legends, true, true,time,gisApiSources?.data?.features,metadataRef.current)
                })
        })
    };

    function handleSetSuperCluster (newFeatures) {
        const index = new Supercluster({
            radius: 30,
            maxZoom: 8
        }).load(newFeatures);
        setSuperCluster(index)
    }

    function changeClusterItemsTime (date) {
        const newFeatures = []
        const year = new Date(date).getFullYear()
        const source = mapRef.current.getStyle().sources['Brainograph PIN GIS API']?.data.features?.map(el => {
            const {yearStart, yearEnd, visible} = el.properties
            el.properties.startTime = year
            el.properties.endTime = year
            if (
                (visible === 'visible') &&
                ((year !== year) ||
                    (year >= yearStart && year <= yearEnd) ||
                    (year >= yearStart && year <= yearEnd))
            ) {
                newFeatures.push(el)
            }
            return el
        });
        handleSetSuperCluster(newFeatures)

        mapRef.current.getSource('Brainograph PIN GIS API')?._updateWorkerData(source)
    }

    function generAnimationMarker ({id,cordinates,pointCount,articleIds,catColor,flyTo = true}) {
        if(Array.isArray(articleIds) && !Number.isNaN(+articleIds[0])) actions.getArticleFromAPI(articleIds[0],!flyTo)

        if(!('closeAnimationMarker' in mapRef.current._listeners)){
            mapRef.current.on('closeAnimationMarker', (e) => {
                if(!('ids' in e) || e.ids.includes(id)){
                    markerAnimation.remove();
                    setSelectedElement(null)
                }else if(selectedElement?.properties?.id && e.ids.includes(selectedElement?.properties?.id)){
                    markerAnimation.remove();
                    setSelectedElement(null)
                }
                actions.dispatchMapMarkerInfo({})
            });
        }
        const generSize  = (count) =>{
            const sizes = [40,40,45,60,70]
            const elemCount = [1,10,40,100]
            const elemCountIndex = elemCount.findIndex(elCount=>elCount > count)
            if(elemCountIndex === -1) return sizes[sizes.length -1];
            return sizes[elemCountIndex];
        }
        let mapMarker = document.getElementById("markerAnimation");

        const circleSize = generSize(pointCount)
        if (mapMarker && +markerAnimation.getElement().dataset.id === +id) return

        if (mapMarker) {
            markerAnimation.setLngLat(cordinates);
            markerAnimation.getElement().dataset.id = id;
            markerAnimation.getElement().style.cssText = `--size:${circleSize}px;--catColor:${catColor}`;
        } else {
            const el = document.createElement('div');
            el.id = 'markerAnimation';
            el.classList.add("tic_animation");
            el.dataset.id = id
            el.style.cssText = `--size:${circleSize}px;--catColor:${catColor}`;
            el.style.zIndex = "1";
            const markerAnimation = new mapboxgl.Marker(el).setLngLat(cordinates).addTo(mapRef.current);
            setMarkerAnimation(markerAnimation)
        }
    }

    function selectELements (item, cords, count) {
        const popupELems = document.getElementsByClassName('elements-popup-radius-content')
        if (popupELems && popupELems.length > 0) {
            [...popupELems].forEach(el => {
                el.dataset.active = false
            })
        }
        const markerInfo = {
            id: item.properties.id,
            cordinates: cords,
            pointCount: count,
            articleIds: item.properties.articleIds,
            flyTo:false
        }

        actions.dispatchMapMarkerInfo(markerInfo)
        generAnimationMarker(markerInfo)

        setSelectedElement(item)
        if (document.getElementById(`element-${item.id || item?.properties?.id}`)) {
            document.getElementById(`element-${item.id || item?.properties?.id}`).dataset.active = true
        }
    }
    function generMarker(coordinates, child, zoomInfo, clusterId) {
        const currentZoom = mapRef.current.getZoom();
        const zoom = currentZoom <= 8 ? 8 : zoomInfo;

        // Fly to the specified coordinates
        mapRef.current.flyTo({
            center: coordinates,
            zoom: zoom && zoom > 16 ? 16 : zoom || (currentZoom < 6 ? 6 : currentZoom),
        });

        mapRef.current.once('moveend', () => {
            const cluster = mapRef.current.queryRenderedFeatures(null, { layers: ["clusters"] })
                .find(el => el.id === clusterId);

            // Adjust zoom for the cluster
            if (zoom > 17) {
                mapRef.current.flyTo({
                    center: cluster?.geometry.coordinates,
                    zoom: zoom || (currentZoom < 6 ? 6 : currentZoom),
                });
            } else {
                mapRef.current.easeTo({
                    center: cluster?.geometry.coordinates,
                    zoom: zoom || (currentZoom < 6 ? 6 : currentZoom),
                });
            }

            setTimeout(() => {
                mapRef.current.once('moveend', () => {
                    let popup = null;

                    if (child.length <= 6) {
                        // Create popup with circular radius for fewer elements
                        const positionedChild = calculateChildPositions(child);
                        popup = createPopupWithRadius(
                            mapRef,
                            cluster?.geometry.coordinates,
                            positionedChild,
                            selectedMapStyleRef.current,
                            selectELements.bind(this)
                        );
                    } else {
                        // Create modal popup for larger datasets
                        const sortedChild = child.reduce((acum, el) => {
                            if (!acum[el?.properties?.catId]) acum[el?.properties?.catId] = [];
                            acum[el?.properties?.catId].push(el);
                            return acum;
                        }, {});

                        const sortedFlatChild = Object.values(sortedChild)
                            ?.map(sublist => sublist.sort((a, b) => a?.properties?.name - b?.properties?.name))
                            ?.flat();

                        popup = createPopup(
                            mapRef,
                            cluster?.geometry.coordinates,
                            sortedFlatChild,
                            selectELements.bind(this)
                        );
                    }

                    mapRef.current.on('closeAllPopups', () => {
                        popup.remove();
                    });
                });
            }, 300);
        });
    }

    function handleTimeLineClickAction (e) {
        if(stopCustomTimeChangeRef.current) return;
        if (e.event.target.id === "group_content") return;
        // if (e.event.target.id === "lesson-item") return;
        let time = e.time
        const customTimeLine = document.querySelector(".t1")
        const timeLineClock = document.querySelector(".time-line-clock")
        if(!timeLineClock){
            const rootElement = document.createElement("div")
            rootElement.className = 'time-line-clock'
            ReactDOM.render(<TimeLineClock/>, rootElement);
            customTimeLine?.appendChild(rootElement)
        }
        const customTimeLineParams = customTimeLine?.getBoundingClientRect();
        document.querySelector('.iconic-clock-minute-hand').setAttribute('transform', `rotate(${customTimeLineParams?.left * 6},192,192)`);
        document.querySelector('.iconic-clock-hour-hand').setAttribute('transform', `rotate(${customTimeLineParams?.left},192,192)`);
        const activeEvent = selectorsRef.current.timeLineItems?.getItemByDateRange?.(timeLineRef.current?.timeAxis?.step?.step || 1, time)
        const eventIcon = document.getElementById('event-icon')
        if(activeEvent[0]?.elementType === "Lamp"){
            mapRef.current.once('moveend', () => {
                const {lng,lat} = {lng:activeEvent[0].bounds.coordinates[0],lat:activeEvent[0].bounds.coordinates[1]}
                const cords = mapRef.current.project(activeEvent[0].bounds.coordinates)
                const elements = mapRef.current.queryRenderedFeatures(cords, {layers: ["clusters","unclustered-point"]})
                const element = elements.find(item => item.geometry?.coordinates?.[0] === lng && item.geometry?.coordinates?.[1] === lat) || elements[1]
                if(element?.layer.id === "clusters"){
                    const clusterId = element?.properties.cluster_id;
                    mapRef.current.getSource("Brainograph PIN GIS API")
                        .getClusterExpansionZoom(
                            clusterId,
                            (err, zoom) => {

                                if (err) {
                                    mapRef.current.getSource("Brainograph PIN GIS API")
                                        .getClusterChildren(clusterId, (error, features) => {
                                            if (!error) {
                                                generMarker(element.geometry.coordinates, features, zoom,clusterId)
                                                const activeChild = features.find(el=>el.properties.articleIds.includes(activeEvent[0].articleId))
                                                selectELements(activeChild,activeEvent[0].bounds.coordinates,features?.length || 0)
                                            }
                                        });

                                    return
                                };
                                if (zoom <= 8) {
                                    return mapRef.current.easeTo({
                                        center: {lng,lat},
                                        zoom: zoom + 0.1
                                    });
                                }
                                mapRef.current.getSource("Brainograph PIN GIS API")
                                    .getClusterChildren(clusterId, (error, features) => {
                                        if (!error) {
                                            generMarker(element.geometry.coordinates, features, zoom,clusterId)
                                            const activeChild = features.find(el=>el.properties.articleIds.includes(activeEvent[0].articleId))
                                            selectELements(activeChild,activeEvent[0].bounds.coordinates,features?.length || 0)
                                        }
                                    });

                                return
                            });
                }
                if(element?.layer.id === "unclustered-point") {
                    const markerInfo = {
                        id: element.properties.id,
                        cordinates: element.geometry.coordinates,
                        pointCount: 0,
                        articleIds: JSON.parse(element.properties.articleIds),
                        catColor: element.properties?.catColor
                    }
                    actions.dispatchMapMarkerInfo(markerInfo)
                    generAnimationMarker(markerInfo)
                    setSelectedElement(element)
                }
            })
        }
        if(activeEvent[0]?.elementType === "Border"){
            const bbox = turf.bbox(activeEvent[0].bounds)
            mapRef.current.syncMapAndCompassStart()
            mapRef.current.on('idle',mapRef.current.syncMapAndCompassEnd)
            mapRef.current.fitBounds(bbox,{padding: 20})
        }
        if (eventIcon) {
            eventIcon.remove()
        }

        if (activeEvent?.length === 1) {
            time = activeEvent[0].start
            const icon = document.createElement('i')
            icon.className = 'event-icon' + ' ' + activeEvent[0]?.elementType
            icon.id = 'event-icon'
            document.getElementsByClassName('t1')[0].appendChild(icon)
        }
        actions.setTimeLineCursorStatusST(true);
        actions.setTimeLineEventDataST({
            targetId: e.event.target.id,
            item: e.item,
            time: time,
        });
        setTimeLineChanged(true)
        const customBar = document.querySelector(".t1");
        if (customBar === null) {
            timeLineRef.current.addCustomTime(time, "t1");
        } else {
            timeLineRef.current.setCustomTime(time, "t1");
        }
        handleTimeChange(time);
        debeounced100(()=>onMapRenderComplete(mapRef.current,filterLegend))
        changeClusterItemsTime(time)
        let data = selectorsRef.current.timeLineItems.find((x) => x.id === e.item);
        if (data) {
            if(data?.articleId) actions.getArticleFromAPI(data.articleId, false)
        }
    };

    function processLessonMode (id) {
        const lng = isScreenshot ? screenshotLanguageRef.current : (i18n.language === 'en' ? 2 : 1)
        mapLessonAPIRequest(
            id,
            lng,
            url.preview,
            actions.setLessonData
        ).then((resData) => {
            setNextLessonId(resData.next)
            setPrevLessonId(resData.previous)
            const type = "lesson";
            const catIds = determineCatIds(resData, type);
            actions.getFilteredCategoriesFromAPI(resData, catIds, type);
            actions.getLampInfo(resData, type);
            const {startFrom, endTo} = formatServerResponseTime(resData);
            setMapData(resData)
            const newObj = {}
            newObj.lessonId = selectorsRef.current.selectedLesson !== null ? selectorsRef.current.selectedLesson : mapData.id
            const queryString = Object.keys(newObj)
                .map((key) => key + "=" + newObj[key])
                .join("&") ;
            const tileUrl = new URL(mapRef.current.getStyle()
                .sources["Brainograph GIS API"].tiles[0]);
            mapRef.current.getSource("Brainograph GIS API")
                .setTiles([`${tileUrl.origin}${decodeURI(tileUrl.pathname)}?&${queryString}`]);

            //Remove layar filters
            const layers = mapRef.current.getStyle().layers;
            layers.forEach((layer) => {
                if (layer && layer.source === "Brainograph GIS API" && layer['source-layer'] === "brainograph") {
                    const filterOptions = [];
                    mapRef.current.getFilter(layer.id)
                        .forEach(filter => {
                            if (filter && !(filter[0] == '!=' && filter[1] == 'id')) {
                                filterOptions.push(filter);
                            }
                        })
                    mapRef.current.setFilter(layer.id, filterOptions);
                }
            });
            setLoadFirst(false)
            actions.loadingMap(false)
            processTimeLineRendering(resData, type, false, lng);
            actions.getQuizInfo(url.type, id)
        });
    };

    async function toggleLegendsFromMapByID (layerId, layerKey, subLayerId, key, type, action) {

        await actions.updateLegendToggle(layerId, subLayerId, key, type, action)
        filterFeatuers(mapRef,selectorsRef.current.filteredLegends)

        onMapRenderComplete(mapRef.current,filterLegendsViewport)
    }

    function handleGetItemVisibility (data) {
        const newItems = data.filter(el => {
            if (selectorsRef.current.filteredCategories?.checkIsHideFromTimeLineItem?.(el.articleId)) return el
        });
        return newItems
    }

    function toggleItemsFromTimeLineByID (categoryId, subCategoryId, type, articleItemId, elementsIds, action, show) {
        let newItems;
        actions.updateCategoriesToggle(
            categoryId,
            subCategoryId,
            type,
            articleItemId,
            action,
            show
        );

        if (selectorsRef.current.timelineExtend === 1) return
        if (selectorsRef.current.timelineExtend === 2) {
            newItems = handleGetItemVisibility(selectorsRef.current.middleTimeLineItems)
        } else if (selectorsRef.current.timelineExtend === 3) {
            newItems = handleGetItemVisibility(selectorsRef.current.middleMaxTimeLineItems)
        }
        timeLineRelatedRef.current?.setItems(newItems);
        timeLineRelatedRef.current?.redraw();

    }

    function toggleItemsFromMapByID (categoryId, subCategoryId, type, articleItemId, elementsIds, action, show) {
        // TODO improve this logic

        if (false && url.screenShot && screenShotFirstLoad) {
            actions.updateTimeLineGroupToggle(categoryId);
            const isShow = getShowByType(
                selectorsRef.current.filteredCategories,
                categoryId,
                subCategoryId,
                type,
                articleItemId
            );
            toggleItemFromMap(
                categoryId,
                type,
                isShow,
                subCategoryId,
                articleItemId,
                elementsIds
            );

            setScreenShotFirstLoad(false)
        } else {
            actions.updateCategoriesToggle(
                categoryId,
                subCategoryId,
                type,
                articleItemId,
                action,
                show
            );
            actions.updateTimeLineGroupToggle(categoryId);
            const isShow = getShowByType(
                selectorsRef.current.filteredCategories,
                categoryId,
                subCategoryId,
                type,
                articleItemId
            );
            toggleItemFromMap(
                categoryId,
                type,
                isShow,
                subCategoryId,
                articleItemId,
                elementsIds
            );
        }
    };

    function toggleItemFromMap (id, type, isShow, subID, articleItemId, elementsIds = [], onlyHide) {
        const mapCurrent = mapRef.current
        const data = mapCurrent.getStyle()
        let features = [];
        // Query for all features within the bounding box
        const categories = selectorsRef.current.filteredCategories
        if (!data || !data.layers.length) {
            return
        }
        const _subCatFilterIfNeeded = (feature) => {
            if (type === 'category') {
                return true
            } else {
                return subCatFilterLogic(categories, feature, id, isShow, subID)
            }
        }
        const getFilteredLayerIds = (feature) => {
            return data.layers
                .filter(_filterByFeatureId(feature))
                .map(extractId)
        }
        const toggleItemFromMapFUnc = (isShow, mapCurrent, elementsIds, articleItemIds) => {
            mapCurrent.fire('closeAllPopups');
            mapCurrent.fire('closeAnimationMarker', {ids: [...elementsIds, ...articleItemIds]});
            if (!isShow || onlyHide) {
                let sourceBrainographPINGISAPI = [...(mapRef.current.getStyle().sources['Brainograph PIN GIS API']?.data.features || [] )]
                sourceBrainographPINGISAPI.map(el => {
                    if (el.properties.visible && el.properties.articleIds.some(item => articleItemIds.includes(item))) {
                        el.properties.visible = 'visible'

                        if (elementsIds.includes(el.properties.id)) {
                            el.properties.visible = 'none'
                        }
                        if (el?.properties?.isArticle) {
                            el.properties.visible = 'none'
                        }
                    }
                    return el
                });
                mapRef.current.getSource('Brainograph PIN GIS API')?._updateWorkerData(sourceBrainographPINGISAPI)
            }
            else {
                const layers = mapCurrent.getStyle().layers;
                layers.forEach((layer) => {
                    if (layer && layer.source === "Brainograph GIS API" && layer['source-layer'] === "brainograph") {
                        const filterOptions = [];
                        mapCurrent.getFilter(layer.id)
                            .forEach(filter => {
                                if (filter && !(filter[0] == '!=' && filter[1] == 'id' && elementsIds.includes(filter[2]))) {
                                    filterOptions.push(filter);
                                }
                            })
                        mapCurrent.setFilter(layer.id, filterOptions);
                    }
                });
                const source = [...(mapRef.current.getStyle().sources['Brainograph PIN GIS API']?.data.features || [])]
                source.map(el => {
                    if (el.properties.visible && el.properties.articleIds.some(item => articleItemIds.includes(item))) {
                        if (elementsIds.includes(el.properties.id)) {
                            el.properties.visible = 'visible'
                        }
                        if (el?.properties?.isArticle) {
                            el.properties.visible = 'visible'
                        }
                    }
                    return el
                });
                mapRef.current.getSource('Brainograph PIN GIS API')?._updateWorkerData(source)
            }
        }
        if (type === 'article' || type === 'lamp') {
            toggleItemFromMapFUnc(isShow, mapCurrent, elementsIds, [articleItemId])
        } else if (type === 'layar' || type === 'category') {
            const articleItemIds = []
            const elementsIdsFromCat = categories
                .filter(categorie => categorie.show === isShow)
                .reduce((acum, item) => {
                    item.subCategories.forEach(subCategorie => {
                        subCategorie.articles.forEach(article => {
                            articleItemIds.push(article.id)
                            acum.push(...article.elementsIds)
                        })
                    })
                    return acum
                }, [])
            toggleItemFromMapFUnc(isShow, mapCurrent, elementsIdsFromCat, articleItemIds)
            selectorsRef.current.filteredCategories.updateItems()
        }
    }

    // End
    function filtredLegendsByViewport(data) {
        const features = filterFeaturesBySource(mapRef);
        const featureLayerKeys = extractFeatureLayerKeys(features, selectorsRef.current.filteredLegends);

        const { filterLayers, layerKey } = organizeFilterLayers(data, featureLayerKeys);
        const result = finalizeLayers(filterLayers, layerKey);

        updateAndDispatchLayers(layerKey, selectorsRef.current, actions, result);
    }
    async function setupMapFirstView (styleId = 1, mapData) {
        const lng = isScreenshot ? screenshotLanguageRef.current : (i18n.language === 'en' ? 2 : 1)
        let year = metadataRef.current?.timeStart?.year || 100;
        if(metadataRef.current?.timeStart?.isBc) year = year * -1
        console.log(mapData,'=======')
        const newObj = generateTimeLineQueryString(
            year - 1,
            url,
            selectorsRef.current.selectedLesson,
            mapData?.id,
            lng
        );
        const queryString = Object.keys(newObj)
            .map((key) => key + "=" + newObj[key])
            .join("&") ;

        mapRef.current = await new mapboxgl.Map({
            container: mapContainerRef.current,
            style: `${process.env.REACT_APP_GIS_URL}/BaseMap/${styleId}?${url.type}Id=${url[`${url.type}ID`]}`,
            center: [11, 42],
            minZoom: selectorsRef.current.mapConfigData.minZoom,
            maxZoom: selectorsRef.current.mapConfigData.maxZoom + 0.2,
            zoom: 0.865,
            maxPitch:45,
            renderWorldCopies: false,
            preserveDrawingBuffer: true,
            transformRequest: (url, resourceType) => mapTransformRequest(url, resourceType, queryString, lng,currentBaseMapIdRef)
            // maxPitch:60,
        })
        mapRef.current.baseMapId = styleId
        mapRef.current.on('idle', () => {
            setTimeout(() => {
                mapRef.current.resize()
            }, 10)
        })
        mapRef.current.on("styledata", () => {
            filterFeatuers(mapRef,selectorsRef.current.filteredLegends)
        })
        mapRef.current.on("moveend", () => {
            if(selectorsRef.current.menuExpend){
                debeounced100(()=> {
                    filterFeatuers(mapRef,selectorsRef.current.filteredLegends)
                    filtredLegendsByViewport(selectorsRef.current.legendsData)
                })
            }
        });
    }

    function moveLeft () {
        return move(0.2 , timeLineRef, timeLineRelatedRef);
    }
    function moveRight () {
        return move(-0.2, timeLineRef, timeLineRelatedRef);
    }

    // Timeline
    function onTimeLineShowChange () {
        mapRef.current?.fire('closeAllPopups');
        mapRef.current?.fire('closeAnimationMarker');
        setTimeLineChanged(false)
    }

    const fetchSubjectSettings = async (id) => {
        await getSubjectSettings(id, i18n.language, isScreenshot, actions, setSubject, screenshotLanguageRef);
    };

    const initMapToolboxListeners = () => {
        const toolbox = subjectRef.current?.toolbox;
        if (!toolbox) return;

        const listenMouseMove = toolbox[MAP_TOOLBOX_KEYS.HEIGHT] || toolbox[MAP_TOOLBOX_KEYS.COORDS];
        const listenZoomEnd = !!toolbox[MAP_TOOLBOX_KEYS.SCALE];

        if (listenMouseMove) {
            setupMouseMoveListener(mapRef, toolbox, setToolboxValues, MAP_TOOLBOX_KEYS);
        }

        if (listenZoomEnd) {
            setupZoomEndListener(mapRef, toolbox, setToolboxValues, MAP_TOOLBOX_KEYS, getMapScale);
        }
    };

    function navigate (to)  {
        const isTopicMode = url?.type === 'topic';
        const navigationType = isTopicMode ? selectorsRef.current.topicNavigation : selectorsRef.current.lessonNavigationData;
        navigateTo(to,navigationType,isTopicMode)
    }

    const initializeComponent = async () => {
        try {
            window.scrrenShot = false;
            actions.getSpriteFromAPI('/BaseSprite');
            const baseMapConfigs = await actions.getBaseMapConfigurationsFromAPI();
            mapboxgl.accessToken = baseMapConfigs.mapBoxAccessToken;

            await HandleToken();
            const { id, type } = determinateURLParams(url);

            // Handle screenshot mode
            await handleScreenshotMode(url, actions, screenShotFirstLoad, screenShoteGenFRef, setIsScreenshot);

            // Fetch metadata
            const lng = window.scrrenShot ? screenshotLanguageRef.current : (i18n.language === 'en' ? 2 : 1);
            const metadataResp = fetchMetadata(id, type, lng, mapLiveAPIRequest);

            if (url.preview === 'true' && url.type === 'article') {
                actions.setIsPresentationShowF(false);
            }

            // Process metadata
            const metadata = await processMetadata(
                metadataResp,
                url,
                setMetadata,
                setMapData,
                setHasLanguageContent
            );

            // Install important map features
            await installMapImportantFeatures(metadata, type);

            // Fetch legends
            const legends = await fetchLegends(url, lng, actions, selectedMapStyleRef);
            setLegends(legends);

            // Setup map first view
            await setupMapFirstViewMode(
                metadata,
                url,
                screenShotFirstLoad,
                selectedMapStyleRef,
                selectorsRef,
                setupMapFirstView
            );

            actions.setIsMicroArticlesLoading(true);
            const microArticles = await MicroArticleService.getMicroArticlesForMap();
            actions.setAllMicroArticles(microArticles);
            actions.setIsMicroArticlesLoading(false);

            // Finalize map setup
            finalizeMapSetup(id, type, legends, metadata, mapRef, initMapToolboxListenersRef, mapLiveViewRef);
        } catch (error) {
            console.error('Error initializing component:', error);
        }
    };

    screenshotLanguageRef.current = selectorsRef.current.screenshotLanguage
    subjectRef.current = subject
    selectedMapStyleRef.current = selectorsRef.current.selectedMapStyle;
    metadataRef.current = metadata;
    legendsRef.current = legends;

    currentBaseMapIdRef.current = useMemo(() => {
         return  determineMapStyle(selectedMapStyleRef, selectorsRef.current, screenShotFirstLoad, url);
    }, [selectedMapStyleRef, selectorsRef.current, screenShotFirstLoad, url]);
    initMapToolboxListenersRef.current = initMapToolboxListeners
    mapLiveViewRef.current = mapLiveView

    useEffect(() => {
        // Access the previous states
        const prevStates = prevStatesRef.current;

        // Logic when any state changes
        if (prevStates.getScreenShotLoadingST !== selectors.getScreenShotLoadingST) {
            console.log('getScreenShotLoadingST changed:', prevStates.getScreenShotLoadingST, '->', selectors.getScreenShotLoadingST);
        }

        // Update the reference with the current state
        prevStatesRef.current = selectors;
    }, [selectors]); // Run effect when `selectors` changes
    // Handle selectors
    useEffect(() => {
        selectorsRef.current = selectors;
    }, [selectors]);

    // Handle Screenshot state
    useEffect(() => {
        const loadingST = selectorsRef.current.getScreenShotLoadingST;
        const prevLoadingST = prevStatesRef.current.getScreenShotLoadingST;
        const screenShotFirstLoad = selectorsRef.current.screenShotFirstLoad;
        const mapMarkerInfo = selectorsRef.current.getMapMarkerInfo;

        // Check for screenshot loading state and URL condition
        if (prevLoadingST !== loadingST && url.screenShot) {
            setLoadingOverlay(loadingST);
        }

        // Handle marker animations or closing popups
        if (prevLoadingST !== loadingST && (loadingST || screenShotFirstLoad)) {
            setTimeout(() => {
                if (mapMarkerInfo && !!Object.keys(mapMarkerInfo).length) {
                    generAnimationMarker(mapMarkerInfo);
                } else {
                    mapRef.current?.fire('closeAllPopups');
                    mapRef.current?.fire('closeAnimationMarker');
                }
            }, 500);
        }
    }, [
        selectorsRef.current.getScreenShotLoadingST,
        selectorsRef.current.getMapMarkerInfo,
        url.screenShot
    ]);

    // Handle notifications
    useEffect(() => {
        if (+url.userId === +selectorsRef.current.user?.data?.id) {
            if (!selectorsRef.current.getShowNotificationST && !selectorsRef.current.getScreenShotLoadingST && url.screenShot && selectorsRef.current.screenShotFirstLoad) {
                const statesST = ['checkSettingsSTF'];
                runScreenShotStatesRef.current?.(statesST);
            }
        }
    }, [url, selectorsRef.current.user, selectorsRef.current.getShowNotificationST, selectorsRef.current.getScreenShotLoadingST, selectorsRef.current.screenShotFirstLoad]);

    // Handle timeline changes
    useEffect(() => {
        // Check if previous state was true and current state is false
        if (prevTimeLineChangedRef.current === true && timeLineChanged === false) {
            handleTimelineChange({
                timeLineChanged,
                setTimeLineChanged,
                setMetadata,
                metadataRef,
                selectors,
                url,
                mapData,
                mapRef
            });
        }
        // Update the ref to the latest state
        prevTimeLineChangedRef.current = timeLineChanged;

    }, [timeLineChanged]);

    // Handle map style changes
    useEffect(() => {
        if (
            (selectedMapStyleRef.current && selectedMapStyleRef.current !== selectedMapStyleRef.current) ||
            (selectorsRef.current.selectedMapStyleDark && selectorsRef.current.selectedMapStyleDark !== selectorsRef.current.selectedMapStyleDark)
        ) {
            handleMapStyleChange();
        }
    }, [selectedMapStyleRef.current, selectorsRef.current.selectedMapStyleDark]);

    // Handle cluster selection
    useEffect(() => {
        // Access the previous value from the ref
        const prevMapSelectedCluster = prevMapSelectedClusterRef.current;
        const mapSelectedCluster = selectorsRef.current?.mapSelectedCluster
        if (
            mapSelectedCluster?.forScreenShoot &&
            !prevMapSelectedCluster &&
            JSON.stringify(prevMapSelectedCluster) !== JSON.stringify(mapSelectedCluster)
        ) {
            triggerClickMapCluster(selectorsRef.current.mapSelectedCluster);
            setLoadFirst(false);
        }
            prevMapSelectedClusterRef.current = mapSelectedCluster;
    }, [selectorsRef.current.mapSelectedCluster]);

    // Handle map requirements
    useEffect(() => {
        const prevSelectedMapRequirement = prevSelectedMapRequirementRef.current;
        const selectedMapRequirement = selectorsRef.current?.selectedMapRequirement
        if (selectedMapRequirement && prevSelectedMapRequirement !== selectorsRef.current.selectedMapRequirement) {
            handleRequirements();
        }
        prevSelectedMapRequirementRef.current = selectedMapRequirement
    }, [selectorsRef.current.selectedMapRequirement]);

    // Handle timeline loading and items
    useEffect(() => {
        if (!selectorsRef.current.timeLineLoading && selectorsRef.current.timeLineItems.length > 0) {
            processSelectedWhateverView(selectorsRef.current.timeLineItems[0]);
        }
    }, [selectorsRef.current.timeLineLoading, selectorsRef.current.timeLineItems]);

    // Show article read more section
    useEffect(() => {
        if (
            !selectorsRef.current.mapIsLoading &&
            !selectorsRef.current.timeLineLoading &&
            Object.keys(selectorsRef.current.selectedArticle).length === 0 &&
            url?.bookmarkID
        ) {
            setTimeout(() => {
                showArticleReadMoreSection(actions,url);
            }, 2000);
        }
    }, [selectorsRef.current.mapIsLoading, selectorsRef.current.timeLineLoading, selectorsRef.current.selectedArticle, url]);

    // Handle map zoom
    useEffect(() => {
        if (
            (url.screenShot && mapZoomRef.current !== selectorsRef.current.mapZoom && loadFirst) ||
            (mapZoomRef.current !== selectorsRef.current.mapZoom && selectorsRef.current.mapZoomToAction)
        ) {
            changeMapZoom(selectorsRef.current.mapZoom,mapRef);
        }
        mapZoomRef.current = selectorsRef.current.mapZoom
    }, [url.screenShot, selectorsRef.current.mapZoom, loadFirst, selectorsRef.current.mapZoomToAction]);

    // Initialize component
    useEffect(() => {
        initializeComponent();
    }, []);

    // Track DOM element for the custom line
    useEffect(() => {
        const customBar = document.querySelector(".t1");
        customLineRef.current = customBar;
    }, []);

    const sidebarHeader = url.type === 'topic' ? `${props.t('topic')} - ${mapData?.language?.[0]?.title}` :
        <div className={'lesson__name'}>
            <p id={'lesson_title'}>{props.t('lesson')} - {mapData?.language?.[0]?.title}</p>
            <p class="lesson_slash">|</p>
            <a href={`/map?type=topic&topicID=${topic?.id}`}>
                <p id={'topic_title'}>{props.t('topic')} - {topic?.title}</p>
            </a>
        </div>

    if(!hasLanguageContent) {
        return (
            <NoLanguageContent title={props.t(`noLanguageContent.${url.type}` )}/>
        )
    }
    return (
        <div className="map-boby">
            {!url.articleReadMode  && (
                <MapHeader tools={subjectRef.current?.toolbox} map={mapRef.current}
                           mapTypes={subjectRef.current?.setting?.mapTypes}
                           globalLoading={globalLoading}
                           runScreenShotStates={runScreenShotStatesRef.current}
                />
            )}
            <MapContainer mapContainerRef={mapContainerRef}/>
            {globalLoading &&
                <WorldMapLoader/>
            }
            {loadingOverlay &&
                <div className={"loading__overlay"} />
            }
            <main className="main">
                {!url.articleReadMode && (
                    // TODO STATES OF NEW REFACTORED VERSION OF LEFT BAR ** LeftSideRefactored **
                    // leftBarSTF
                    // toggleItemFromMap
                    // globalLoading
                    // toggleItemsFromMap
                    // toggleItemsFromTimeLine
                    // stopPinAnimation
                    // toggleLegendsFromMapByID
                    // toggleLampFromMap
                    // timeLine
                    <LeftSide
                        toggleItemFromMap={toggleItemFromMap}
                        globalLoading={globalLoading}
                        toggleItemsFromMap={toggleItemsFromMapByID}
                        toggleItemsFromTimeLine={toggleItemsFromTimeLineByID}
                        stopPinAnimation={()=>stopPinAnimation(mapRef)}
                        toggleLegendsFromMapByID={toggleLegendsFromMapByID}
                        timeLine={timeLineRef}
                    />
                )}
                <RightSide mapState={mapRef.current}
                           stopPinAnimation={()=>stopPinAnimation(mapRef)}
                           globalLoading={globalLoading}
                />
            </main>
            <Toolbox attachTimeline={mapData?.showTimeline} coords={toolboxValues?.coords}
                     height={toolboxValues?.height}
                     scale={toolboxValues?.scale}/>
            {
                url.type !== "article" && mapData && !mapData?.showTimeline &&
                <MapSlider hasNext={url?.type === 'lesson' ? !!selectorsRef.current.lessonNavigationData?.next : selectorsRef.current.topicNavigation?.next}
                           hasPrev={url?.type === 'lesson' ? !!selectorsRef.current.lessonNavigationData?.previous : selectorsRef.current.topicNavigation?.previous}
                           onClickItem={(item)=>clickSliderItemHandler(item,url?.type)}
                           onNext={()=>navigate('next')}
                           onPrev={()=>navigate('prev')}
                           noBody={url?.type === 'lesson'}
                           items={mapData?.lessons?.map(mapItemAdapter) || []}
                           header={sidebarHeader}/>
            }
            {!globalLoading && selectorsRef.current.getStickyNotesMarkersState.map(note =>
                <StickyNotesEditPopup
                    key={note.id + (selectorsRef.current.getSlidesSelectedSlideData?.id ?? "")}
                    id={note.id}
                    color={note.markerColor}
                    description={note.description}
                    background={note.background}
                    markerWidth={note.markerWidth}
                    markerHeight={note.markerHeight}
                    isEdited={note.isEdited}
                    isMaximize={note.isMaximized}
                    left={note.left}
                    top={note.top}
                />
            )}
            <div
                style={{display: url?.type !== 'article' && mapData && !mapData?.showTimeline ? 'none' : 'block',zIndex:'99'}}
                data-is-windows={navigator.appVersion.indexOf("Windows") !== -1}
            >
                <Timeline
                    runScreenShotStates={runScreenShotStatesRef.current}
                    handleTimeChange={(time) => handleTimeChange(time)}
                    dataType={url.type}
                    timeLine={timeLineRef}
                    timeLineRelated={timeLineRelatedRef}
                    goToTopics={() => goToTopics(selectorsRef.current.topicId)}
                    moveLeft={() => moveLeft()}
                    moveRight={() => moveRight()}
                    toggleItemsFromMap={toggleItemsFromMapByID}
                    mapData={mapData}
                    onMapRenderComplete={onMapRenderComplete}
                    handleGetItemVisibility={handleGetItemVisibility}
                    filterLegend={filterLegend}
                    subjectID={subjectID}
                    gradeID={gradeID}
                    timeLineChanged={timeLineChanged}
                    onTimeLineShowChange={onTimeLineShowChange}
                    map={mapRef}
                    processLessonMode={processLessonMode}
                    nextLessonId={nextLessonId}
                    prevLessonId={prevLessonId}
                    handleTimeLineClickActionST={(e,isScreenShot)=>handleTimeLineClickActionST(e,isScreenShot, timeLineRef, setTimeLineChanged, handleTimeChange, changeClusterItemsTime)}
                    handleSetSuperCluster={handleSetSuperCluster}
                    globalLoading={globalLoading}
                />
            </div>
        </div>
    );
}
export default (withTranslation()(NewMap))
