// src/components/PopupDetail.js

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Card, Carousel, Typography, Spin, Alert, Button, Space } from 'antd';
import Portal from '../Portal'; // Adjust import path
import {
    CloseOutlined,
    ExpandOutlined,
    MinusOutlined,
    LaptopOutlined,
    EditOutlined,
} from '@ant-design/icons';
import { getYouTubeEmbedURL } from '../../utils'; // Adjust import path
import styles from './PopupDetail.module.css'; // Ensure this CSS is updated

import Dots from '../Dots';
import IconButton from '../../UI/IconButton';
import { setMicroArticleData } from '../../../../store/actions/microArticle';
import { getMicroArticleData, getScreenShotLoadingST } from '../../../../store/selectors';
import { connect } from 'react-redux';
import MediaRenderer from '../MediaRenderer'; // Import the new component
import GalleryNew from '../../../gallery-new/UI';
import { MicroArticleService } from "../../service"; // Import the GalleryNew component

const { Title, Paragraph } = Typography;

const PopupDetail = ({
                         featureId,
                         featureMicroArticleID,
                         isVisible,
                         onClose,
                         map,
                         changeEditMode,
                         changeIsViewPopupVisible,
                         changeIsModalVisible,
                         getMicroArticleData,
                         setMicroArticleData,
                         getScreenShotLoadingST,
                     }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const carouselRef = useRef(null);
    const carouselContainerRef = useRef(null); // Ref for carousel container
    const [currentSlide, setCurrentSlide] = useState(0);
    const [fontSize, setFontSize] = useState(
        window.innerWidth > 768 && window.innerHeight > 720 ? 16 : 20
    );
    const [isMaximized, setIsMaximized] = useState(true);
    const [isGalleryVisible, setIsGalleryVisible] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);

    // Refs for dragging
    const isDraggingRef = useRef(false);
    const dragStartX = useRef(0);
    const dragStartY = useRef(0);
    const dragThreshold = 5; // Pixels to consider as drag

    // Ref to track if click should be ignored
    const ignoreClickRef = useRef(false);

    // Fetch micro article details
    useEffect(() => {
        if (isVisible && featureId) {
            setLoading(true);
            MicroArticleService.getMicroArticleByID(featureMicroArticleID)
                .then((response) => {
                    console.log('response', response);
                    if (response.status === 200 || response.status === 204) {
                        setMicroArticleData(response.data.data[0]);
                    } else {
                        setError('Failed to fetch article details.');
                    }
                })
                .catch(() => {
                    setError('An error occurred while fetching data.');
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setMicroArticleData(null);
            setError(null);
            setCurrentSlide(0); // Reset currentSlide when not visible
        }
    }, [isVisible, featureId, setMicroArticleData, featureMicroArticleID]);

    // Handle carousel slide change
    const handleAfterChange = (current) => {
        setCurrentSlide(current);
    };

    // Handle dot click
    const handleDotClick = useCallback((index) => {
        if (carouselRef.current) {
            carouselRef.current.goTo(index, false); // Enable animation
        }
    }, []);

    // Font size adjustment handlers
    const increaseFontSize = () => setFontSize((prev) => Math.min(prev + 2, 32)); // Limit max font size
    const decreaseFontSize = () => setFontSize((prev) => Math.max(prev - 2, 10)); // Limit min font size

    const togglePopupSize = () => {
        setIsMaximized((prev) => !prev);
    };

    const changeToEditMode = () => {
        changeIsViewPopupVisible(false);
        changeEditMode(true);
        changeIsModalVisible(true);
    };

    // Handler for opening the gallery
    const handleOpenGallery = () => {
        setIsGalleryVisible(true);
    };

    // Handler for toggling the gallery visibility
    const toggleGallery = () => {
        setIsGalleryVisible((prev) => !prev);
    };

    // Drag event handlers
    const handleMouseDown = (e) => {
        isDraggingRef.current = false;
        dragStartX.current = e.clientX;
        dragStartY.current = e.clientY;
        ignoreClickRef.current = false;

        // Add 'grabbing' class
        if (carouselContainerRef.current) {
            carouselContainerRef.current.classList.add(styles.grabbing);
        }

        // Attach global event listeners
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e) => {
        const dx = Math.abs(e.clientX - dragStartX.current);
        const dy = Math.abs(e.clientY - dragStartY.current);
        if (!isDraggingRef.current && (dx > dragThreshold || dy > dragThreshold)) {
            isDraggingRef.current = true;
            ignoreClickRef.current = true;

            // Ensure cursor is 'grabbing'
            if (carouselContainerRef.current) {
                carouselContainerRef.current.classList.add(styles.grabbing);
            }
        }
    };

    const handleMouseUp = () => {
        if (isDraggingRef.current) {
            isDraggingRef.current = false;
            // Revert cursor to 'pointer'
            if (carouselContainerRef.current) {
                carouselContainerRef.current.classList.remove(styles.grabbing);
            }
        } else {
            // If not dragging, ensure cursor is 'pointer'
            if (carouselContainerRef.current) {
                carouselContainerRef.current.classList.remove(styles.grabbing);
            }
        }

        // Remove global event listeners
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
    };

    const handleTouchStart = (e) => {
        isDraggingRef.current = false;
        const touch = e.touches[0];
        dragStartX.current = touch.clientX;
        dragStartY.current = touch.clientY;
        ignoreClickRef.current = false;

        // Add 'grabbing' class
        if (carouselContainerRef.current) {
            carouselContainerRef.current.classList.add(styles.grabbing);
        }

        // Attach global touch event listeners
        window.addEventListener('touchmove', handleTouchMove);
        window.addEventListener('touchend', handleTouchEnd);
    };

    const handleTouchMove = (e) => {
        const touch = e.touches[0];
        const dx = Math.abs(touch.clientX - dragStartX.current);
        const dy = Math.abs(touch.clientY - dragStartY.current);
        if (!isDraggingRef.current && (dx > dragThreshold || dy > dragThreshold)) {
            isDraggingRef.current = true;
            ignoreClickRef.current = true;

            // Ensure cursor is 'grabbing'
            if (carouselContainerRef.current) {
                carouselContainerRef.current.classList.add(styles.grabbing);
            }
        }
    };

    const handleTouchEnd = () => {
        if (isDraggingRef.current) {
            isDraggingRef.current = false;
            // Revert cursor to 'pointer'
            if (carouselContainerRef.current) {
                carouselContainerRef.current.classList.remove(styles.grabbing);
            }
        } else {
            // If not dragging, ensure cursor is 'pointer'
            if (carouselContainerRef.current) {
                carouselContainerRef.current.classList.remove(styles.grabbing);
            }
        }

        // Remove global touch event listeners
        window.removeEventListener('touchmove', handleTouchMove);
        window.removeEventListener('touchend', handleTouchEnd);
    };

    if (!isVisible) return null;

    // Map the media items to galleryItems expected by GalleryNew
    const galleryItems = getMicroArticleData?.media?.map((item) => {
        let type;
        let path = item.path;

        if (item.type.trim().toLowerCase() === 'url') {
            // Check if it's a YouTube URL
            const isYouTube = /youtube\.com|youtu\.be/.test(item.path);
            if (isYouTube) {
                type = 3; // Treat as iframe
                path = getYouTubeEmbedURL(item.path);
            } else {
                // Handle other URLs appropriately, possibly still as iframe
                type = 3; // Treat as iframe
            }
        } else {
            switch (item.type.trim().toLowerCase()) {
                case 'image':
                    type = 0;
                    break;
                case 'video':
                    type = 1;
                    break;
                case 'iframe':
                    type = 3;
                    break;
                default:
                    type = -1;
            }
        }

        return {
            ...item,
            type,
            path,
        };
    });

    return (
        <>
            <Portal containerSelector=".main" className={styles.portal}>
                {isMaximized ? (
                    <Card
                        className={styles.card}
                        styles={{ body: { padding: 5 }, header: { padding: 5 } }}
                        title={
                            <div className={styles.header}>
                                <div className={styles.titleContainer}>
                                    <div className={styles.nameInitials}>{'Ա Մ'}</div>
                                    <Title level={4} className={styles.title}>
                                        <div
                                            className={styles.titleText}
                                            style={{
                                                fontSize:
                                                    getMicroArticleData && getMicroArticleData.title.length > 60
                                                        ? '18px'
                                                        : '20px',
                                            }}
                                        >
                                            {getMicroArticleData ? getMicroArticleData.title : 'Loading...'}
                                        </div>
                                    </Title>
                                </div>
                                <div className={styles.buttons}>
                                    <Button
                                        type="text"
                                        icon={isMaximized ? <MinusOutlined /> : <ExpandOutlined />}
                                        onClick={togglePopupSize}
                                        className={styles.toggleButton}
                                    />
                                    <Button
                                        type="text"
                                        icon={<CloseOutlined />}
                                        onClick={onClose}
                                        className={styles.closeButton}
                                    />
                                </div>
                            </div>
                        }
                        bordered={false}
                    >
                        {loading ? (
                            <div className={styles.spinnerContainer}>
                                <Spin size="large" />
                            </div>
                        ) : error ? (
                            <Alert message={error} type="error" showIcon />
                        ) : getMicroArticleData ? (
                            <div className={styles.content}>
                                {/* Carousel Section */}
                                {getMicroArticleData.media && getMicroArticleData.media.length > 0 && (
                                    <div
                                        className={styles.carouselContainer}
                                        ref={carouselContainerRef}
                                        onMouseDown={handleMouseDown}
                                        onTouchStart={handleTouchStart}
                                        // No onClick here; clicks are handled within carousel items
                                    >
                                        <Carousel
                                            draggable={true}
                                            arrows={false}
                                            infinite={false}
                                            autoplay={false}
                                            dots={false} // Disable default dots
                                            className={styles.carousel}
                                            ref={carouselRef}
                                            afterChange={handleAfterChange}
                                        >
                                            {getMicroArticleData.media.map((mediaItem, index) => (
                                                <div
                                                    key={mediaItem.mediaId || index}
                                                    className={styles.carouselItem}
                                                    onClick={(e) => {
                                                        if (ignoreClickRef.current) {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            return;
                                                        }
                                                        handleOpenGallery();
                                                    }}
                                                >
                                                    <MediaRenderer mediaItem={mediaItem} />
                                                </div>
                                            ))}
                                        </Carousel>
                                    </div>
                                )}

                                {/* Dot Pagination Section */}
                                {getMicroArticleData.media && getMicroArticleData.media.length > 0 && (
                                    <Dots
                                        total={getMicroArticleData.media.length}
                                        active={currentSlide} // Use currentSlide from PopupDetail state
                                        onScrollTo={handleDotClick} // Callback to update the carousel
                                        maxDots={6} // Customize as needed
                                        passiveDotWidth={8}
                                        activeDotWidth={10}
                                        passiveDotHeight={8}
                                        activeDotHeight={10}
                                        passiveColor="#dbdbdb"
                                        activeColor="#2C476C"
                                        marginHorizontal={3}
                                        smallerDotScale={0.7} // Scale for side dots
                                    />
                                )}

                                {/* Description Section */}
                                <div className={`${styles.description} ${styles.customScroll}`}>
                                    <Paragraph
                                        className={styles.paragraph}
                                        style={{ fontSize: `${fontSize}px` }}
                                    >
                                        {getMicroArticleData.description}
                                    </Paragraph>
                                </div>

                                {/* Button Section */}
                                <div className={styles.actionButtons}>
                                    <Space>
                                        <IconButton
                                            icon={<Typography.Text strong>A +</Typography.Text>}
                                            onClick={increaseFontSize}
                                        />
                                        <IconButton
                                            icon={<Typography.Text strong>A -</Typography.Text>}
                                            onClick={decreaseFontSize}
                                        />
                                        <IconButton icon={<LaptopOutlined />} />
                                        <IconButton icon={<EditOutlined />} onClick={changeToEditMode} />
                                    </Space>
                                </div>
                            </div>
                        ) : (
                            <div>No data available.</div>
                        )}
                    </Card>
                ) : (
                    <Card
                        className={styles.cardMinimized}
                        bordered={false}
                        styles={{ body: { padding: '0' } }}
                    >
                        <div className={styles.minimizedImageWrapper}>
                            {/* Left Side: First Image */}
                            {getMicroArticleData &&
                            getMicroArticleData.media &&
                            getMicroArticleData.media.length > 0 &&
                            getMicroArticleData.media[0].type.trim().toLowerCase() === 'image' ? (
                                <img
                                    src={getMicroArticleData.media[0].path}
                                    alt={getMicroArticleData.media[0].caption}
                                    className={styles.minimizedImage}
                                />
                            ) : null}

                            {/* Middle: Title */}
                            <Typography className={styles.minimizedTitle}>
                                {getMicroArticleData ? getMicroArticleData.title : 'Loading...'}
                            </Typography>

                            {/* Right Side: Maximize and Close Buttons */}
                            <div className={styles.minimizedButtons}>
                                <Button
                                    type="text"
                                    icon={<ExpandOutlined />}
                                    onClick={togglePopupSize}
                                    className={styles.toggleButton}
                                />
                                <Button
                                    type="text"
                                    icon={<CloseOutlined />}
                                    onClick={onClose}
                                    className={styles.closeButton}
                                />
                            </div>
                        </div>
                    </Card>
                )}
            </Portal>

            {/* Gallery Mode Overlay */}
            {isGalleryVisible && getMicroArticleData && (
                <Portal containerSelector={'#root'}>
                    <GalleryNew
                        isGrid={false}
                        mapState={map}
                        title={getMicroArticleData.title}
                        gallery={isGalleryVisible}
                        toggleGallery={toggleGallery}
                        clickedMediaImage={getMicroArticleData.media[currentSlide]?.path}
                        feauturedImage={getMicroArticleData.media[currentSlide]?.path}
                        feauturedImageIdx={currentSlide}
                        galleryItems={galleryItems}
                        feauturedTiliPageIdx={0}
                        fromArticle={false}
                        isFullScreen={isFullScreen}
                        setGalleryFullScreen={setIsFullScreen}
                        articleId={getMicroArticleData.id}
                        screenShotFirstLoad={getScreenShotLoadingST}
                    />
                </Portal>
            )}
        </>
    )
};

/**
 * Map Redux state to component props.
 */
const mapStateToProps = (state) => ({
    getMicroArticleData: getMicroArticleData(state),
    getScreenShotLoadingST: getScreenShotLoadingST(state),
});

/**
 * Map Redux actions to component props.
 */
const mapDispatchToProps = {
    setMicroArticleData,
};

/**
 * Connect the component to Redux and export.
 */
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(PopupDetail));
